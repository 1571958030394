import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { configSlice } from 'features/config/configSlice';
import { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

interface Props {
  children?: ReactNode;
}

const Auth0ProviderWithHistory: FC<Props> = ({ children }) => {
  const navigate = useNavigate();

  const config = useSelector(configSlice.selectors.config);

  const { audience, clientId, domain } = config.auth;

  if (!domain || !clientId || !audience) {
    return null;
  }

  const onRedirectCallback = (appState?: AppState) => {
    navigate(appState?.returnTo ?? window.location.pathname);
  };

  return (
    <Auth0Provider
      authorizationParams={{
        audience: audience,
        redirect_uri: window.location.origin,
        scope: 'openid profile email',
      }}
      clientId={clientId}
      domain={domain}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens={true}
      useRefreshTokensFallback={true}
      //cacheLocation="memory" // can also be 'localstorage' alternatively you can make your own ICache implementation (store in redux?)
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
