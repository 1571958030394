import { DakotaUser, IdentityClaims } from 'auth/DakotaUser';

export interface Identity {
  firstName?: string;
  lastName?: string;
  options: UserOpts;
  orgId: string;
  orgName?: string;
  userId: string;
  userName: string;
}

type UserOpts = {
  guidesEnabled: boolean;
};

export function toIdentity(user?: Partial<DakotaUser>): Identity | null {
  if (!user) {
    return null;
  }
  const oInfo = user[IdentityClaims.OrgInfo] ?? { id: '', name: '' };
  const uInfo = user[IdentityClaims.UserInfo] ?? { userId: '' };
  const guidesEnabled = uInfo.guidesEnabled ?? true;

  const identity: Identity = {
    firstName: user.given_name,
    lastName: user.family_name,
    options: {
      guidesEnabled,
    },
    orgId: oInfo.id,
    orgName: oInfo.name,
    userId: uInfo.userId,
    userName: user.email ?? user.name ?? user.sub ?? '',
  };
  return identity;
}
