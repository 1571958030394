import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { AutocompleteRenderOptionState, ListItem } from '@mui/material';
import { clsx } from 'clsx';
import DakotaLibraryLogo from 'components/DakotaLibraryLogo';
import { HTMLAttributes, ReactNode } from 'react';

/**
 * Render a given ReactNode formatted for the autocomplete option list,
 * with a checkmark icon for selected options,  an X instead of the checkmark
 * when the selected options are hovered, and no icon for unselected options.
 */
export const renderOptionWithCheckmark = (
  props: HTMLAttributes<HTMLLIElement>,
  option: ReactNode,
  state: AutocompleteRenderOptionState,
) => {
  type PropsWithKey = { key: string } & HTMLAttributes<HTMLLIElement>;
  const { key, ...rest } = props as PropsWithKey;

  return (
    <ListItem
      {...rest}
      className={clsx(
        { 'font-semibold': state.selected },
        'group cursor-pointer text-sm font-sans text-gray-700',
        'hover:bg-green-base hover:text-white',
      )}
      key={key}
    >
      <div className='flex items-center justify-between w-full'>
        <div className='flex-1 text-pretty'>{option}</div>
        <div className='w-5 flex-none'>
          {state.selected && (
            <>
              <XMarkIcon className='hidden group-hover:block' />
              <CheckIcon className='group-hover:hidden text-green-base' />
            </>
          )}
        </div>
      </div>
    </ListItem>
  );
};

/**
 * Given a function that returns a string label for an option that contains
 * a Boolean propert `isPlatformDefined`, return a function that renders
 * the option with the library logo when `isPlatformDefined` is `true`.
 * The returned function can be passed directly to the `renderOption` prop
 * of the `Autocomplete` component.
 */
export const getRendererWithLibraryLogo =
  <T extends { isPlatformDefined: boolean }>(getLabel: (t: T) => string) =>
  (
    props: HTMLAttributes<HTMLLIElement>,
    option: T,
    state: AutocompleteRenderOptionState,
  ) =>
    renderOptionWithCheckmark(
      props,
      <span>
        {getLabel(option)}
        {option.isPlatformDefined && <DakotaLibraryLogo inDropdown />}
      </span>,
      state,
    );
