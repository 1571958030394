import { Priority, TaskSeries } from '@dakota/platform-client';
import { ClockIcon } from '@heroicons/react/24/outline';
import { LocalDate } from '@js-joda/core';
import { clsx } from 'clsx';
import Autocomplete from 'components/Autocomplete';
import { DatePicker } from 'components/DatePicker';
import EditableText from 'components/EditableText';
import { Recurrence } from 'components/recurrence';
import { toHumanReadable } from 'components/recurrence/humanReadable';
import { isEqualAsRRule } from 'components/recurrence/rrule';
import { useSelectedFacilityUser } from 'hooks/useSelectedFacilityUser';
import { useUsers } from 'hooks/useUsers';
import { EditSeriesChoices, getPriorityLabel } from 'Pages/Tasks/types';
import { FC, useEffect, useState } from 'react';

type SeriesDetailsProps = {
  /**
   * The option to edit all events or this event and following events.
   */
  editOption: EditSeriesChoices;
  /**
   * Callback to notify about changes in the form.
   */
  onFieldChange: (changed: boolean) => void;
  /**
   * The task series to edit.
   */
  task: TaskSeries;
};

export const SeriesDetails: FC<SeriesDetailsProps> = ({
  editOption,
  onFieldChange,
  task,
}) => {
  const { getActiveUserSummary } = useUsers();
  const { selectableUsers } = useSelectedFacilityUser(task.facility.id);

  const [assigneeId, setAssigneeId] = useState<string | undefined>(
    task.assigneeId,
  );
  const [selectedPriority, setSelectedPriority] = useState<Priority>(
    task.priority,
  );
  const [date, setDate] = useState<LocalDate>(
    editOption === EditSeriesChoices.CurrentAndFollowing
      ? LocalDate.now()
      : LocalDate.parse(task.startDate),
  );
  const [title, setTitle] = useState<string>(task.title);
  const [description, setDescription] = useState<string | undefined>(
    task.description,
  );
  const [recurrence, setRecurrence] = useState<string>(task.recurrenceRule);

  const priorities = [Priority.High, Priority.Medium, Priority.Low];

  /*
   * Effect to check if the form has changed and notify the parent component.
   */
  useEffect(() => {
    const hasFormChanged =
      task.assigneeId !== assigneeId ||
      task.priority !== selectedPriority ||
      task.startDate !== date.toString() ||
      task.title !== title ||
      task.description !== description ||
      !isEqualAsRRule(task.recurrenceRule, recurrence);

    onFieldChange(hasFormChanged);
  }, [
    assigneeId,
    selectedPriority,
    date,
    title,
    description,
    recurrence,
    task,
    onFieldChange,
  ]);

  return (
    <>
      <div className='p-4 border-x border-gray-100'>
        <EditableText
          containerClasses='w-full'
          initialEditableValue={task.title}
          onEdit={setTitle}
          required
          saveOnEnter
          styleAfterSave
          text={title}
        />
      </div>
      <div
        className={clsx(
          'p-4 flex items-center gap-2',
          'border-b-0 border border-gray-100',
        )}
      >
        <ClockIcon className='h-6 w-6' />
        <div className='text-sm'>{toHumanReadable(task.recurrenceRule)}</div>
      </div>
      <div className='p-4 border-t-0 border border-gray-100'>
        <EditableText
          allowEmpty
          containerClasses='w-full'
          growVertically
          initialEditableValue={task.description}
          onEdit={setDescription}
          saveOnEnter
          styleAfterSave
          text={description ?? 'Enter a description'}
        />
      </div>
      <div className='p-4 border-gray-100'>
        <div className='text-base font-semibold'>Task Details</div>
        <p className='text-sm text-gray-600'>
          Details for every recurrence of this task.
        </p>
        <div className='flex flex-col gap-4 mt-4'>
          <div className='flex text-sm'>
            <div className='flex-1'>Facility</div>
            <div className='font-bold'>{task.facility.name}</div>
          </div>
          {task.zone?.name && (
            <div className='flex text-sm'>
              <div className='flex-1'>Zone</div>
              <div className='font-bold'>{task.zone.name}</div>
            </div>
          )}
          <div className='flex'>
            <div className='flex-1 text-sm'>Start Date</div>
            <DatePicker
              asSingle
              id='scheduled-date-picker'
              onChange={setDate}
              popoverDirection='up'
              value={date}
            />
          </div>
          <div className='flex'>
            <div className='flex-1 text-sm text-gray-700'>Assignee</div>
            <Autocomplete
              className='max-sm:w-48 w-72'
              getOptionKey={(user) => user.id}
              getOptionLabel={(user) => user.name}
              id='assignee-selector'
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(user) => setAssigneeId(user.id)}
              options={selectableUsers}
              value={getActiveUserSummary(assigneeId) ?? null}
            />
          </div>
          <div className='flex'>
            <div className='flex-1 text-sm text-gray-700'>Priority</div>
            <Autocomplete
              className='max-sm:w-48 w-72'
              getOptionLabel={(priority) => getPriorityLabel(priority)}
              id='priority-selector'
              loading={false}
              onChange={setSelectedPriority}
              options={priorities}
              value={selectedPriority}
            />
          </div>
          <div className='flex'>
            <div className='flex-1 text-sm text-gray-700'>Recurrence</div>
            <Recurrence
              initialDate={date}
              initialRRule={recurrence}
              onChange={setRecurrence}
            />
          </div>
          <div className='text-base font-semibold'>Source</div>
          <div className='flex text-sm'>
            <div className='flex-1 text-gray-700'>Reported By</div>
            <div className='font-bold'>{task.reportedBy}</div>
          </div>
        </div>
      </div>
    </>
  );
};
