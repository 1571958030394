import { configSlice } from 'features/config/configSlice';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';

import { CacheBusterContextProps, CacheBusterProps } from './types';
import { isNewVersion, tracker } from './utils';

const CacheBusterContext = createContext<CacheBusterContextProps>({
  clearCacheAndReload: () => Promise.resolve(),
  forceCheck: () => Promise.resolve(),
  initialized: false,
  versions: { current: '', isLatest: false, latest: '' },
});

// eslint-disable-next-line sonarjs/sonar-prefer-read-only-props
function CacheBuster({
  autoReloadEnabled = true,
  children,
  currentVersion = '',
  forceCheck,
  loadingComponent = undefined,
}: CacheBusterProps) {
  const [cacheStatus, setCacheStatus] = useState({
    foundVersion: '',
    isLatestVersion: false,
    loading: true,
  });

  const isFirstCheck = useRef(true);
  const config = useSelector(configSlice.selectors.config);

  const checkCacheStatus = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const configVersion = config?.appInfo?.version;

    // if current version is not found, assume we are good and fall back to
    // value from config and just assume that we have the latest copy as there's
    // nothing more we can do
    if (!currentVersion) {
      setCacheStatus({
        foundVersion: configVersion,
        isLatestVersion: true,
        loading: false,
      });
      return;
    }

    if (!configVersion) {
      return;
    }

    const cycle = tracker.isCycle(configVersion, currentVersion);

    // if we can't track or we're in a loop, then we can't auto-reload
    if (cycle || !tracker.enabled) {
      isFirstCheck.current = false;
    }

    if (isNewVersion(configVersion, currentVersion)) {
      let message = `A new version (v${configVersion}) was detected`;
      if (cycle) {
        message += ' but the source is not yet available... try again later';
      } else if (autoReloadEnabled && isFirstCheck.current) {
        message += '!! The page will reload.';
      }
      console.log(message);
      setCacheStatus({
        foundVersion: configVersion,
        isLatestVersion: false,
        loading: false,
      });
    } else {
      setCacheStatus({
        foundVersion: currentVersion,
        isLatestVersion: true,
        loading: false,
      });
    }
  }, [currentVersion, config, autoReloadEnabled]);

  const deleteCacheAndReload = useCallback(async () => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (window.caches) {
        const { caches } = window;
        const cacheNames = await caches.keys();
        const cacheDeletionPromises = cacheNames.map((n) => caches.delete(n));

        await Promise.all(cacheDeletionPromises);
        console.log('The cache has been deleted.');
      }
    } catch (error) {
      console.warn('An error occurred while deleting the cache.', error);
    }

    tracker.set(`${cacheStatus.foundVersion}|${currentVersion}`);

    window.location.reload();
  }, [cacheStatus, currentVersion]);

  useEffect(() => {
    checkCacheStatus();
  }, [checkCacheStatus]);

  if (!cacheStatus.loading && isFirstCheck.current) {
    isFirstCheck.current = false;
    if (!cacheStatus.isLatestVersion && autoReloadEnabled) {
      // eslint-disable-next-line @typescript-eslint/use-unknown-in-catch-callback-variable
      deleteCacheAndReload().catch((error) => {
        console.error('Error during cache deletion and reload:', error);
      });
    }
  }

  if (!autoReloadEnabled) {
    tracker.remove();
  }

  const value = useMemo(
    () =>
      ({
        clearCacheAndReload: deleteCacheAndReload,

        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        forceCheck: forceCheck || (() => Promise.resolve()),
        initialized: !cacheStatus.loading,
        versions: {
          current: currentVersion,
          isLatest: cacheStatus.isLatestVersion,
          latest: cacheStatus.foundVersion || currentVersion,
        },
      }) as CacheBusterContextProps,
    [cacheStatus, currentVersion, deleteCacheAndReload, forceCheck],
  );

  const childrenToUse =
    cacheStatus.loading && loadingComponent ? loadingComponent : children;

  return (
    <CacheBusterContext.Provider value={value}>
      {childrenToUse}
    </CacheBusterContext.Provider>
  );
}

const useCacheBuster = () => {
  const context = useContext(CacheBusterContext);

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition, sonarjs/different-types-comparison
  if (context === undefined || context === null) {
    throw new Error(
      'useCacheBuster must be used within a CacheBuster component.',
    );
  }
  return context;
};

export { CacheBuster, useCacheBuster };
