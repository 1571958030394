import { TaskInstance, UserSummary } from '@dakota/platform-client';
import { AssigneeAvatar } from 'components/Avatar';
import { PriorityIcon } from 'components/PriorityIcon';
import { FC } from 'react';

type TaskDetailsButtonProps = {
  onClick: () => void;
  task: TaskInstance;
};

export const TaskDetailsButton: FC<TaskDetailsButtonProps> = ({
  onClick,
  task,
}) => {
  const userSummary = UserSummary.fromJS({
    id: task.assigneeId,
    name: task.assigneeName,
  });

  return (
    <button
      className='flex-1 flex sm:items-center gap-4 max-sm:flex-col'
      onClick={onClick}
    >
      <AssigneeAvatar user={userSummary} />
      <div>
        Due: <strong>{task.timeline.scheduledDate}</strong>
      </div>
      <div className='flex items-center gap-1'>
        Priority: <PriorityIcon priority={task.priority} />
      </div>
      <div>
        Facility: <strong>{task.facility.name}</strong>
      </div>
      {task.zone && (
        <div>
          Zone: <strong>{task.zone.name}</strong>
        </div>
      )}
    </button>
  );
};
