import { Inspection, InspectionStatus } from '@dakota/platform-client';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Tooltip } from '@mui/material';
import { clsx } from 'clsx';
import Confirmation from 'components/SimpleConfirmation';
import { configSlice } from 'features/config/configSlice';
import { cancelInspection } from 'features/inspections/inspectionActions';
import { tokenSlice } from 'features/token/tokenSlice';
import { useCheckPermission } from 'hooks/useCheckPermission';
import useToast from 'hooks/useToast';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { Permission } from 'utils/permissions';

type Props = {
  inspection: Inspection;
};
export const Cancel: FC<Props> = ({ inspection }) => {
  const dispatch = useAppDispatch();
  const token = useSelector(tokenSlice.selectors.token);
  const baseUrl = useSelector(configSlice.selectors.backend);

  const { setErrorMessage, setSuccessMessage } = useToast();

  const canCancelInspection = useCheckPermission(Permission.CancelInspection);

  const [isCanceling, setIsCanceling] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const handleCancelInspection = (inspectionId: string) => {
    setIsCanceling(true);

    const cancelData = {
      baseUrl,
      id: inspectionId,
      token,
    };
    dispatch(cancelInspection(cancelData))
      .unwrap()
      .then(() => {
        setIsConfirmationOpen(false);
        setSuccessMessage('Inspection canceled successfully');
      })
      .catch(() => setErrorMessage('Failed to cancel the inspection'))
      .finally(() => setIsCanceling(false));
  };

  return (
    <>
      <button
        className={clsx(
          'flex items-center gap-3 cursor-pointer text-gray-700',
          'disabled:cursor-not-allowed disabled:text-gray-300',
        )}
        data-testid='cancel-inspection-button'
        disabled={!canCancelInspection}
        onClick={() => setIsConfirmationOpen(true)}
      >
        <Tooltip placement='top' title='Cancel Inspection'>
          <XMarkIcon className='w-4' />
        </Tooltip>
        Cancel
      </button>
      {isConfirmationOpen && (
        <Confirmation
          cancelText='Return to Dashboard'
          confirmText={isCanceling ? 'Canceling...' : 'Cancel Inspection'}
          data-testid='confirmation-dialog'
          loading={isCanceling}
          onCancel={() => setIsConfirmationOpen(false)}
          onConfirm={() => handleCancelInspection(inspection.id)}
          title='Cancel Inspection?'
        >
          Are you sure you want to cancel this inspection?{' '}
          {inspection.status === InspectionStatus.Overdue ||
          inspection.status === InspectionStatus.Scheduled
            ? 'It will be removed from your schedule.'
            : 'All entries on this inspection will be removed.'}
        </Confirmation>
      )}
    </>
  );
};
