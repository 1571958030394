import { LocalDate } from '@js-joda/core';
import { DatePicker } from 'components/DatePicker';
import { taskEditSlice } from 'features/tasks/taskEditSlice';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';

export const DueDate: FC = () => {
  const dispatch = useAppDispatch();

  const hasErrorFiles = useSelector(taskEditSlice.selectors.hasErrorFiles);

  const date = useSelector(taskEditSlice.selectors.date);

  return (
    <>
      <label className='required-field ' htmlFor='task-datepicker-input'>
        Due date
      </label>
      <div className='place-self-end'>
        <DatePicker
          asSingle
          disabled={hasErrorFiles}
          id='task-datepicker'
          onChange={(newDate) =>
            dispatch(
              taskEditSlice.actions.setTaskField({
                field: 'date',
                value: newDate.toString(),
              }),
            )
          }
          value={LocalDate.parse(date)}
        />
      </div>
    </>
  );
};
