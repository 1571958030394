import { PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer';

import { TemplatesState } from './templatesSlice';

/**
 * This file contains the implementation of two drag-and-drop functions
 * that cannot be (easily) tested with the current setup.
 * The file is ignored in Jest setup for coverage.
 */

export function moveSectionQuestionImpl(): (
  state: WritableDraft<TemplatesState>,
  action: PayloadAction<{
    fromIndex: number;
    sectionId: string;
    toIndex: number;
  }>,
) => void {
  return (
    state,
    action: PayloadAction<{
      fromIndex: number;
      sectionId: string;
      toIndex: number;
    }>,
  ) => {
    const questions = state.sections.find(
      (s) => s.itemTypeId === action.payload.sectionId,
    )?.questions;
    const { fromIndex, toIndex } = action.payload;
    if (questions) {
      const [removed] = questions.splice(fromIndex, 1);
      questions.splice(toIndex, 0, removed);

      // update order of questions
      state.sections.forEach((section) =>
        section.questions.forEach(
          (question, index) => (question.order = index),
        ),
      );
    }
  };
}

export function moveSectionImpl() {
  return (
    state: WritableDraft<TemplatesState>,
    action: PayloadAction<{ fromIndex: number; toIndex: number }>,
  ) => {
    const { fromIndex, toIndex } = action.payload;
    const [removed] = state.sections.splice(fromIndex, 1);
    state.sections.splice(toIndex, 0, removed);

    // update order of sections
    state.sections.forEach((section, index) => (section.order = index));
  };
}
