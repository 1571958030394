import { LocalDate } from '@js-joda/core';
import { configSlice } from 'features/config/configSlice';
import { tokenSlice } from 'features/token/tokenSlice';
import { listFacilityUsers } from 'features/user/userActions';
import { userSlice } from 'features/user/userSlice';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { unassignedUser } from 'utils/user';

export const useSelectedFacilityUser = (facilityId?: string) => {
  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);

  const usersPerFacility = useSelector(userSlice.selectors.usersPerFacility);

  const [assignee, setAssignee] = useState(unassignedUser);
  const [date, setDate] = useState(LocalDate.now());

  const selectableUsers = [unassignedUser].concat(
    usersPerFacility.get(facilityId ?? '') ?? [],
  );

  useEffect(() => {
    // Only dispatch the action if the facility is selected
    // and the users for that facility are not already loaded
    if (facilityId && !usersPerFacility.get(facilityId)) {
      const listFacilityUsersData = {
        active: true,
        baseUrl,
        directFacilityOnly: false,
        facilityId,
        orgWide: false,
        token,
      };

      void dispatch(listFacilityUsers(listFacilityUsersData));
    }
  }, [baseUrl, dispatch, facilityId, token, usersPerFacility]);

  // Go back to unassigned if the new facility doesn't have the selected
  // assignee
  useEffect(() => {
    if (
      !!facilityId &&
      !usersPerFacility.get(facilityId)?.find((user) => user.id === assignee.id)
    ) {
      setAssignee(unassignedUser);
    }
  }, [usersPerFacility, assignee.id, setAssignee, facilityId]);

  return {
    assignee,
    date,
    selectableUsers,
    setAssignee,
    setDate,
  };
};
