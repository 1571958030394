import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { Fade } from '@mui/material';
import { clsx } from 'clsx';
import { FC, PropsWithChildren, useEffect } from 'react';

type Props = {
  /**
   * Whether to render the exclamation circle icon or not
   * @default true
   */
  renderIcon?: boolean;
  /**
   * The 'warning' variant renders a red background with a red icon,
   * whereas the 'light' variant renders a yellow background
   * with a yellow icon.
   * @default 'warning'
   */
  variant?: 'light' | 'warning';
};

/**
 * A simple warning message with an icon on desktop view,
 * and no icon on mobile view. Text is center-aligned on mobile.
 */
export const WarningMessage: FC<PropsWithChildren<Props>> = ({
  children,
  renderIcon = true,
  variant = 'warning',
}) => {
  const containerId = 'warning-container';

  useEffect(() => {
    document
      .getElementById(containerId)
      ?.scrollIntoView({ behavior: 'smooth' });
  }, [containerId]);

  // We use a long timeout in the fade animation to draw attention to
  // the warning message. Also, when used in a modal, the modal itself
  // will likely be animated so this timeout acts as a counter to that.
  return (
    <Fade appear in timeout={1000}>
      <div
        className={clsx(
          'flex items-center gap-4 p-4 border rounded-md',
          'text-sm ',
          variant === 'warning'
            ? 'border-red-light bg-red-lightest text-red-darker'
            : 'border-yellow-base bg-yellow-lightest text-yellow-darker',
        )}
        data-testid={containerId}
        id={containerId}
      >
        {renderIcon && (
          <div className='max-w-6 flex-none'>
            <ExclamationCircleIcon
              className={clsx(
                'hidden sm:block w-full',
                variant === 'warning' ? 'text-red-base' : 'text-yellow-base',
              )}
            />
          </div>
        )}
        <div className='text-center sm:text-left space-y-4'>{children}</div>
      </div>
    </Fade>
  );
};
