import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/16/solid';
import { clsx } from 'clsx';
import useLongPress from 'hooks/useLongPress';
import { FC, HTMLAttributes, useRef, useState } from 'react';
import { useEventListener } from 'usehooks-ts';

type NumberInputProps = {
  /**
   * If true, the input will be disabled.
   * @default false
   */
  disabled?: boolean;
  /**
   * The initial value of the input.
   * @default 1 if it's in the range, or the minimum value valid in the range.
   */
  initialValue?: number;
  /**
   * The maximum value that the input can have.
   * @default undefined
   */
  max?: number;
  /**
   * The minimum value that the input can have.
   * @default undefined
   */
  min?: number;
  /**
   * Function called when the value of the input changes.
   */
  onChangeValue: (value: number) => void;
  /**
   * If provided, it will be used to append a string after
   * the value of the input, depending on the value itself.
   * @default undefined
   */
  suffix?: (value: number) => string;
} & Exclude<HTMLAttributes<HTMLInputElement>, 'onChange'>;

/**
 * Determine the initial value of the input based on the range.
 */
const defaultInitialValue = (min?: number, max?: number) => {
  if (min === undefined && max !== undefined) {
    return Math.min(1, max);
  } else if (min !== undefined) {
    return Math.max(1, min);
  }
  return 1;
};

export const NumberInput: FC<NumberInputProps> = ({
  disabled = false,
  max,
  min,
  initialValue = defaultInitialValue(min, max),
  onChangeValue,
  suffix,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [value, setValue] = useState(initialValue);
  // Allow the display value to be empty while keeping a numeric value
  const [displayValue, setDisplayValue] = useState(initialValue.toString());

  const increment = () =>
    safeChangeFromNum(Math.min(Number.MAX_SAFE_INTEGER - 1, value) + 1);
  const decrement = () =>
    safeChangeFromNum(Math.max(Number.MIN_SAFE_INTEGER + 1, value) - 1);

  useEventListener('keydown', (e) => {
    // Only listen to key events on the input
    if (e.target !== inputRef.current) {
      return;
    }

    if (e.key === 'ArrowUp') {
      increment();
    } else if (e.key === 'ArrowDown') {
      decrement();
    }
  });

  const isInRange = (candidate: number) => {
    if (max !== undefined && min !== undefined) {
      return candidate >= min && candidate <= max;
    } else if (max !== undefined) {
      return candidate <= max;
    } else if (min !== undefined) {
      return candidate >= min;
    }
    return true;
  };

  const safeChangeFromNum = (candidate: number) => {
    if (isInRange(candidate)) {
      setValue(candidate);
      setDisplayValue(candidate.toString());
      onChangeValue(candidate);
    }
  };

  const safeChangeFromStr = (candidate: string) => {
    // Set the display value to whatever the user typed,
    // even the empty string, but only update the value
    // on a valid number within the required range
    setDisplayValue(candidate);

    if (!isNaN(+candidate)) {
      safeChangeFromNum(+candidate);
    }
  };

  const ensureNumericValue = () => {
    if (!Number.isInteger(displayValue)) {
      setDisplayValue(value.toString());
    }
  };

  const buttonClasses = clsx(
    'w-4 text-green-base flex flex-col',
    'disabled:text-gray-300 disabled:cursor-not-allowed',
  );

  return (
    <div className='flex gap-0.5 items-center'>
      <div
        className={clsx(
          'h-9 p-2 text-sm text-gray-700 has-[:disabled]:bg-gray-100',
          'has-[:disabled]:text-gray-300 has-[:disabled]:border-gray-200',
          'flex items-center gap-0.5 border border-gray-300 rounded-md',
        )}
      >
        <input
          className={clsx(
            'text-center border-0 p-0.5 focus:ring-0 peer',
            'disabled:bg-gray-100 disabled:cursor-not-allowed',
            { 'focus:bg-gray-100 focus:rounded': suffix },
          )}
          disabled={disabled}
          inputMode='numeric'
          max={max}
          min={min}
          onBlur={ensureNumericValue}
          onChange={(e) => safeChangeFromStr(e.target.value)}
          pattern='[0-9]*'
          ref={inputRef}
          required
          size={Math.max(1, value.toString().length)}
          type='text'
          value={displayValue}
          {...rest}
        />
        <button
          className={clsx(
            'cursor-text peer-focus-within:text-gray-400',
            'peer-disabled:cursor-not-allowed',
          )}
          onClick={() => inputRef.current?.focus()}
        >
          {suffix?.(value)}
        </button>
      </div>
      <div className='flex flex-col justify-center'>
        <button
          aria-label='Increment'
          className={buttonClasses}
          disabled={disabled || value === max}
          {...useLongPress(increment)}
        >
          <ChevronUpIcon />
        </button>
        <button
          aria-label='Decrement'
          className={buttonClasses}
          disabled={disabled || value === min}
          {...useLongPress(decrement)}
        >
          <ChevronDownIcon />
        </button>
      </div>
    </div>
  );
};
