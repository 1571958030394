/**
 * Compute the initials of a name with the following logic:
 *
 * 1. If the name has a comma, assume it's in the format Last, First and use
 *    the initials from the first word and the first word after the comma.
 * 2. If the name doesn't have a comma but it has a space and at least two
 *    words, assume it's in the format First Last and use the initials from
 *    the first two words.
 * 3. If the name doesn't have a space, and it has at least two characters,
 *    use the first two characters.
 * 4. If the name is a single character, use that character.
 */
export const getInitials = (name: string) => {
  const upperCaseName = name.toUpperCase();

  if (upperCaseName.includes(',')) {
    const [lastName, firstName] = upperCaseName.split(', ');
    return `${firstName[0]}${lastName[0]}`;
  }

  if (upperCaseName.includes(' ')) {
    const [firstName, lastName] = upperCaseName.split(' ');
    return `${firstName[0]}${lastName[0]}`;
  }

  if (upperCaseName.length >= 2) {
    return upperCaseName.slice(0, 2);
  }

  return upperCaseName;
};
