import { useAuth0 } from '@auth0/auth0-react';
import Button from 'components/Button';

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  const login = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    void loginWithRedirect({
      appState: { returnTo: window.location.pathname },
    });
  };

  return (
    <Button id='login-button' onClick={login}>
      Log In
    </Button>
  );
};

export default LoginButton;
