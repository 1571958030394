import { Modal } from '@mui/material';
import { clsx } from 'clsx';
import Button from 'components/Button';
import { ReactNode } from 'react';

/**
 * If additionalButtonText is provided, onAdditionalAction is required.
 */
type AdditionalButtonProps =
  | {
      additionalButtonText: string;
      onAdditionalAction: () => void;
    }
  | {
      additionalButtonText?: never;
      onAdditionalAction?: never;
    };

type AcknowledgmentDialogProps = Readonly<
  {
    /**
     * Disable the acknowledgment button *and* the cancel button when `true`.
     * @default false
     */
    acknowledgmentDisabled?: boolean;
    /**
     * The main text to display in the dialog.
     */
    acknowledgmentText: ReactNode;
    /**
     * The text to display on the acknowledgment button.
     * @default 'OK'
     */
    buttonText?: string;
    /**
     * Optional text to display on the cancel button. If provided,
     * a cancel button will be rendered which closes the dialog.
     */
    cancelButtonText?: string;
    /**
     * Callback to run when the user clicks the acknowledgment button.
     * The dialog will be closed first, and then the callback will be run.
     */
    onAcknowledgment: () => void;
    /**
     * Callback to run when the dialog is requested to be closed, either by
     * clicking the cancel button or by other means such as clicking outside the
     * dialog.
     */
    onCloseDialog: () => void;
    /**
     * Short title for the dialog.
     */
    title: string;
  } & AdditionalButtonProps
>;

/**
 * Dialog with a title, a line of text, an acknowledgment button with an action,
 * and optional cancel and additional buttons. The cancel button, if provided,
 * simply closes the dialog using the onCloseDialog callback.
 */
export default function AcknowledgmentDialog({
  acknowledgmentDisabled = false,
  acknowledgmentText,
  additionalButtonText,
  buttonText = 'OK',
  cancelButtonText,
  onAcknowledgment,
  onAdditionalAction,
  onCloseDialog,
  title,
}: AcknowledgmentDialogProps) {
  return (
    <Modal
      aria-label='Acknowledgment Dialog'
      data-testid='acknowledgment-dialog'
      onClose={onCloseDialog}
      open
    >
      <div
        className={clsx(
          'flex flex-col gap-3 p-6 bg-white top-1/2 left-1/2',
          'transform -translate-x-1/2 -translate-y-1/2 rounded-md',
          'absolute max-sm:text-center max-sm:items-center w-4/5 sm:w-1/2',
        )}
      >
        <div className='text-lg font-semibold'>{title}</div>
        <p
          aria-label='Acknowledgment Text'
          className={`text-sm text-gray-500 ${
            acknowledgmentDisabled ? 'opacity-50' : ''
          }`}
        >
          {acknowledgmentText}
        </p>
        <div className='flex sm:justify-end gap-2'>
          <Button
            aria-label='Acknowledge Button'
            disabled={acknowledgmentDisabled}
            onClick={onAcknowledgment}
          >
            {buttonText}
          </Button>
          {cancelButtonText && (
            <Button
              aria-label='Cancel Button'
              disabled={acknowledgmentDisabled}
              onClick={onCloseDialog}
              secondary
            >
              {cancelButtonText}
            </Button>
          )}
          {additionalButtonText && (
            <Button
              aria-label='Additional Action'
              disabled={acknowledgmentDisabled}
              onClick={onAdditionalAction}
              secondary
            >
              {additionalButtonText}
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
}
