import { PlusIcon } from '@heroicons/react/24/solid';
import { FilePreview, UploadState } from 'components/Attachment/FilePreview';
import { WarningMessage } from 'components/WarningMessage';
import dotProp from 'dot-prop-immutable';
import { taskEditSlice } from 'features/tasks/taskEditSlice';
import { FC, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';

type AddAttachmentsProps = {
  /**
   * The number of attachments already in the task, before adding more
   */
  currentCount: number;
};

export const AddAttachments: FC<AddAttachmentsProps> = ({ currentCount }) => {
  const dispatch = useAppDispatch();
  const maxFileSize = 20 * 1024 * 1024; // 20MB
  const newAttachments = useSelector(taskEditSlice.selectors.newAttachments);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const [states, setStates] = useState<UploadState[]>([]);

  const limit = 10;

  const hasErrorFiles = useSelector(taskEditSlice.selectors.hasErrorFiles);

  const handleFileEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFiles = e.target.files;
    const filesToAdd = [] as File[];

    if (newFiles) {
      for (const file of Array.from(newFiles)) {
        if (!newAttachments.some((f) => f.mediaFile.fileName === file.name)) {
          filesToAdd.push(file);
        }
      }
    }

    const newMediaParam = filesToAdd.map((file) => ({
      description: '',
      mediaFile: {
        data: file,
        fileName: file.name,
      },
    }));

    dispatch(
      taskEditSlice.actions.setTaskField({
        field: 'newAttachments',
        value: [...newAttachments, ...newMediaParam],
      }),
    );
    setStates((prev) => [
      ...prev,
      ...filesToAdd.map(() => 'pending' as UploadState),
    ]);
  };

  const removeFile = (name: string) => {
    const index = newAttachments.findIndex(
      (file) => file.mediaFile.fileName === name,
    );
    if (index !== -1) {
      dispatch(
        taskEditSlice.actions.setTaskField({
          field: 'newAttachments',
          value: newAttachments.toSpliced(index, 1),
        }),
      );

      setStates((prev) => prev.toSpliced(index, 1));
    }
  };

  return (
    <div className='h-full flex flex-col justify-between text-gray-700'>
      <div className='flex-1 overflow-y-auto'>
        <div aria-label='Attachments' className='pb-6 flex flex-col gap-3'>
          {currentCount + newAttachments.length > limit && (
            <WarningMessage>
              You can upload up to {limit} attachments to a task. Please remove
              some files before saving.
            </WarningMessage>
          )}
          {currentCount == limit && (
            <WarningMessage variant='light'>
              This task has the maximum number of attachments allowed ({limit}).
            </WarningMessage>
          )}
          {currentCount < limit &&
            currentCount + newAttachments.length == limit && (
              <WarningMessage variant='light'>
                You can upload up to {limit} attachments to a task
              </WarningMessage>
            )}
          {newAttachments.some(
            (file) => (file.mediaFile.data as File).size > maxFileSize,
          ) && (
            <WarningMessage variant='warning'>
              File size too large; limit 20 MB per attachment.
            </WarningMessage>
          )}
          <div className='space-y-2'>
            <input
              className='hidden'
              data-testid='file-upload-input'
              disabled={hasErrorFiles}
              multiple
              onChange={handleFileEvent}
              ref={fileInputRef}
              type='file'
            />
            <button
              className='flex gap-2 items-center text-green-base'
              onClick={() => fileInputRef.current?.click()}
            >
              <PlusIcon className='w-5' /> Add Attachments
            </button>
            <div className='text-xs text-gray-400'>
              Image and PDF files up to 20MB
            </div>
          </div>
          <div className='flex flex-col gap-3'>
            {newAttachments.map((file, index) => (
              <div key={file.mediaFile.fileName}>
                <FilePreview
                  description={file.description ?? ''}
                  file={file.mediaFile.data as File}
                  index={currentCount + index}
                  key={file.mediaFile.fileName}
                  onRemove={() => {
                    if (!hasErrorFiles) {
                      removeFile(file.mediaFile.fileName);
                    }
                  }}
                  setDescription={(desc) =>
                    dispatch(
                      taskEditSlice.actions.setTaskField({
                        field: 'newAttachments',
                        value: dotProp.set(
                          newAttachments,
                          `${index}.description`,
                          desc,
                        ),
                      }),
                    )
                  }
                  state={states[index]}
                  warning={(file.mediaFile.data as File).size > maxFileSize}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
