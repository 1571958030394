import { Inspection, InspectionStatus } from '@dakota/platform-client';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { clsx } from 'clsx';
import { Print } from 'components/Inspections/Print';
import { ResultsBar } from 'components/Inspections/ResultsBar';
import { ZoneCell } from 'components/Inspections/ZoneCell';
import { FC } from 'react';
import resolveConfig from 'tailwindcss/resolveConfig';
import { getStatusLabel } from 'utils/inspectionStatusLabel';

import tailwindConfig from '../../../../tailwind.config';
import { InspectionDetailsButton } from './inspectionDetailsButton';
import { StartButton } from './start';

type InspectionCardProps = {
  inspection: Inspection;
  openInspection: () => void;
};

export const InspectionCard: FC<InspectionCardProps> = ({
  inspection,
  openInspection,
}) => {
  const { theme } = resolveConfig(tailwindConfig);

  const renderResultsBar =
    inspection.status === InspectionStatus.InProgress ||
    inspection.status === InspectionStatus.Completed ||
    inspection.status === InspectionStatus.Canceled;

  return (
    <div
      className={clsx(
        'border border-gray-100 rounded-md group',
        'flex justify-between text-gray-700',
        'hover:border-gray-300 *:group-hover:border-gray-300',
      )}
    >
      <div className='flex-1 flex flex-col'>
        <div
          className={clsx(
            'flex-none min-h-12 px-4 py-2 flex items-center justify-between',
            'border-b group-hover:border-gray-300 rounded-tl-md font-bold',
            {
              'rounded-tr-md': inspection.status === InspectionStatus.Completed,
            },
            inspection.status === InspectionStatus.Overdue
              ? 'bg-red-base bg-opacity-10 group-hover:bg-opacity-20'
              : 'bg-gray-50 group-hover:bg-gray-100',
          )}
          data-testid='title-row'
        >
          <button className='w-full text-start' onClick={openInspection}>
            {inspection.form.name}
          </button>
          {inspection.status === InspectionStatus.Overdue && (
            <button
              className={clsx(
                'flex-none w-32 flex items-center justify-end gap-1',
                'font-medium text-red-base',
              )}
              onClick={openInspection}
            >
              <ExclamationCircleIcon className='w-5 h-5' /> Overdue
            </button>
          )}
        </div>
        <div
          className={clsx(
            'flex-1 min-h-24 p-4',
            'flex sm:items-center sm:justify-between gap-4',
          )}
        >
          <button className='w-full text-start' onClick={openInspection}>
            <div className='text-sm font-semibold'>Description</div>
            <div
              className={clsx('text-balance', {
                italic: !inspection.form.description,
              })}
              style={
                !inspection.form.description
                  ? { color: theme.colors.gray[400] }
                  : undefined
              }
            >
              {inspection.form.description ?? 'No description.'}
            </div>
          </button>
          <div className='flex-none w-40 text-end'>
            <div>{getStatusLabel(inspection.status)}</div>
          </div>
        </div>
        <div
          className={clsx(
            'flex-none px-4 py-2 flex sm:items-center sm:justify-between',
            'border-t group-hover:border-gray-300',
          )}
        >
          <div className='flex items-center gap-2'>
            <InspectionDetailsButton
              inspection={inspection}
              onClick={openInspection}
            />
            <ZoneCell name={inspection.form.name} zones={inspection.zones} />
          </div>
          <div className='flex items-center gap-4'>
            {/* @TODO: Pass in actual values once BE updates or remove until BE is capable of handling this */}
            {/* <Counters
              comments={}
              media={}
              onClick={openInspection}
            /> */}
            <Print inspection={inspection} />
          </div>
        </div>
      </div>
      <div
        className={clsx(
          'flex items-center justify-center w-24',
          'sm:min-h-40 sm:border-s border-gray-100 group-hover:border-gray-300',
        )}
      >
        {renderResultsBar ? (
          <ResultsBar
            prompts={inspection.prompts}
            rotate
            status={inspection.status}
          />
        ) : (
          <StartButton onClick={openInspection} />
        )}
      </div>
    </div>
  );
};
