import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { clsx } from 'clsx';
import { FC, InputHTMLAttributes, useRef, useState } from 'react';

interface CustomSearchInputProps extends InputHTMLAttributes<HTMLInputElement> {
  /**
   * Classes applied to the input element.
   */
  className?: string;
  /**
   * Classes applied to the outer container. Use cautiously,
   * mostly to set the width of the input.
   */
  containerClasses?: string;
  /**
   * Function called when the user types in the search input.
   */
  onSearch: (value: string) => void;
}

const SearchInput: FC<CustomSearchInputProps> = ({
  className,
  containerClasses = '',
  onSearch,
  placeholder = 'Search',
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [query, setQuery] = useState('');

  const inputBaseClasses = clsx(
    'placeholder:text-gray-300 placeholder:text-sm w-full',
    'border-none p-0 m-0 focus:ring-0',
    'disabled:cursor-not-allowed',
    'placeholder:font-normal',
  );

  const containerBaseClasses = clsx(
    'group max-sm:w-full sm:w-52 h-9 p-2 flex gap-1',
    'rounded-md shadow-sm border border-gray-300',
    'text-base text-sm text-gray-700',
    'has-[:disabled]:bg-gray-200 has-[:disabled]:cursor-not-allowed',
    'focus-within:outline-blue-base focus-within:outline-2',
    'focus-within:outline-offset-0 focus-within:outline',
  );

  return (
    <div className={clsx(containerBaseClasses, containerClasses)}>
      <MagnifyingGlassIcon
        className={clsx(
          'inline-block w-5 text-gray-400',
          'group-focus-within:text-gray-500',
          'group-has-[:disabled]:text-gray-300',
        )}
        onClick={() => inputRef.current?.focus()}
      />
      <input
        className={clsx(inputBaseClasses, className)}
        onChange={(e) => {
          setQuery(e.target.value);
          onSearch(e.target.value);
        }}
        placeholder={placeholder}
        ref={inputRef}
        style={{ background: 'transparent' }}
        type='search'
        value={query}
        {...rest}
      />
    </div>
  );
};

export default SearchInput;
