import { taskEditSlice } from 'features/tasks/taskEditSlice';
import { FC } from 'react';
import { useSelector } from 'react-redux';

export const Facility: FC = () => {
  const facility = useSelector(taskEditSlice.selectors.facility);

  return (
    <div>
      <label className='flex-1' htmlFor='task-facility'>
        Facility
      </label>
      <div className='font-bold' id='task-facility'>
        {facility?.name}
      </div>
    </div>
  );
};
