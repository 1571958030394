import { InspectionDetails } from '@dakota/platform-client';
import { ClipboardDocumentIcon } from '@heroicons/react/24/outline';
import { clsx } from 'clsx';
import Button from 'components/Button';
import Confirmation from 'components/SimpleConfirmation';
import { WarningMessage } from 'components/WarningMessage';
import { configSlice } from 'features/config/configSlice';
import {
  cancelInspection,
  startInspection,
} from 'features/inspections/inspectionActions';
import { inspectionSlice } from 'features/inspections/inspectionSlice';
import { tokenSlice } from 'features/token/tokenSlice';
import useToast from 'hooks/useToast';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { formatBackendDate } from 'utils/date';

import { Header } from './Header';

type StartOptionsProps = {
  inspectionDetails: InspectionDetails;
  navigateAway: () => void;
};

export const StartOptions: FC<StartOptionsProps> = ({
  inspectionDetails: inspection,
  navigateAway,
}) => {
  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);

  const { setErrorMessage, setSuccessMessage } = useToast();

  const [startingInspection, setStartingInspection] = useState(false);
  const [cancelingInspection, setCancelingInspection] = useState(false);
  const [showCancellationDialog, setShowCancellationDialog] = useState(false);

  const doStartInspection = (id: string) => {
    setStartingInspection(true);
    const startData = { baseUrl, id, token };

    dispatch(startInspection(startData))
      .unwrap()
      .then(() => {
        setSuccessMessage('Inspection started');
      })
      .catch(() => {
        setErrorMessage('Failed to start inspection');
      })
      .finally(() => {
        setStartingInspection(false);
      });
  };

  const doCancelInspection = (id: string) => {
    setShowCancellationDialog(false);
    setCancelingInspection(true);
    const cancelData = { baseUrl, id, token };

    dispatch(cancelInspection(cancelData))
      .unwrap()
      .then(() => {
        dispatch(inspectionSlice.actions.clearInspection());
        setSuccessMessage('Inspection canceled');
        navigateAway();
      })
      .catch(() => {
        setErrorMessage('Failed to cancel inspection');
      })
      .finally(() => {
        setCancelingInspection(false);
      });
  };

  return (
    <div className='w-full h-full flex flex-col'>
      <Header inspectionDetails={inspection} onClose={navigateAway} />
      <div
        className={clsx(
          'h-full flex flex-col gap-7 justify-center items-center',
          'text-lg text-gray-700',
        )}
      >
        <ClipboardDocumentIcon className='w-24 h-24 text-gray-400' />
        <div className='text-center'>
          The inspection <em>{inspection.form.name}</em> is scheduled for{' '}
          {formatBackendDate(inspection.timeline.scheduledDate)}.
        </div>
        {inspection.userId === undefined && (
          <WarningMessage variant='light'>
            This inspection has no assigned inspector. If you start it, it will
            be assigned to you.
          </WarningMessage>
        )}
        <Button
          className='text-lg'
          loading={startingInspection}
          onClick={() => doStartInspection(inspection.id)}
        >
          {startingInspection ? 'Starting...' : 'Start Inspection Now'}
        </Button>
        <Button
          alert
          className='text-sm'
          loading={cancelingInspection}
          onClick={() => setShowCancellationDialog(true)}
        >
          {cancelingInspection ? 'Canceling...' : 'Cancel Inspection'}
        </Button>
        <Button className='text-sm' onClick={navigateAway} secondary>
          Return to Table
        </Button>
        {showCancellationDialog && (
          <Confirmation
            cancelText='Return to Inspection'
            confirmLabel='Confirm Cancel Inspection'
            confirmText='Cancel Inspection'
            onCancel={() => setShowCancellationDialog(false)}
            onConfirm={() => doCancelInspection(inspection.id)}
          >
            Are you sure you want to cancel this inspection? It will be removed
            from your schedule.
          </Confirmation>
        )}
      </div>
    </div>
  );
};
