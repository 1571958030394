import { Cancel, CheckCircle, Info, Warning } from '@mui/icons-material';
import { Alert, AlertColor, Snackbar, useTheme } from '@mui/material';
import { toastSlice } from 'features/toast/toastSlice';
import useToast from 'hooks/useToast';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

/**
 * Based on an example on MUI's documentation.
 * @see https://mui.com/material-ui/react-snackbar/#consecutive-snackbars
 */
const ToastContainer: FC = () => {
  interface ToastMessage {
    key: number;
    text: string;
  }

  const [snackPack, setSnackPack] = useState<readonly ToastMessage[]>([]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState<ToastMessage | undefined>(undefined);
  const [severity, setSeverity] = useState<AlertColor>('success');

  const theme = useTheme();

  const errorMessage = useSelector(toastSlice.selectors.errorMessage);
  const infoMessage = useSelector(toastSlice.selectors.infoMessage);
  const successMessage = useSelector(toastSlice.selectors.successMessage);
  const warningMessage = useSelector(toastSlice.selectors.warningMessage);

  const {
    setErrorMessage,
    setInfoMessage,
    setSuccessMessage,
    setWarningMessage,
  } = useToast();

  useEffect(() => {
    if (snackPack.length && !message) {
      // Set a new snack when we don't have an active one
      setMessage({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setOpen(true);
    } else if (snackPack.length && message && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [snackPack, message, open]);

  const addMsg = (text: string, sev: AlertColor) => {
    setSnackPack((prev) => [...prev, { key: new Date().getTime(), text }]);
    setSeverity(sev);
  };

  useEffect(() => {
    if (errorMessage) {
      addMsg(errorMessage, 'error');
    }
  }, [errorMessage]);

  useEffect(() => {
    if (infoMessage) {
      addMsg(infoMessage, 'info');
    }
  }, [infoMessage]);

  useEffect(() => {
    if (successMessage) {
      addMsg(successMessage, 'success');
    }
  }, [successMessage]);

  useEffect(() => {
    if (warningMessage) {
      addMsg(warningMessage, 'warning');
    }
  }, [warningMessage]);

  const handleClose = (_: Event | React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleExited = () => {
    setErrorMessage('');
    setInfoMessage('');
    setSuccessMessage('');
    setWarningMessage('');
    setMessage(undefined);
  };

  return (
    <Snackbar
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      autoHideDuration={5000}
      key={message?.key}
      onClose={handleClose}
      open={open}
      TransitionProps={{ onExited: handleExited }}
    >
      {message && (
        <Alert
          data-testid='toast-message'
          elevation={6}
          iconMapping={{
            error: <Cancel />,
            info: <Info />,
            success: <CheckCircle />,
            warning: <Warning />,
          }}
          onClose={handleClose}
          severity={severity}
          style={{
            backgroundColor: theme.palette[severity].main,
            color: theme.palette[severity].contrastText,
          }}
          sx={{ width: '100%' }}
          variant='filled'
        >
          {message.text}
        </Alert>
      )}
    </Snackbar>
  );
};

export default ToastContainer;
