import { createSlice, current } from '@reduxjs/toolkit';

import { fetchConfig } from './configActions';
import { DakotaConfig } from './types';

const initialState: DakotaConfig = {
  appInfo: {
    environment: '',
    name: '',
    version: '',
  },
  appInsights: null,
  auth: {
    audience: '',
    clientId: '',
    domain: '',
  },
  backend: '',
  cdnUrl: '',
  pendo: null,
};

export const configSlice = createSlice({
  extraReducers(builder) {
    builder.addCase(fetchConfig.fulfilled, (state, action) => {
      // return the original/current state if the new one is identical json
      // this way we don't trigger re-renders everywhere unless there's an
      // update
      const candidateState = {
        ...action.payload,
        cdnUrl: action.payload.cdnUrl.replace(/\/$/, ''),
      };
      if (JSON.stringify(current(state)) === JSON.stringify(candidateState)) {
        return state;
      }
      return candidateState;
    });
  },
  initialState,
  name: 'config',
  reducers: {},
  selectors: {
    appName: (state: DakotaConfig) => state.appInfo.name,
    backend: (state: DakotaConfig) => state.backend,
    config: (state: DakotaConfig) => state,
  },
});
