import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { clsx } from 'clsx';
import { FC, Fragment, ReactNode } from 'react';

import EscapeEventHandler from './escapeEventHandler';

interface DialogProps {
  children: ReactNode;
  className?: string;
  /**
   * The height class that the content of the dialog should have.
   * Only used when `fullViewport` is `false`.
   */
  contentHeight?: string;
  /**
   * The width class that the content of the dialog should have.
   * Only used when `fullViewport` is `false`.
   */
  contentWidth?: string;
  detailText?: string;
  /**
   * When true, adds a class of "py-2" to content container, when false, adds a
   * class of "py-0".
   *
   * @default `true`
   */
  enableVerticalPadding?: boolean;
  footerContent?: ReactNode;
  fullViewport?: boolean;
  headerBgColor?: string;
  headerTextColor?: string;
  highlightText?: string;
  /**
   * Renders a ReactNode to the left of the title and subtitle nodes
   */
  icon?: ReactNode;
  /**
   * Used as ID and data-testid for the dialog.
   */
  id?: string;
  isOpen: boolean;
  onClose: () => void;
  /**
   * When true, the dialog will not close when the escape key is pressed.
   */
  preventCloseOnEscape?: boolean;
  subTitle?: ReactNode;
  /**
   * The color of the subtitle text, which can be different to the title.
   * @default `headerTextColor`
   */
  subTitleTextColor?: string;
  title?: string;
}

const CustomDialog: FC<DialogProps> = ({
  children,
  className = '',
  contentHeight,
  contentWidth,
  detailText,
  enableVerticalPadding = true,
  footerContent,
  fullViewport = false,
  headerBgColor = 'bg-white',
  headerTextColor,
  highlightText,
  icon,
  id,
  isOpen,
  onClose,
  preventCloseOnEscape,
  subTitle,
  subTitleTextColor = headerTextColor,
  title,
}) => {
  return (
    <Transition appear as={Fragment} show={isOpen}>
      <Dialog
        as='div'
        className={`fixed inset-0 z-10 overflow-y-auto ${className}`}
        data-testid={id}
        id={id}
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <Dialog.Overlay className='fixed inset-0 bg-black bg-opacity-30' />
        </Transition.Child>
        {preventCloseOnEscape && <EscapeEventHandler />}
        <div
          className={`flex items-center justify-center min-h-screen ${
            fullViewport ? '' : 'p-4'
          }`}
        >
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 scale-95'
            enterTo='opacity-100 scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 scale-100'
            leaveTo='opacity-0 scale-95'
          >
            <div
              className={clsx(
                fullViewport ? 'w-screen h-screen rounded-none' : 'w-3/4',
                'overflow-hidden text-left align-middle transition-all',
                'transform bg-white shadow-xl rounded-md flex flex-col',
                fullViewport ? '' : contentWidth,
              )}
              data-testid='custom-dialog'
            >
              <div
                className={clsx(
                  `${headerBgColor} px-4 py-4 h-20 flex-none`,
                  'flex items-center justify-between border-b border-gray-200',
                )}
                data-testid='dialog-header'
              >
                <div className='flex items-center'>
                  {icon && <div className='mr-4'>{icon}</div>}
                  <div className='flex flex-col'>
                    {title && (
                      <Dialog.Title
                        as='h3'
                        className={`text-lg font-sans leading-6 ${headerTextColor}`}
                        data-testid='dialog-title'
                      >
                        {title}
                      </Dialog.Title>
                    )}
                    {subTitle && (
                      <div
                        className={`text-xs max-sm:hidden ${subTitleTextColor}`}
                        data-testid='dialog-subTitle'
                      >
                        {subTitle}
                      </div>
                    )}
                  </div>
                </div>
                <div className='flex items-center space-x-2'>
                  {highlightText && (
                    <div className='hidden sm:flex flex-col items-end text-right'>
                      <h3
                        className={`text-lg font-medium leading-6 ${headerTextColor}`}
                        data-testid='dialog-highlightText'
                      >
                        {highlightText}
                      </h3>
                      {detailText && (
                        <p
                          className={`text-xs ${headerTextColor}`}
                          data-testid='dialog-detailText'
                        >
                          {detailText}
                        </p>
                      )}
                    </div>
                  )}

                  <button
                    aria-label='Close dialog'
                    className=' sm:inline-flex items-center justify-center p-2 text-gray-500 rounded-md hover:text-gray-600 focus:border-green-base'
                    onClick={onClose}
                    type='button'
                  >
                    <span className='sr-only'>Close</span>
                    <XMarkIcon
                      aria-hidden='true'
                      className={`w-6 h-6 ${
                        headerBgColor !== 'bg-white' ? 'text-white' : ''
                      }`}
                    />
                  </button>
                </div>
              </div>
              <div
                className={clsx(
                  'flex-1 px-4 overflow-y-auto font-sans bg-white',
                  enableVerticalPadding ? 'py-2' : 'py-0',
                  fullViewport ? '' : contentHeight,
                )}
                data-testid='dialog-body'
              >
                {children}
              </div>
              {footerContent && (
                <div
                  className={clsx(
                    'flex-none h-16 flex items-center justify-start px-2 sm:px-10',
                    'py-2 mt-auto space-x-2 bg-white border-t border-gray-200',
                  )}
                  data-testid='dialog-footer'
                >
                  {footerContent}
                </div>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default CustomDialog;
