import { Group } from '@dakota/platform-client';
import { PencilIcon } from '@heroicons/react/24/outline';
import { clsx } from 'clsx';
import { DropdownMenu } from 'components/DropdownMenu';
import Toggle from 'components/Toggle';
import Tooltip from 'components/Tooltip';
import { configSlice } from 'features/config/configSlice';
import {
  activateItemGroup,
  deactivateItemGroup,
} from 'features/items/itemGroupsActions';
import { tokenSlice } from 'features/token/tokenSlice';
import useToast from 'hooks/useToast';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';

import AddEditItemGroup from './addItemGroup';

const ItemGroupsMenu: FC<{ itemGroup: Group }> = ({ itemGroup }) => {
  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);

  const [active, setActive] = useState(!itemGroup.inactive);
  const [isEditItemGroupPanelOpen, setIsEditItemGroupPanelOpen] =
    useState(false);
  const [processingActivation, setProcessingActivation] = useState(false);

  const { setErrorMessage, setSuccessMessage } = useToast();

  const isDeactivationDisabled =
    itemGroup.itemTypes.filter((i) => !i.inactive).length > 0;

  const processActivation = (newActiveState: boolean) => {
    setActive(newActiveState);
    setProcessingActivation(true);

    if (newActiveState) {
      dispatch(activateItemGroup({ baseUrl, id: itemGroup.id, token }))
        .unwrap()
        .then(() => {
          setSuccessMessage('Item group activated successfully');
        })
        .catch(() => {
          setErrorMessage('Failed to activate item group');
        });
    } else {
      dispatch(deactivateItemGroup({ baseUrl, id: itemGroup.id, token }))
        .unwrap()
        .then(() => {
          setSuccessMessage('Item group deactivated successfully');
        })
        .catch(() => {
          setErrorMessage('Failed to deactivate item group');
        });
    }

    setProcessingActivation(false);
  };

  const toggle = (
    <Toggle
      disabled={processingActivation || isDeactivationDisabled}
      id='item-group-status-toggle'
      isOn={active}
      label='Active'
      onToggle={processActivation}
      showIcon={true}
    />
  );

  return (
    <div data-testid={`item-group-menu-${itemGroup.id}`}>
      <DropdownMenu
        buttonTestId='item-group-menu-button'
        contentTestId='item-group-contextual-menu'
      >
        <div
          className={clsx(
            'bg-white w-44 flex flex-col p-4 gap-3',
            'rounded-md shadow-md text-sm font-medium text-gray-800',
          )}
        >
          <button
            className={'flex gap-2 cursor-pointer'}
            data-testid='edit-item-group-button'
            onClick={() => setIsEditItemGroupPanelOpen(true)}
          >
            <PencilIcon className='w-4' />
            <p>Edit</p>
          </button>
          {isDeactivationDisabled ? (
            <Tooltip
              placement='top'
              title='Cannot deactivate an item group with active item types'
            >
              <div>{toggle}</div>
            </Tooltip>
          ) : (
            toggle
          )}
        </div>
      </DropdownMenu>
      {isEditItemGroupPanelOpen && (
        <AddEditItemGroup
          itemGroup={itemGroup}
          setIsOpen={setIsEditItemGroupPanelOpen}
        />
      )}
    </div>
  );
};

export default ItemGroupsMenu;
