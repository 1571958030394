import { clsx } from 'clsx';
import { FC } from 'react';

type ClearAllProps = {
  onClick: () => void;
  text?: string;
};

export const ClearAllButton: FC<ClearAllProps> = ({
  onClick,
  text = 'clear all',
}) => (
  <button
    className={clsx(
      'max-sm:inline-flex text-red-base px-1 outline-none',
      'focus:ring-2 focus:ring-red-base focus:rounded-md focus:ring-inset',
    )}
    data-testid='clear-all-button'
    onClick={onClick}
  >
    {text}
  </button>
);
