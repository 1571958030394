import { Badge, styled } from '@mui/material';
import resolveConfig from 'tailwindcss/resolveConfig';

import tailwindConfig from '../../../tailwind.config';

const { theme } = resolveConfig(tailwindConfig);

/**
 * Renders a badge in the top-right corner of the child icon component.
 */
export const IconBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    '&::after': {
      borderRadius: '50%',
      content: '""',
      height: '100%',
      left: 0,
      position: 'absolute',
      top: 0,
      width: '100%',
    },
    backgroundColor: theme.colors.green.base,
    color: theme.colors.white,
    fontSize: 10,
    height: 14,
    minWidth: 14,
    width: 14,
  },
}));
