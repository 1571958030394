import { XMarkIcon } from '@heroicons/react/24/outline';
import { Modal, Slide } from '@mui/material';
import { clsx } from 'clsx';
import { FC, PropsWithChildren, ReactElement } from 'react';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  PanelTitle: ReactElement;
  size?: 'lg' | 'sm';
  testId?: string;
};

const SidePanel: FC<PropsWithChildren<Props>> = ({
  children,
  isOpen,
  onClose,
  PanelTitle,
  size = 'sm',
  testId,
}) => (
  <Modal data-testid={testId} open={isOpen}>
    <Slide direction='left' in={isOpen} mountOnEnter unmountOnExit>
      <div
        className={clsx(
          'absolute inset-y-0 right-0 max-sm:w-full h-svh',
          'flex flex-col justify-between bg-white',
          size === 'sm' ? 'w-[400px]' : 'w-[550px]',
        )}
      >
        <div
          className={clsx(
            'min-h-16 p-5 border-b border-gray-200',
            'flex justify-between items-center',
          )}
        >
          {PanelTitle}
          <button
            className='rounded-md p-1 text-gray-500 hover:text-gray-600'
            data-testid='panel-close-button'
            onClick={onClose}
            type='button'
          >
            <span className='sr-only'>Close panel</span>
            <XMarkIcon aria-hidden='true' className='h-6 w-6' />
          </button>
        </div>
        <div className='flex-1 overflow-y-hidden'>{children}</div>
      </div>
    </Slide>
  </Modal>
);

export default SidePanel;
