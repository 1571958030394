import { templatesSlice } from 'features/templates/templatesSlice';
import { useCallback } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { useAppDispatch } from 'store/store';

/**
 * This file contains the implementation of two drag-and-drop functions
 * that cannot be (easily) tested with the current setup.
 * The file is ignored in Jest setup for coverage.
 */
export const useDragAndDrop = () => {
  const dispatch = useAppDispatch();

  const moveQuestion = useCallback(
    (itemTypeId: string) => (onEnd: DropResult) => {
      const fromIndex = onEnd.source.index;
      const toIndex = onEnd.destination?.index;
      if (toIndex === undefined) {
        return;
      }

      dispatch(
        templatesSlice.actions.moveSectionQuestion({
          fromIndex,
          sectionId: itemTypeId,
          toIndex,
        }),
      );
    },
    [dispatch],
  );

  const moveSection = useCallback(
    (onEnd: DropResult) => {
      const fromIndex = onEnd.source.index;
      const toIndex = onEnd.destination?.index;
      if (toIndex === undefined) {
        return;
      }

      dispatch(
        templatesSlice.actions.moveSection({
          fromIndex,
          toIndex,
        }),
      );
    },
    [dispatch],
  );

  return { moveQuestion, moveSection };
};
