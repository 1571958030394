import { Priority } from '@dakota/platform-client';
import Autocomplete from 'components/Autocomplete';
import { taskEditSlice } from 'features/tasks/taskEditSlice';
import { getPriorityLabel } from 'Pages/Tasks/types';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';

export const TaskPriority: FC = () => {
  const dispatch = useAppDispatch();

  const hasErrorFiles = useSelector(taskEditSlice.selectors.hasErrorFiles);

  const priority = useSelector(taskEditSlice.selectors.priority);

  return (
    <>
      <label htmlFor='task-priority-selector'>Priority</label>
      <Autocomplete
        disabled={hasErrorFiles}
        fullWidth
        getOptionLabel={getPriorityLabel}
        id='task-priority-selector'
        onChange={(value: Priority) =>
          dispatch(
            taskEditSlice.actions.setTaskField({
              field: 'priority',
              value,
            }),
          )
        }
        options={[Priority.Low, Priority.Medium, Priority.High]}
        value={priority}
      />
    </>
  );
};
