import { Frequency, Weekday } from 'rrule';

/**
 * Recurrence options as offered to the user
 * in the main recurrence dialog.
 */
export enum RecurrenceOption {
  CUSTOM = 'CUSTOM',
  DAILY = 'DAILY',
  MONTHLY_ON_DAY = 'MONTHLY_ON_DAY',
  NOT_REPEATING = 'NOT_REPEATING',
  WEEKLY_ON_WEEKDAY = 'WEEKLY_ON_WEEKDAY',
  YEARLY_ON_DAY_MONTH = 'YEARLY_ON_DAY_MONTH',
}

/**
 * A weekly recurrence can be in one of two forms:
 *  - byweekday and interval: the days of the week on which the event occurs,
 *    and the interval between each occurrence
 *    Example: every Monday and Wednesday, every 3 weeks
 *  - wkst: a single day of the week, which repeats every week
 *    Example: every Saturday
 * However, the second case can be represented with interval=1
 * and a single day selected in byweekday.
 */
export type WeeklyRecurrenceDetails = {
  byweekday: Weekday[];
};

/**
 * A monthly recurrence always has an interval ("every N months"),
 * and it can be in one of two forms:
 *   - bymonthday: the day of the month on which the event occurs
 *     Example: every month the 15th
 *   - bysetpos and byweekday: the Nth occurrence of a weekday
 *     Example: every month on the first Monday
 * Note that for the latter, byweekday is an array in the rule,
 * supporting cases like ("every first Monday and first Wednesday of the
 * month"), but we only support one weekday.
 */
export type MonthlyRecurrenceDetails =
  | { bymonthday: number }
  | { bysetpos: number; byweekday: Weekday };

/**
 * A yearly recurrence always has an interval ("every N years"),
 * and it can be in one of two forms:
 *   - bymonth and bymonthday: the day of the month on which the event occurs
 *     Example: every March on the 15th
 *   - bymonth, bysetpos and byweekday: the Nth occurrence of a weekday on a
 *     given month
 *     Example: every January on the first Monday
 */
export type YearlyRecurrenceDetails =
  | {
      bymonth: number;
      bymonthday: number;
    }
  | {
      bymonth: number;
      bysetpos: number;
      byweekday: Weekday;
    };

export type RecurrenceRepetition = { interval: number } & (
  | { freq: Frequency.DAILY }
  | ({ freq: Frequency.MONTHLY } & MonthlyRecurrenceDetails)
  | ({ freq: Frequency.WEEKLY } & WeeklyRecurrenceDetails)
  | ({ freq: Frequency.YEARLY } & YearlyRecurrenceDetails)
);

export type RecurrenceEndCondition =
  | {
      count: null;
      until: Date;
    }
  | {
      count: null;
      until: null;
    }
  | {
      count: number;
      until: null;
    };

/** The union type for the frequencies we use. */
export type RecurrenceFrequency =
  | Frequency.DAILY
  | Frequency.MONTHLY
  | Frequency.WEEKLY
  | Frequency.YEARLY;

/**
 * We only deal with four types of frequencies:
 * DAILY, WEEKLY, MONTHLY, and YEARLY.
 */
export const frequencies: RecurrenceFrequency[] = [
  Frequency.DAILY,
  Frequency.WEEKLY,
  Frequency.MONTHLY,
  Frequency.YEARLY,
];
