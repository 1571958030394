import { TaskComment } from '@dakota/platform-client';
import Note from 'components/Note';
import { configSlice } from 'features/config/configSlice';
import { taskEditSlice } from 'features/tasks/taskEditSlice';
import { deleteTaskNote, updateTaskNote } from 'features/tasks/tasksActions';
import { tokenSlice } from 'features/token/tokenSlice';
import { userSlice } from 'features/user/userSlice';
import { useCheckPermission } from 'hooks/useCheckPermission';
import useToast from 'hooks/useToast';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { Permission } from 'utils/permissions';

type NoteProps = {
  dueDate: string;
  note: TaskComment;
  noteIndex: number;
  seriesId: string;
};

export const TaskNote: FC<NoteProps> = ({
  dueDate,
  note,
  noteIndex,
  seriesId,
}) => {
  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);
  const currentUser = useSelector(userSlice.selectors.currentUser);

  const isAdmin = useCheckPermission(Permission.Admin);
  /**
   * People Managers can edit comments and they have this
   * permission.
   */
  const isPeopleManager = useCheckPermission(Permission.ManageUsers);
  const canEdit = isAdmin || isPeopleManager || currentUser.id === note.userId;

  const [updating, setUpdating] = useState(false);

  const { setErrorMessage, setSuccessMessage } = useToast();

  const updateText = (text: string) => {
    setUpdating(true);

    dispatch(
      updateTaskNote({
        baseUrl,
        dueDate,
        id: note.id,
        note: text,
        noteIndex,
        seriesId,
        token,
      }),
    )
      .unwrap()
      .then(() =>
        dispatch(taskEditSlice.actions.updateNote({ noteIndex, text })),
      )
      .then(() => setSuccessMessage('Note updated'))
      .catch(() => setErrorMessage('Failed to update note'))
      .finally(() => setUpdating(false));
  };

  const removeNote = () => {
    dispatch(
      deleteTaskNote({
        baseUrl,
        dueDate,
        id: note.id,
        noteIndex,
        seriesId,
        token,
      }),
    )
      .unwrap()
      .then((response) => dispatch(taskEditSlice.actions.deleteNote(response)))
      .then(() => setSuccessMessage('Note removed'))
      .catch(() => setErrorMessage('Failed to remove note'));
  };

  return (
    <Note
      canEdit={canEdit}
      id={`note-${noteIndex}`}
      key={note.id}
      loading={updating}
      note={note}
      onChange={updateText}
      removeNote={removeNote}
    />
  );
};
