import Autocomplete from 'components/Autocomplete';
import { taskEditSlice } from 'features/tasks/taskEditSlice';
import { useSelectedFacilityUser } from 'hooks/useSelectedFacilityUser';
import { useUsers } from 'hooks/useUsers';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';

export const Assignee: FC = () => {
  const dispatch = useAppDispatch();

  const facility = useSelector(taskEditSlice.selectors.facility);

  const hasErrorFiles = useSelector(taskEditSlice.selectors.hasErrorFiles);

  const { selectableUsers } = useSelectedFacilityUser(facility?.id);

  const { getActiveUserSummary } = useUsers();

  return (
    <>
      <label htmlFor='task-assignee-selector'>Assignee</label>
      <Autocomplete
        disabled={!facility || hasErrorFiles}
        fullWidth
        getOptionKey={(u) => u.id}
        getOptionLabel={(u) => u.name}
        id='task-assignee-selector'
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onChange={(option) =>
          dispatch(
            taskEditSlice.actions.setTaskField({
              field: 'assigneeId',
              value: option.id,
            }),
          )
        }
        options={selectableUsers}
        value={
          getActiveUserSummary(
            useSelector(taskEditSlice.selectors.assigneeId),
          ) ?? null
        }
      />
    </>
  );
};
