import { LocalDate } from '@js-joda/core';
import { Recurrence as TaskRecurrence } from 'components/recurrence';
import { taskEditSlice } from 'features/tasks/taskEditSlice';
import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';

export const Recurrence: FC = () => {
  const dispatch = useAppDispatch();

  const date = useSelector(taskEditSlice.selectors.date);
  const initialDate = useMemo(() => LocalDate.parse(date), [date]);

  return (
    <>
      <label htmlFor='recurrence-option-selector'>Recurrence</label>
      <div>
        <TaskRecurrence
          initialDate={initialDate}
          onChange={(rule) =>
            dispatch(
              taskEditSlice.actions.setTaskField({
                field: 'recurrence',
                value: rule,
              }),
            )
          }
        />
      </div>
    </>
  );
};
