import { DateTimeFormatter, LocalDate } from '@js-joda/core';
import { Locale as Locale_EN } from '@js-joda/locale_en-us';

import { RecurrenceOption } from './types';

/**
 * Convert a number to its ordinal representation in English.
 */
export const toOrdinal = (n: number): string => {
  const ordinalRules = new Intl.PluralRules('en', { type: 'ordinal' });
  switch (ordinalRules.select(n)) {
    case 'few':
      return n.toString().concat('rd');
    case 'one':
      return n.toString().concat('st');
    case 'two':
      return n.toString().concat('nd');
    default:
      return n.toString().concat('th');
  }
};

export const recurrenceOptionToString = (
  option: RecurrenceOption,
  selectedDate: LocalDate,
): string => {
  const weekdayFormatter = DateTimeFormatter.ofPattern('eeee').withLocale(
    Locale_EN.US,
  );
  const monthLongName = DateTimeFormatter.ofPattern('MMMM').withLocale(
    Locale_EN.US,
  );

  switch (option) {
    case RecurrenceOption.DAILY:
      return 'Daily';
    case RecurrenceOption.MONTHLY_ON_DAY:
      return `Monthly on the ${toOrdinal(selectedDate.dayOfMonth())}`;
    case RecurrenceOption.NOT_REPEATING:
      return 'Does not repeat';
    case RecurrenceOption.WEEKLY_ON_WEEKDAY:
      return `Weekly on ${selectedDate.format(weekdayFormatter)}`;
    case RecurrenceOption.YEARLY_ON_DAY_MONTH:
      return `Annually on ${selectedDate.format(monthLongName)} ${toOrdinal(
        selectedDate.dayOfMonth(),
      )}`;
    case RecurrenceOption.CUSTOM:
    default:
      return 'Custom';
  }
};
