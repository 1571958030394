import { TaskSeries } from '@dakota/platform-client';
import { PencilIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { clsx } from 'clsx';
import AcknowledgmentDialog from 'components/Dialog/AcknowledgmentDialog';
import { Spinner } from 'components/Spinner';
import { configSlice } from 'features/config/configSlice';
import { taskEditSlice } from 'features/tasks/taskEditSlice';
import { getTaskSeries } from 'features/tasks/tasksActions';
import { tokenSlice } from 'features/token/tokenSlice';
import { useTaskEdit } from 'hooks/useTaskEdit';
import useToast from 'hooks/useToast';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';

import { EditSeries } from '../EditSeries';
import { ChoiceDialog } from '../EditSeries/ChoiceDialog';
import { EditSeriesChoices } from '../types';

type Props = {
  /**
   * Callback to close the dialog.
   */
  onClose: () => void;
  /**
   * The id of the task series.
   */
  seriesId: string;
  /**
   * The title of the task series.
   */
  title: string;
};

export const Header: FC<Props> = ({ onClose, seriesId, title }) => {
  const dispatch = useAppDispatch();
  const { setErrorMessage, setSuccessMessage } = useToast();
  const { editTaskInstance } = useTaskEdit();

  const token = useSelector(tokenSlice.selectors.token);
  const baseUrl = useSelector(configSlice.selectors.backend);
  const hasUnsavedChanges = useSelector(
    taskEditSlice.selectors.hasUnsavedChanges,
  );

  const [showAcknowledgmentDialog, setShowAcknowledgmentDialog] =
    useState(false);
  const [showEditSeriesPrompt, setShowEditSeriesPrompt] = useState(false);
  const [editOption, setEditOption] = useState<EditSeriesChoices>(
    EditSeriesChoices.CurrentAndFollowing,
  );
  const [taskSeries, setTaskSeries] = useState<TaskSeries | undefined>(
    undefined,
  );
  const [isLoadingSeries, setIsLoadingSeries] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const openEditSeriesDialog = () => {
    setIsLoadingSeries(true);
    dispatch(getTaskSeries({ baseUrl, id: seriesId, token }))
      .unwrap()
      .then((payload) => {
        if (payload.recurrenceRule) {
          payload.recurrenceRule = payload.recurrenceRule.replace('RRULE:', '');
        }
        setTaskSeries(payload);
      })
      .catch(() => setErrorMessage('Error loading task series'))
      .finally(() => setIsLoadingSeries(false));
  };

  const editSeries = () => {
    if (hasUnsavedChanges) {
      setShowAcknowledgmentDialog(true);
    } else {
      setShowEditSeriesPrompt(true);
    }
  };

  const saveTask = () => {
    setIsSaving(true);

    editTaskInstance()
      .then(() => {
        setSuccessMessage('Task updated successfully');
        setShowAcknowledgmentDialog(false);
        setShowEditSeriesPrompt(true);
      })
      .catch(() => {
        setErrorMessage('Failed to update task');
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const closeChoiceDialog = () => {
    setShowEditSeriesPrompt(false);
  };

  const onChoiceMade = (option: EditSeriesChoices) => {
    setEditOption(option);
    setShowEditSeriesPrompt(false);
    openEditSeriesDialog();
  };

  return (
    <div className='flex items-center p-4 bg-green-base text-white h-16'>
      <div
        aria-label='Task title'
        className='flex-1 truncate text-xl font-semibold'
        id='task-title'
      >
        {title}
      </div>
      <button
        className={clsx(
          'border border-white rounded-md px-4 py-2 mx-2',
          'flex items-center justify-center gap-2',
          'disabled:opacity-50',
        )}
        disabled={isLoadingSeries}
        onClick={editSeries}
      >
        {isLoadingSeries ? <Spinner /> : <PencilIcon className='h-4 w-4' />}
        Edit Series
      </button>
      <div className='flex flex-1 justify-end'>
        <button
          aria-label='Close'
          className='flex items-center justify-center h-full'
          id='close-create-task'
          onClick={onClose}
        >
          <XMarkIcon className='h-6 w-6' />
        </button>
      </div>
      {showAcknowledgmentDialog && (
        <AcknowledgmentDialog
          acknowledgmentDisabled={isSaving}
          acknowledgmentText='You have unsaved changes. Do you want to save them before editing the series?'
          additionalButtonText='Return to form'
          buttonText='Yes'
          cancelButtonText='No'
          onAcknowledgment={saveTask}
          onAdditionalAction={() => setShowAcknowledgmentDialog(false)}
          onCloseDialog={() => {
            setShowAcknowledgmentDialog(false);
            setShowEditSeriesPrompt(true);
          }}
          title='Save Changes?'
        />
      )}
      <ChoiceDialog
        onClose={closeChoiceDialog}
        onContinue={onChoiceMade}
        open={showEditSeriesPrompt}
      />
      {taskSeries && (
        <EditSeries
          editOption={editOption}
          onClose={() => setTaskSeries(undefined)}
          task={taskSeries}
        />
      )}
    </div>
  );
};
