import { UserDetails, UserSummary } from '@dakota/platform-client';

export const unassignedUser = UserSummary.fromJS({
  email: 'Unassigned',
  id: 'Unassigned',
  inactive: false,
  name: 'Unassigned',
});

export const toUserSummary = (user: UserDetails) =>
  ({
    email: user.email,
    id: user.id,
    inactive: user.inactive,
    name: user.displayName,
  }) as UserSummary;
