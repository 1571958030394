import {
  FacilityForm,
  FormsClient,
  FormType,
  GlobalForm,
} from '@dakota/platform-client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ClientData, getClient } from 'features/clientProvider';

export const getForm = createAsyncThunk(
  'forms/getForm',
  async (params: { id: string } & ClientData) => {
    const client = getClient(FormsClient, params);
    const response = await client.getForm(params.id);
    return response.result;
  },
);

/**
 * Return the form that was created.
 */
export const createGlobalForm = createAsyncThunk(
  'forms/createGlobalForm',
  async (params: { form: GlobalForm } & ClientData) => {
    const client = getClient(FormsClient, params);
    const response = await client.createGlobalForm(params.form);
    return response.result;
  },
);

/**
 * Return the form that was created.
 */
export const createFacilityForm = createAsyncThunk(
  'forms/createFacilityForm',
  async (params: { form: FacilityForm } & ClientData) => {
    const client = getClient(FormsClient, params);
    const response = await client.createFacilityForm(params.form);
    return response.result;
  },
);

export const getAllFormSummaries = createAsyncThunk(
  'forms/listForms',
  async (params: ClientData) => {
    const client = getClient(FormsClient, params);
    const response = await client.listForms();
    return response.result;
  },
);

export const activateForm = createAsyncThunk(
  'forms/reactivateForm',
  async (params: { id: string; type: FormType } & ClientData) => {
    const client = getClient(FormsClient, params);
    if (params.type === FormType.Global) {
      await client.reactivateGlobalForm(params.id);
    } else {
      await client.reactivateFacilityForm(params.id);
    }
  },
);

export const deactivateForm = createAsyncThunk(
  'forms/deactivateForm',
  async (params: { id: string; type: FormType } & ClientData) => {
    const client = getClient(FormsClient, params);
    if (params.type === FormType.Global) {
      await client.deactivateGlobalForm(params.id);
    } else {
      await client.deactivateFacilityForm(params.id);
    }
  },
);

/**
 * Return the form that was updated.
 */
export const updateGlobalForm = createAsyncThunk(
  'forms/updateGlobalForm',
  async (params: { form: GlobalForm; id: string } & ClientData) => {
    const client = getClient(FormsClient, params);
    const response = await client.updateGlobalForm(params.id, params.form);
    return response.result;
  },
);

/**
 * Return the form that was updated.
 */
export const updateFacilityForm = createAsyncThunk(
  'forms/updateFacilityForm',
  async (params: { form: FacilityForm; id: string } & ClientData) => {
    const client = getClient(FormsClient, params);
    const response = await client.updateFacilityForm(params.id, params.form);
    return response.result;
  },
);
