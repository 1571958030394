import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { clsx } from 'clsx';
import { FC } from 'react';

type StartButtonProps = {
  onClick: () => void;
};

export const StartButton: FC<StartButtonProps> = ({ onClick }) => {
  return (
    <div
      className={clsx(
        'flex-none sm:w-24 flex sm:items-center sm:justify-center h-full',
        'sm:min-h-40 sm:border-s border-gray-100 group-hover:border-gray-300',
      )}
    >
      <button
        aria-label='Start inspection'
        className={clsx(
          'flex flex-col items-center justify-center w-full h-full',
          'text-gray-400 hover:text-green-base hover:bg-green-lightest',
          'text-xs font-semibold tracking-wider rounded-e-md',
        )}
        data-testid='start-inspection-button'
        onClick={onClick}
      >
        <CheckCircleIcon className='w-7 h-7' />
        Start Inspection
      </button>
    </div>
  );
};
