import type { Config } from 'tailwindcss';

import forms from '@tailwindcss/forms';
import colors from 'tailwindcss/colors';
import defaultTheme from 'tailwindcss/defaultTheme';

export default {
  content: [
    './index.html',
    './src/**/*.{js,ts,jsx,tsx}',
    './node_modules/react-tailwindcss-datepicker/dist/index.esm.js',
  ],
  /**
   * Hack that allows the DatePicker component to work correctly when the
   * user has dark mode enabled. The component uses 'media' dark mode type
   * from Tailwind and it doesn't seem to work with the other modes.
   */
  darkMode: 'class',
  plugins: [forms],
  theme: {
    colors: {
      /**
       * This color is only intended for use in the Avatar component,
       * where we display the user's initials with a background.
       **/
      avatar: {
        '0': colors.blue[400],
        '1': colors.cyan[700],
        '2': '#497D28', // green-base
        '3': '#EB5757', // red-base
        '4': colors.sky[700],
        '5': colors.violet[700],
        '6': '#FBBF24', // yellow-base
      },
      black: colors.black,
      blue: {
        base: colors.blue[400],
        dark: colors.blue[700],
        darker: colors.blue[800],
        light: '#CAD6E5',
        lighter: '#DCE6F2',
        lightest: colors.blue[50],
      },
      /**
       * This color is added exclusively for the DatePicker component.
       * It is not based on the Tailwind 'emerald' color, but was created
       * ad-hoc with the values the DatePicker uses, and with colors based
       * on the Dakota palette (which is why the '500' value is the
       * Dakota green). *Do not* use these colors for anything else.
       *
       * Ideally, we would have a 'datepicker' color, but the component
       * only accepts a fixed set of color names (which you can see at
       * https://react-tailwindcss-datepicker.vercel.app/theming-options)
       * so we have to use one of them, and 'emerald' is the closest to
       * 'green'.
       */
      emerald: {
        '100': '#EBFFDE',
        '200': '#BFFF96',
        '400': '#92E65E',
        '500': '#497D28',
        '600': '#345421',
        '700': '#1D380C',
      },
      gray: colors.gray,
      green: {
        base: '#497D28',
        dark: '#3A6320',
        darker: '#33571C',
        light: '#D3E0CC',
        lighter: '#E4EDDF',
        lightest: '#F5FAF2',
      },
      red: {
        base: '#EB5757',
        dark: '#B91C1C',
        darker: '#991B1B',
        light: '#E5CCCC',
        lighter: '#EDDFDF',
        lightest: '#FAF2F2',
      },
      /**
       * Only intended for the Dashboard charts, where
       * the regular red doesn't play too well as a
       * background color in a semaphore setting, and
       * the yellow looks terrible as it's too brown
       * and desaturated.
       */
      semaphore: {
        greenLight: '#EBF7E4',
        greenMedium: '#D9F7C6',
        redLight: colors.red[50],
        redMedium: colors.red[100],
        yellowLight: colors.yellow[50],
        yellowMedium: colors.yellow[100],
      },
      white: colors.white,
      yellow: {
        base: '#FBBF24',
        dark: '#B45309',
        darker: '#92400E',
        light: '#E0DBCC',
        lighter: '#EDE9DF',
        lightest: '#FBF9F3',
      },
    },
    extend: {
      backgroundImage: {
        barChartHover:
          'repeating-linear-gradient(135deg, rgba(255,255,255,.25), rgba(255,255,255,.25) 3px, rgba(255,255,255,.1) 3px, rgba(255,255,255,.1) 6px)',
        errorBackdrop: 'url(assets/error-background.png)',
        homeBackdrop: 'url(assets/home-background.jpg)',
      },
      fontFamily: {
        sans: ['Inter', ...defaultTheme.fontFamily.sans],
      },
      transitionProperty: {
        position:
          'top, right, bottom, left, position, fontSize, padding, color',
      },
    },
    screens: {
      /**
       * Another hack just for the date picker. The component uses the
       * `lg` breakpoint to decide where to place the date ranges
       * ("last 7 days", etc.), and because we don't use it, it ends up
       * with the date ranges above the months, which uses too much space.
       * This breakpoint SHOULD NOT BE USED.
       */
      lg: '1024px',
      /**
       * Yet another hack for the date picker, for similar reasons as with
       * the `lg` breakpoint. We leave this breakpoint for the date picker,
       * but we SHOULD NOT USE IT anywhere else.
       */
      md: '768px',
      sm: '480px',
    },
  },
} satisfies Config;
