import { ClockIcon } from '@heroicons/react/24/outline';
import { toHumanReadable } from 'components/recurrence/humanReadable';
import { taskEditSlice } from 'features/tasks/taskEditSlice';
import { FC } from 'react';
import { useSelector } from 'react-redux';

export const Recurrence: FC = () => {
  const recurrence = useSelector(taskEditSlice.selectors.recurrence);

  return (
    <div className='flex gap-1 items-center text-sm font-normal text-gray-700'>
      <ClockIcon className='w-6' />
      {toHumanReadable(recurrence)}
    </div>
  );
};
