import { clsx } from 'clsx';
import React, { InputHTMLAttributes } from 'react';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  /**
   * When present, it is assumed that the input is currently invalid,
   * and we display this error message below the input field.
   */
  errorExplanation?: string;
  label?: string;
}

const Input: React.FC<InputProps> = ({
  className,
  errorExplanation = '',
  label,
  ...props
}) => {
  const containerStyles = 'text-gray-700';

  const inputBaseClasses = clsx(
    'h-9 p-2 max-sm:w-full',
    'rounded-md shadow-sm border border-gray-300',
    'text-base text-sm text-gray-700',
    'disabled:bg-gray-200 disabled:cursor-not-allowed',
    'placeholder:text-gray-300',
    'placeholder:text-sm placeholder:font-normal',
    'focus:outline-blue-base focus:outline-2',
    'focus:outline-offset-0 focus:outline',
    'focus:border-gray-300',
    {
      'border-red-base text-red-base': errorExplanation,
    },
    className,
  );

  return (
    <div className={containerStyles}>
      {label && (
        <label
          className={clsx('block mb-2', { 'required-field': props.required })}
          data-testid={`${label}-id`}
          htmlFor={props.id ?? props.name}
        >
          {label}
        </label>
      )}
      <input
        className={inputBaseClasses}
        data-testid={props.id}
        disabled={props.disabled}
        {...props}
      />
      {errorExplanation && (
        <div
          className='text-xs text-end text-red-base mt-1 text-pretty'
          data-testid='input-error-message'
          role='alert'
        >
          {errorExplanation}
        </div>
      )}
    </div>
  );
};

export default Input;
