import { LocalDate } from '@js-joda/core';
import { clsx } from 'clsx';
import Autocomplete from 'components/Autocomplete';
import { DatePicker } from 'components/DatePicker';
import { NumberInput } from 'components/NumberInput';
import dotProp from 'dot-prop-immutable';
import { FC, useEffect, useState } from 'react';
import { Frequency, Weekday } from 'rrule';

import { MonthlySelection } from './MonthlySelection';
import { frequencyTypeToString, toRRuleDatetime } from './rrule';
import { stateFromInitialRecurrence } from './state';
import {
  frequencies,
  MonthlyRecurrenceDetails,
  RecurrenceEndCondition,
  RecurrenceFrequency,
  RecurrenceRepetition,
  YearlyRecurrenceDetails,
} from './types';
import { WeekdaySelection } from './WeekdaySelection';
import { YearlySelection } from './YearlySelection';

type CustomRecurrenceProps = {
  /**
   * The initial date of the recurrence.
   */
  initialDate: LocalDate;
  initialEndCondition: RecurrenceEndCondition;
  /**
   * The initial recurrence type selected in the dialog.
   * Its frequency should match what the user selected in a previous
   * dialog that opens this one, just like with the initialDate.
   * The exception is when the user had selected 'Does not repeat'
   * or 'Daily', since we force the default custom recurrence
   * to be 'Weekly' in those cases.
   */
  initialRepetition: RecurrenceRepetition;
  /**
   * Callback to update the condition to end the recurrence.
   */
  onChangeEndCondition: (cond: RecurrenceEndCondition) => void;
  /**
   * Callback to update the interval of the recurrence.
   */
  onChangeInterval: (newInterval: number) => void;
  /**
   * Callback to update the repetition of the recurrence.
   */
  onChangeRepetition: (repetition: RecurrenceRepetition) => void;
};

export const CustomRecurrence: FC<CustomRecurrenceProps> = ({
  initialDate,
  initialEndCondition,
  initialRepetition,
  onChangeEndCondition,
  onChangeInterval,
  onChangeRepetition,
}) => {
  const [opts, setOpts] = useState(
    stateFromInitialRecurrence(
      initialDate,
      initialEndCondition,
      initialRepetition,
    ),
  );

  useEffect(() => {
    setOpts(
      stateFromInitialRecurrence(
        initialDate,
        initialEndCondition,
        initialRepetition,
      ),
    );
  }, [initialDate, initialEndCondition, initialRepetition]);

  const onChangeWeekly = (days: Weekday[]) => {
    setOpts(dotProp.set(opts, 'byweekday', days));
    onChangeRepetition({
      byweekday: days,
      freq: Frequency.WEEKLY,
      interval: opts.interval,
    });
  };

  const onChangeMonthly = (details: MonthlyRecurrenceDetails) => {
    if ('bymonthday' in details) {
      setOpts(dotProp.set(opts, 'bymonthday', details.bymonthday));
    } else {
      setOpts(dotProp.set(opts, 'byweekday', [details.byweekday]));
      setOpts(dotProp.set(opts, 'bymonthday', details.bysetpos));
    }

    onChangeRepetition({
      freq: Frequency.MONTHLY,
      interval: opts.interval,
      ...details,
    });
  };

  const onChangeYearly = (details: YearlyRecurrenceDetails) => {
    setOpts(dotProp.set(opts, 'bymonth', details.bymonth));
    if ('bymonthday' in details) {
      setOpts(dotProp.set(opts, 'bymonthday', details.bymonthday));
    } else {
      setOpts(dotProp.set(opts, 'bysetpos', details.bysetpos));
      setOpts(dotProp.set(opts, 'byweekday', [details.byweekday]));
    }

    onChangeRepetition({
      freq: Frequency.YEARLY,
      interval: opts.interval,
      ...details,
    });
  };

  const onChangeFrequency = (newFrequency: RecurrenceFrequency) => {
    setOpts(dotProp.set(opts, 'freq', newFrequency));

    if (newFrequency === Frequency.DAILY) {
      onChangeRepetition({ freq: Frequency.DAILY, interval: opts.interval });
    } else if (newFrequency === Frequency.WEEKLY) {
      onChangeWeekly(opts.byweekday);
    } else if (newFrequency === Frequency.MONTHLY) {
      onChangeMonthly({ bymonthday: opts.bymonthday });
    } else {
      onChangeYearly({ bymonth: opts.bymonth, bymonthday: opts.bymonthday });
    }
  };

  const setEndToNever = () => {
    setOpts(dotProp.set(opts, 'endType', 'never'));
    onChangeEndCondition({ count: null, until: null });
  };

  const setEndToCount = (count: number) => {
    setOpts(dotProp.set(opts, 'endType', 'count'));
    setOpts(dotProp.set(opts, 'count', count));
    onChangeEndCondition({ count, until: null });
  };

  const setEndToUntil = (until: LocalDate) => {
    setOpts(dotProp.set(opts, 'endType', 'until'));
    setOpts(dotProp.set(opts, 'until', until));
    onChangeEndCondition({ count: null, until: toRRuleDatetime(until) });
  };

  return (
    <div
      className={clsx(
        'w-full p-4 border border-gray-300 rounded-md',
        'text-sm text-gray-700',
      )}
    >
      <div className='flex flex-col gap-4'>
        <div className='font-bold'>Custom recurrence</div>
        <div className='flex justify-between items-center'>
          <div>Repeat every</div>
          <div className='flex items-center gap-0.5'>
            <NumberInput
              aria-label='Interval'
              initialValue={opts.interval}
              min={1}
              onChangeValue={(value) => {
                setOpts(dotProp.set(opts, 'interval', value));
                onChangeInterval(value);
              }}
            />
            <Autocomplete
              className='w-28'
              getOptionLabel={(type) =>
                frequencyTypeToString(type, opts.interval)
              }
              id='recurrence-frequency'
              onChange={onChangeFrequency}
              options={frequencies}
              value={opts.freq}
            />
          </div>
        </div>
        <div
          className={clsx('flex justify-between items-center', {
            hidden: opts.freq !== Frequency.WEEKLY,
          })}
        >
          <div>Repeat on</div>
          <WeekdaySelection
            initialSelection={opts.byweekday}
            onChange={onChangeWeekly}
          />
        </div>
        {opts.freq === Frequency.MONTHLY && (
          <MonthlySelection
            initialBySetPos={opts.bysetpos}
            initialDayOfMonth={opts.bymonthday}
            initialType={opts.monthlyType}
            initialWeekday={opts.byweekday[0]}
            onChange={onChangeMonthly}
          />
        )}
        {opts.freq === Frequency.YEARLY && (
          <YearlySelection
            initialBySetPos={opts.bysetpos}
            initialDayOfMonth={opts.bymonthday}
            initialMonthOfYear={opts.bymonth}
            initialType={opts.yearlyType}
            initialWeekday={opts.byweekday[0]}
            onChange={onChangeYearly}
          />
        )}
        <div className='font-bold'>Ends</div>
        <div className='flex items-center gap-1 h-9'>
          <input
            checked={opts.endType === 'never'}
            id='end-never'
            name='end'
            onChange={() => setEndToNever()}
            type='radio'
          />
          <label htmlFor='end-never'>Never</label>
        </div>
        <div className='flex justify-between items-center gap-2'>
          <div className='flex items-center gap-1'>
            <input
              checked={opts.endType === 'until'}
              id='end-until'
              name='end'
              onChange={() => setEndToUntil(initialDate)}
              type='radio'
            />
            <label htmlFor='end-until'>On this date</label>
          </div>
          <div className='place-self-end'>
            <DatePicker
              asSingle
              disabled={opts.endType !== 'until'}
              id='end-until-date'
              onChange={setEndToUntil}
              value={opts.until}
            />
          </div>
        </div>
        <div className='flex justify-between items-center gap-2'>
          <div className='flex items-center gap-1'>
            <input
              checked={opts.endType === 'count'}
              id='end-count'
              name='end'
              onChange={() => setEndToCount(opts.count)}
              type='radio'
            />
            <label htmlFor='end-count'>After</label>
          </div>
          <div className='place-self-end flex items-center gap-1'>
            <NumberInput
              aria-label='End count'
              disabled={opts.endType !== 'count'}
              initialValue={opts.count}
              min={1}
              onChangeValue={setEndToCount}
              suffix={(value: number) => `occurrence${value !== 1 ? 's' : ''}`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
