import { AddMediaParams } from '@dakota/platform-client';
import { configSlice } from 'features/config/configSlice';
import { taskEditSlice } from 'features/tasks/taskEditSlice';
import {
  addAttachmentToTask,
  createTask,
  updateTaskInstance,
} from 'features/tasks/tasksActions';
import { tokenSlice } from 'features/token/tokenSlice';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';

/**
 * Functions to save new tasks and edit existing tasks in the backend.
 */
export const useTaskEdit = () => {
  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);

  const createTaskRequest = useSelector(
    taskEditSlice.selectors.createTaskRequest,
  );

  const editTaskInstanceRequest = useSelector(
    taskEditSlice.selectors.editTaskInstanceRequest,
  );

  const newAttachments = useSelector(taskEditSlice.selectors.newAttachments);

  /**
   * Add current attachments to the given task series. Returns an array of the
   * filenames of the files that failed to upload. If all uploads are
   * successful (including when there is no file to upload), it returns an
   * empty array.
   */
  const addAttachments = async (seriesId: string, dueDate: string) =>
    Promise.all(
      newAttachments.map(async (attachment) => {
        const options = {
          description: attachment.description,
          mediaFile: {
            data: attachment.mediaFile.data as File,
            fileName: attachment.mediaFile.fileName,
          },
        } as AddMediaParams;
        return await dispatch(
          addAttachmentToTask({ baseUrl, dueDate, options, seriesId, token }),
        )
          .unwrap()
          .then(() => undefined)
          .catch(() => attachment.mediaFile.fileName);
      }),
    ).then((promises) => promises.filter((result) => result !== undefined));

  const createNewTask = async () =>
    await dispatch(
      createTask({ baseUrl, task: createTaskRequest, token }),
    ).unwrap();

  const editTaskInstance = () =>
    dispatch(
      updateTaskInstance({ baseUrl, ...editTaskInstanceRequest, token }),
    ).unwrap();

  return {
    addAttachments,
    createNewTask,
    editTaskInstance,
  };
};
