import { Summary, Zone } from '@dakota/platform-client';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  createZone,
  deactivateZone,
  listZones,
  reactivateZone,
  updateZone,
} from './zonesActions';

export type ZoneState = {
  /**
   * Zones when scheduling or rezoning an inspection.
   * The order is the order selected by the user.
   */
  orderedZones: Summary[];
  /**
   * List of zones for each facility
   */
  zonesPerFacility: Map<string, Zone[]>;
};

const initialState: ZoneState = {
  orderedZones: [],
  zonesPerFacility: new Map(),
};

export const zonesSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(listZones.fulfilled, (state, action) => {
      state.zonesPerFacility.set(
        action.payload.facilityId,
        action.payload.zones,
      );
    });
    builder.addCase(createZone.fulfilled, (state, action) => {
      const { facilityId, zone } = action.payload;
      const existingZones = state.zonesPerFacility.get(facilityId) ?? [];
      state.zonesPerFacility.set(facilityId, [...existingZones, zone]);
    });
    builder.addCase(updateZone.fulfilled, (state, action) => {
      const { facilityId, zone } = action.payload;
      const existingZones = state.zonesPerFacility.get(facilityId) ?? [];
      const updatedZones = existingZones.map((z) =>
        z.id === zone.id ? zone : z,
      );
      state.zonesPerFacility.set(facilityId, updatedZones);
    });
    builder.addCase(deactivateZone.fulfilled, (state, action) => {
      const { facilityId, id } = action.meta.arg;
      const existingZones = state.zonesPerFacility.get(facilityId) ?? [];
      const updatedZones = existingZones.map((z) =>
        z.id === id ? { ...z, inactive: true } : z,
      );
      state.zonesPerFacility.set(facilityId, updatedZones);
    });
    builder.addCase(reactivateZone.fulfilled, (state, action) => {
      const { facilityId, id } = action.meta.arg;
      const existingZones = state.zonesPerFacility.get(facilityId) ?? [];
      const updatedZones = existingZones.map((z) =>
        z.id === id ? { ...z, inactive: false } : z,
      );
      state.zonesPerFacility.set(facilityId, updatedZones);
    });
  },
  initialState,
  name: 'zones',
  reducers: {
    deleteZone: (state, action: PayloadAction<Summary>) => {
      state.orderedZones = state.orderedZones.filter(
        (zone) => zone.id !== action.payload.id,
      );
    },
    moveZone: (
      state,
      action: PayloadAction<{ fromIndex: number; toIndex: number }>,
    ) => {
      const { fromIndex, toIndex } = action.payload;
      const [removed] = state.orderedZones.splice(fromIndex, 1);
      state.orderedZones.splice(toIndex, 0, removed);
    },
    setZones: (state, action: PayloadAction<Summary[]>) => {
      state.orderedZones = action.payload;
    },
  },
  selectors: {
    orderedZones: (state) => state.orderedZones,
    zonesPerFacility: (state) => state.zonesPerFacility,
  },
});
