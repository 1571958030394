import { Group, ItemType, Question } from '@dakota/platform-client';
import Autocomplete from 'components/Autocomplete';
import { getRendererWithLibraryLogo } from 'components/Autocomplete/renderFunctions';
import Chips from 'components/Chip/Chips';
import { ClearAllButton } from 'components/ClearAll';
import SearchInput from 'components/SearchInput';
import { configSlice } from 'features/config/configSlice';
import { getAllItemGroups } from 'features/items/itemGroupsActions';
import { itemGroupsSlice } from 'features/items/itemGroupsSlice';
import { getAllItemTypes } from 'features/items/itemTypesActions';
import { itemTypesSlice } from 'features/items/itemTypesSlice';
import { getAllQuestions } from 'features/questions/questionsActions';
import { questionsSlice } from 'features/questions/questionsSlice';
import { tokenSlice } from 'features/token/tokenSlice';
import Fuse from 'fuse.js';
import { filterItemTypes } from 'Pages/Templates/helper';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { alphabeticalCompare } from 'utils/functional';
import { getHighlighter } from 'utils/highlighter';
import { compareQuestionProperties } from 'utils/questionComparator';

type Options = {
  /**
   * IDs of questions to exclude unconditionally from the results.
   * @default `[]`
   */
  exclude?: string[];
  /**
   * Classes to add to the item group filter.
   */
  itemGroupClasses?: string;
  /**
   * Classes to add to the item type filter.
   */
  itemTypeClasses?: string;
  /**
   * Classes to add to the status filter.
   */
  statusClasses?: string;
  /**
   * Whether to enable the status filter. When enabled,
   * only active questions are shown by default.
   * @default `false``
   */
  useStatusFilter?: boolean;
};

const defaultOptions: Options = {
  exclude: [],
  itemGroupClasses: '',
  itemTypeClasses: '',
  statusClasses: '',
  useStatusFilter: false,
};

/**
 * Since we have to search and filter questions in at least two places,
 * this hook is used to encapsulate the logic for searching and filtering
 * questions, providing the components ready to render.
 */
const useQuestionFilters = (opts = defaultOptions) => {
  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);

  const [hookDataLoaded, setHookDataLoaded] = useState(false);

  // Let's start with all active questions already sorted alphabetically
  const allQuestions = useSelector(questionsSlice.selectors.allQuestions);

  // Immediately filter out the unconditionally excluded questions
  const questionsMinorExclusions = useMemo(
    () => allQuestions.filter((q) => !opts.exclude?.includes(q.id)),
    [allQuestions, opts.exclude],
  );

  const itemGroups = useSelector(itemGroupsSlice.selectors.itemGroups);
  const isLoadingItemGroups = useSelector(
    itemGroupsSlice.selectors.isLoadingItemGroups,
  );

  const itemTypes = useSelector(itemTypesSlice.selectors.itemTypes);
  const isLoadingItemTypes = useSelector(
    itemTypesSlice.selectors.isLoadingItemTypes,
  );

  type Status = 'Active' | 'All' | 'Inactive';
  const statuses = ['All', 'Active', 'Inactive'] as const;
  const [selectedStatus, setSelectedStatus] = useState<Status>('Active');

  const [searchQuery, setSearchQuery] = useState('');
  const [selectableGroups, setSelectableGroups] = useState<Group[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<Group[]>([]);
  const [selectableItemTypes, setSelectableItemTypes] = useState<ItemType[]>(
    [],
  );
  const [selectedItemTypes, setSelectedItemTypes] = useState<ItemType[]>([]);

  // We *only* display the filtered questions
  const [filteredQuestions, setFilteredQuestions] = useState<Question[]>([]);

  useEffect(() => {
    void Promise.all([
      dispatch(getAllQuestions({ baseUrl, token })),
      dispatch(getAllItemGroups({ baseUrl, token })),
      dispatch(getAllItemTypes({ baseUrl, token })),
    ]).then(() => {
      setHookDataLoaded(true);
    });
  }, [baseUrl, dispatch, token]);

  // Update item types when the selection of groups change
  useEffect(() => {
    filterItemTypes(selectedGroups, setSelectableItemTypes, itemTypes);
  }, [itemGroups, itemTypes, selectedGroups]);

  useEffect(() => {
    let newQuestions = questionsMinorExclusions;

    if (opts.useStatusFilter) {
      if (selectedStatus === 'Active') {
        newQuestions = newQuestions.filter((q) => !q.inactive);
      } else if (selectedStatus === 'Inactive') {
        newQuestions = newQuestions.filter((q) => q.inactive);
      }
    }

    if (selectedGroups.length > 0) {
      newQuestions = newQuestions.filter((q) =>
        selectedGroups.find((group) => group.id === q.itemType.groupId),
      );
    }

    if (selectedItemTypes.length > 0) {
      newQuestions = newQuestions.filter((q) =>
        selectedItemTypes.find((itemType) => itemType.id === q.itemType.id),
      );
    }

    if (searchQuery) {
      const fuse = new Fuse(newQuestions, {
        findAllMatches: true,
        ignoreLocation: true,
        keys: [
          { name: 'citation', weight: 1 },
          { name: 'text', weight: 1 },
          { name: 'itemType.group', weight: 0.5 },
          { name: 'itemType.name', weight: 0.5 },
        ],
        shouldSort: true,
        threshold: 0.2,
        useExtendedSearch: true,
      });
      newQuestions = fuse.search(searchQuery).map((e) => e.item);
    }
    setFilteredQuestions(newQuestions.toSorted(compareQuestionProperties));
  }, [
    opts.useStatusFilter,
    questionsMinorExclusions,
    searchQuery,
    selectedGroups,
    selectedItemTypes,
    selectedStatus,
  ]);

  useEffect(() => {
    setSelectableGroups(
      itemGroups
        .filter((g) => !g.inactive)
        .toSorted(alphabeticalCompare((e) => e.name)),
    );
  }, [itemGroups]);

  useEffect(() => {
    setSelectableItemTypes(
      itemTypes
        .filter((t) => !t.inactive)
        .toSorted(alphabeticalCompare((e) => e.name)),
    );
  }, [itemTypes]);

  const removeGroup = (group: Group) => {
    setSelectedGroups((prev) => prev.filter((g) => g.id !== group.id));
    setSelectedItemTypes((prev) => prev.filter((i) => i.groupId !== group.id));
  };

  const removeItemType = (itemType: ItemType) => {
    setSelectedItemTypes((prev) => prev.filter((i) => i.id !== itemType.id));
  };

  const resetFilters = () => {
    setSearchQuery('');
    setSelectedGroups([]);
    setSelectedItemTypes([]);
    setSelectedStatus('Active');
  };

  const searchInput = (
    <SearchInput
      data-testid='search-input'
      id='question-text-search'
      onSearch={(query: string) => setSearchQuery(query.toLowerCase())}
      placeholder='Search questions'
      value={searchQuery}
    />
  );

  const itemGroupFilter = (
    <Autocomplete
      className={`max-sm:w-full ${opts.itemGroupClasses}`}
      getOptionKey={(itemGroup) => itemGroup.id}
      getOptionLabel={(option) => option.name}
      id='item-group-selector'
      label='Item Group'
      loading={isLoadingItemGroups}
      multiple
      onChange={setSelectedGroups}
      options={selectableGroups}
      renderOption={getRendererWithLibraryLogo((option) => option.name)}
      value={selectedGroups}
    />
  );

  const itemTypeFilter = (
    <Autocomplete
      className={`max-sm:w-full ${opts.itemTypeClasses}`}
      getOptionKey={(itemType) => itemType.id}
      getOptionLabel={(option) => option.name}
      id='item-type-selector'
      label='Item Type'
      loading={isLoadingItemTypes}
      multiple
      onChange={setSelectedItemTypes}
      options={selectableItemTypes}
      renderOption={getRendererWithLibraryLogo((option) => option.name)}
      value={selectedItemTypes}
    />
  );

  const statusFilter = (
    <Autocomplete
      className={opts.statusClasses}
      id='status-selector'
      label='Status'
      onChange={setSelectedStatus}
      options={statuses}
      value={selectedStatus}
    />
  );

  const clearAllButton = (selectedGroups.length > 0 ||
    selectedItemTypes.length > 0 ||
    !!searchQuery ||
    selectedStatus !== 'Active') && <ClearAllButton onClick={resetFilters} />;

  const itemGroupChips = (
    <Chips
      containerClassName='pt-2'
      elements={selectedGroups}
      getKey={(g) => g.id}
      getLabel={(g) => g.name}
      onRemove={removeGroup}
      title='Groups'
    />
  );

  const itemTypeChips = (
    <Chips
      elements={selectedItemTypes}
      getKey={(g) => g.id}
      getLabel={(g) => g.name}
      onRemove={removeItemType}
      title='Item Types'
    />
  );

  const highlight = getHighlighter(searchQuery);

  return {
    clearAllButton,
    filteredQuestions,
    highlight,
    hookDataLoaded,
    itemGroupChips,
    itemGroupFilter,
    itemTypeChips,
    itemTypeFilter,
    searchInput,
    statusFilter,
  };
};

export default useQuestionFilters;
