import resolveConfig from 'tailwindcss/resolveConfig';

import tailwindConfig from '../../../tailwind.config';

const { theme } = resolveConfig(tailwindConfig);

const avatarColors = [
  'bg-avatar-0',
  'bg-avatar-1',
  'bg-avatar-2',
  'bg-avatar-3',
  'bg-avatar-4',
  'bg-avatar-5',
  'bg-avatar-6',
] as const;

const colorHash = (userId: string) =>
  // Convert the last character from hex to decimal and get the modulo 7 to
  // determine the color. Since we have 7 colors for avatars, this will ensure
  // consistent color for a user, across refreshes and even if the user changes
  // their name.
  Number(`0x${userId.charAt(userId.length - 1)}`) % 7;

/** Get the user avatar color in the form of a Tailwind background class. */
export const getColorById = (assigneeId: string) => {
  return avatarColors[colorHash(assigneeId)];
};

/** Get the user avatar color in the form of the color's RGB value. */
export const getRgbColorById = (assigneeId: string) =>
  theme.colors.avatar[
    colorHash(assigneeId).toString() as keyof typeof theme.colors.avatar
  ];
