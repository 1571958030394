import { ClipboardDocumentCheckIcon } from '@heroicons/react/24/outline';
import { clsx } from 'clsx';
import { configSlice } from 'features/config/configSlice';
import { getTaskDetails } from 'features/tasks/tasksActions';
import { tasksSlice } from 'features/tasks/tasksSlice';
import { tokenSlice } from 'features/token/tokenSlice';
import { FC, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from 'store/store';

import { EditTask } from './EditTask';

type TaskLoaderProps = {
  /**
   * The source where the user is redirected after closing the dialog,
   * completing or canceling the task.
   *
   * If the value is `'email'` or `null`, the user is redirected to the
   * home page, which displays the dashboard.
   *
   * @default `null`
   */
  source?: null | string;
};

export const TaskLoader: FC<TaskLoaderProps> = ({ source = null }) => {
  const { date, seriesId } = useParams() as { date: string; seriesId: string };
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);

  const tasksDetails = useSelector(tasksSlice.selectors.tasksDetails);
  const task = tasksDetails.find(
    (t) => t.seriesId === seriesId && t.timeline.scheduledDate === date,
  );

  const navigateAway = useCallback(() => {
    navigate(source === null || source === 'email' ? '/' : source);
  }, [navigate, source]);

  useEffect(
    () => void dispatch(getTaskDetails({ baseUrl, date, seriesId, token })),
    [baseUrl, date, dispatch, seriesId, token],
  );

  const loadingAnimation = (text: string) => (
    <div
      className={clsx(
        'w-full h-full flex flex-col gap-2 justify-center items-center',
        'text-lg text-gray-500 animate-pulse',
      )}
    >
      <ClipboardDocumentCheckIcon className='w-24 h-24' />
      {text}
    </div>
  );

  if (!task) {
    return loadingAnimation('Loading task...');
  }

  return <EditTask onClose={navigateAway} task={task} />;
};
