import { TaskInstance, TaskState } from '@dakota/platform-client';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import Autocomplete from 'components/Autocomplete';
import { AssigneeAvatar } from 'components/Avatar';
import { PriorityIcon } from 'components/PriorityIcon';
import Table from 'components/Table';
import { ColumnProps } from 'components/Table/types';
import { useUsers } from 'hooks/useUsers';
import { FC } from 'react';
import { getHighlighter } from 'utils/highlighter';

import { Counters } from './Card/counters';
import { getStatusLabel } from './types';

interface TasksTableProps {
  data: TaskInstance[];
  loading: boolean;
  openTaskInstance: (task: TaskInstance) => void;
  /**
   * If provided, it will highlight the search query in a few columns.
   * @default `undefined`
   */
  searchQuery?: string;
  setTaskInstanceStatus: (task: TaskInstance, status: TaskState) => void;
  /**
   * If `true`, show the Completed Date instead of Due Date.
   * @default `false`
   */
  showCompletedDate?: boolean;
}

const TasksTable: FC<TasksTableProps> = ({
  data,
  loading,
  openTaskInstance,
  searchQuery = undefined,
  setTaskInstanceStatus,
  showCompletedDate = false,
}) => {
  const highlight = getHighlighter(searchQuery ?? '');
  const { getUserSummary } = useUsers();

  const columns: ReadonlyArray<ColumnProps<TaskInstance>> = [
    {
      cellDataTestId: 'task-title',
      key: 'name',
      render: (task) => (
        <div className='flex flex-col'>
          <button
            className='text-start text-green-base hover:text-green-darker'
            onClick={() => openTaskInstance(task)}
          >
            {highlight(task.title)}
          </button>
          {task.overdue && (
            <div className='flex items-center gap-1'>
              <ExclamationCircleIcon className='w-5 h-5 text-red-base gap-1' />
              <span className='text-sm text-red-base font-semibold'>
                Overdue
              </span>
            </div>
          )}
        </div>
      ),
      title: 'Task Name',
    },
    showCompletedDate
      ? {
          cellDataTestId: 'completedDate',
          key: 'completedDate',
          render: (task) => task.timeline.endDate,
          title: 'Completed Date',
        }
      : {
          cellDataTestId: 'dueDate',
          key: 'dueDate',
          render: (task) => task.timeline.scheduledDate,
          title: 'Due Date',
        },
    {
      cellDataTestId: 'facility',
      key: 'facility',
      render: (task) => highlight(task.facility.name),
      title: 'Facility',
    },
    {
      cellDataTestId: 'zone',
      key: 'zone',
      render: (task) => highlight(task.zone?.name),
      title: 'Zone',
    },
    {
      cellDataTestId: 'priority',
      key: 'priority',
      render: (task) => <PriorityIcon priority={task.priority} />,
    },
    {
      cellDataTestId: 'assignee',
      key: 'assignee',
      render: (task) => {
        return <AssigneeAvatar user={getUserSummary(task.assigneeId)} />;
      },
    },
    {
      cellDataTestId: 'status',
      key: 'status',
      render: (task) => (
        <Autocomplete
          className='w-32'
          getOptionLabel={getStatusLabel}
          multiple={false}
          onChange={(status: TaskState) => setTaskInstanceStatus(task, status)}
          options={[
            TaskState.Canceled,
            TaskState.Completed,
            TaskState.InProgress,
            TaskState.Scheduled,
          ]}
          testId={`task-table-status-${task.id}`}
          value={task.status}
        />
      ),
      title: 'Status',
    },
    {
      cellDataTestId: 'actions',
      key: 'actions',
      render: (task) => (
        <Counters onClick={() => openTaskInstance(task)} task={task} />
      ),
    },
  ];

  return (
    <Table
      {...{ columns, data, loading }}
      footer={`${data.length} results`}
      id='tasks-table'
    />
  );
};

export default TasksTable;
