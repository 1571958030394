import { clsx } from 'clsx';
import { Spinner } from 'components/Spinner';
import { ReactElement } from 'react';

import { KeyedObject, TableProps } from './types';

export const DesktopTable = <T extends KeyedObject>({
  columns,
  data,
  footer = undefined,
  id = undefined,
  loading = false,
}: TableProps<T>) => {
  let rows: ReactElement | ReactElement[];
  if (loading) {
    rows = (
      <tr>
        <td colSpan={columns.length}>
          <div className='h-24 flex items-center justify-center'>
            <Spinner />
          </div>
        </td>
      </tr>
    );
  } else if (!data.length) {
    rows = (
      <tr>
        <td
          className='text-center'
          colSpan={columns.length}
          data-testid='no-data-cell'
        >
          No data
        </td>
      </tr>
    );
  } else {
    rows = data.map((row) => {
      return (
        <tr className='hover:bg-gray-50' key={row.id}>
          {columns.map((column) => {
            return (
              <td
                className='p-2 sm:p-4'
                data-testid={column.cellDataTestId}
                key={column.key}
              >
                {column.render(row)}
              </td>
            );
          })}
        </tr>
      );
    });
  }

  return (
    <table
      className='max-sm:hidden w-full table-auto text-gray-700 shadow rounded-lg'
      data-testid={id}
      id={id}
    >
      <thead className='font-medium text-xs text-left uppercase'>
        <tr>
          {columns.map((column) => {
            return (
              <th
                className={clsx(
                  'first-of-type:rounded-tl-lg last-of-type:rounded-tr-lg z-10',
                  'px-2 py-4 sm:p-4 bg-gray-100 text-gray-500 sticky top-0',
                )}
                key={column.key}
              >
                {column.title}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody
        aria-label='Table content'
        className='text-sm divide-y divide-solid'
      >
        {rows}
      </tbody>
      {footer && (
        <tfoot>
          <tr>
            <td
              className='rounded-b-lg bg-gray-100 p-2 text-sm sticky bottom-0'
              colSpan={columns.length}
            >
              {footer}
            </td>
          </tr>
        </tfoot>
      )}
    </table>
  );
};
