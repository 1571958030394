/* eslint-disable perfectionist/sort-objects */
import { convert, LocalDate } from '@js-joda/core';
import { ShortcutsItem } from 'react-tailwindcss-datepicker';

const today = LocalDate.now();

export const shortcutsInThePast: { [key: string]: ShortcutsItem } = {
  today: {
    period: {
      end: convert(today).toDate(),
      start: convert(today).toDate(),
    },
    text: 'Today',
  },
  last7Days: {
    period: {
      end: convert(today).toDate(),
      start: convert(today.minusDays(7)).toDate(),
    },
    text: 'Last 7 days',
  },
  last30Days: {
    period: {
      end: convert(today).toDate(),
      start: convert(today.minusDays(30)).toDate(),
    },
    text: 'Last 30 days',
  },
  last60Days: {
    period: {
      end: convert(today).toDate(),
      start: convert(today.minusDays(60)).toDate(),
    },
    text: 'Last 60 days',
  },
  last365Days: {
    period: {
      end: convert(today).toDate(),
      start: convert(today.minusDays(365)).toDate(),
    },
    text: 'Last 365 days',
  },
};

export const shortcutsInTheFuture: { [key: string]: ShortcutsItem } = {
  today: {
    period: {
      end: convert(today).toDate(),
      start: convert(today).toDate(),
    },
    text: 'Today',
  },
  next7Days: {
    period: {
      end: convert(today.plusDays(7)).toDate(),
      start: convert(today).toDate(),
    },
    text: 'Next 7 days',
  },
  next30Days: {
    period: {
      end: convert(today.plusDays(30)).toDate(),
      start: convert(today).toDate(),
    },
    text: 'Next 30 days',
  },
  next60Days: {
    period: {
      end: convert(today.plusDays(60)).toDate(),
      start: convert(today).toDate(),
    },
    text: 'Next 60 days',
  },
  next365Days: {
    period: {
      end: convert(today.plusDays(365)).toDate(),
      start: convert(today).toDate(),
    },
    text: 'Next 365 days',
  },
};
