import { PromptComment, TaskComment } from '@dakota/platform-client';
import { formatBackendDateTime } from 'utils/date';

import NewNote from './NewNote';

type NoteProps = {
  canEdit?: boolean;
  id?: string;
  loading?: boolean;
  maxNoteLength?: number;
  note: PromptComment | TaskComment;
  onChange: (note: string) => void;
  removeNote: () => void;
};

const Note = ({
  canEdit,
  id,
  loading,
  maxNoteLength,
  note,
  onChange,
  removeNote,
}: NoteProps) => {
  return (
    <div
      className={
        'border border-gray-300 rounded-md bg-gray-50 text-gray-700 p-2'
      }
      data-testid={`${id}-container`}
      id={`${id}-container`}
    >
      <div className='flex justify-between items-center'>
        <NewNote
          canEdit={canEdit}
          deletable
          id={id}
          initialEditableValue={note.text}
          loading={loading}
          maxNoteLength={maxNoteLength}
          note={note.text}
          onChange={onChange}
          removeNote={removeNote}
        />
      </div>

      <div className='text-xs pl-2 text-gray-500'>
        {note.userName} &mdash; {formatBackendDateTime(note.commented)}
      </div>
    </div>
  );
};

export default Note;
