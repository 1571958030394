import { Inspection, InspectionStatus } from '@dakota/platform-client';
import { CalendarDaysIcon } from '@heroicons/react/24/solid';
import { LocalDate } from '@js-joda/core';
import { Dialog, Tooltip } from '@mui/material';
import { clsx } from 'clsx';
import Button from 'components/Button';
import { DatePicker } from 'components/DatePicker';
import { configSlice } from 'features/config/configSlice';
import { updateInspection } from 'features/inspections/inspectionActions';
import { tokenSlice } from 'features/token/tokenSlice';
import { userSlice } from 'features/user/userSlice';
import { useCheckPermission } from 'hooks/useCheckPermission';
import useToast from 'hooks/useToast';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { formatBackendDate } from 'utils/date';
import { Permission } from 'utils/permissions';

type Props = {
  inspection: Inspection;
};

export const Reschedule: FC<Props> = ({ inspection }) => {
  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);
  const currentUser = useSelector(userSlice.selectors.currentUser);

  const canUpdateInspection = useCheckPermission(Permission.UpdateInspection);
  const isAdmin = useCheckPermission(Permission.Admin);

  const { setErrorMessage, setSuccessMessage } = useToast();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [date, setDate] = useState<LocalDate>(LocalDate.now());

  let isDisabled =
    inspection.status === InspectionStatus.InProgress ||
    inspection.status === InspectionStatus.Completed ||
    inspection.status === InspectionStatus.Canceled;

  // For Overdue or Scheduled inspections,
  // allow if the user is an admin or has update permissions
  if (!isDisabled) {
    isDisabled = !(isAdmin || canUpdateInspection);
  }

  const save = () => {
    setIsUpdating(true);

    const updateData = {
      baseUrl,
      currentUserId: currentUser.id,
      id: inspection.id,
      priority: inspection.priority,
      scheduledDate: date.toString(),
      token,
      userId: inspection.userId,
      zones: inspection.zones.map((z) => z.id),
    };
    dispatch(updateInspection(updateData))
      .unwrap()
      .then(() => {
        setSuccessMessage('Inspection rescheduled successfully');
        setIsDialogOpen(false);
      })
      .catch(() => {
        setErrorMessage('Failed to rescheduled inspection');
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  const canSave =
    !isUpdating &&
    date.toString() !==
      inspection.timeline.scheduledDate.toString().slice(0, 10);

  return (
    <>
      <button
        aria-label='Reschedule Inspection'
        className={clsx(
          'flex items-center gap-3 cursor-pointer text-gray-700',
          'disabled:cursor-not-allowed disabled:text-gray-300',
        )}
        data-testid='reschedule-inspection-button'
        disabled={isDisabled}
        onClick={() => setIsDialogOpen(true)}
      >
        <Tooltip arrow placement='top' title='Reschedule Inspection'>
          <CalendarDaysIcon className='w-4' />
        </Tooltip>
        Reschedule
      </button>
      {isDialogOpen && (
        <Dialog onClose={() => setIsDialogOpen(false)} open>
          <div
            className={clsx(
              'p-4 sm:w-96 text-gray-700',
              'flex flex-col justify-between',
            )}
          >
            <div>
              <div className='text-lg font-medium'>Reschedule Inspection</div>
              <div className='text-sm text-gray-500 mt-4 mb-6'>
                Select a new due date for {}
                <span className='font-medium'>{inspection.form.name}</span>
                {', '}
                currently scheduled for{' '}
                <span className='font-medium'>
                  {formatBackendDate(inspection.timeline.scheduledDate)}
                  {'.'}
                </span>
              </div>
              <DatePicker
                asSingle={true}
                id='inspection-date'
                onChange={setDate}
                placeholder='Select a date'
                popoverDirection='down'
                value={date}
              />
            </div>
            <div className='flex'>
              <Button
                className='mt-6'
                disabled={isUpdating || !canSave}
                loading={isUpdating}
                onClick={save}
              >
                {isUpdating ? 'Saving...' : 'Save'}
              </Button>
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
};
