import {
  CreateInspectionRequest,
  FormSummary,
  Inspection,
  Priority,
  Summary,
} from '@dakota/platform-client';
import { LocalDate } from '@js-joda/core';
import { unwrapResult } from '@reduxjs/toolkit';
import { ClientData } from 'features/clientProvider';
import { configSlice } from 'features/config/configSlice';
import { createInspection } from 'features/inspections/inspectionActions';
import { tokenSlice } from 'features/token/tokenSlice';
import { userSlice } from 'features/user/userSlice';
import { zonesSlice } from 'features/zones/zonesSlice';
import useToast from 'hooks/useToast';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { unassignedUser } from 'utils/user';

import { useSelectedFacilityUser } from './useSelectedFacilityUser';

export interface TemplateWorkflowProps {
  form: FormSummary;
  scheduledAssigneeId?: string;
  scheduledInspectionDate?: LocalDate;
}

export const useTemplateWorkflow = ({
  form,
  scheduledAssigneeId,
  scheduledInspectionDate,
}: TemplateWorkflowProps) => {
  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);
  const currentUser = useSelector(userSlice.selectors.currentUser);

  const { setErrorMessage } = useToast();

  const zonesPerInspection = useSelector(zonesSlice.selectors.orderedZones);

  const inspectionFacilityId = form.facility?.id;

  const [facility, setFacility] = useState<null | Summary>(
    form.facility ?? null,
  );

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [scheduling, setScheduling] = useState(false);
  const [scheduledInspection, setScheduledInspection] = useState<
    Inspection | undefined
  >(undefined);
  const [isAssigneeInitialized, setIsAssigneeInitialized] = useState(false);
  const [priority, setPriority] = useState(Priority.Medium);

  const { assignee, date, selectableUsers, setAssignee, setDate } =
    useSelectedFacilityUser(facility?.id);

  const selectableFacilities = useSelector(
    userSlice.selectors.accessibleFacilities,
  );

  useEffect(() => {
    if (inspectionFacilityId) {
      const targetFacility = selectableFacilities.find(
        (f) => f.id === inspectionFacilityId,
      );
      setFacility(targetFacility || null);
    }
  }, [inspectionFacilityId, selectableFacilities]);

  useEffect(() => {
    if (scheduledInspectionDate) {
      setDate(scheduledInspectionDate);
    }
  }, [scheduledInspectionDate, setDate]);

  useEffect(() => {
    if (isDialogOpen && !isAssigneeInitialized && inspectionFacilityId) {
      // Initialize assignee dropdown when dialog opens for the first time
      const targetUser = selectableUsers.find(
        (user) => user.id === scheduledAssigneeId,
      );
      setAssignee(targetUser || unassignedUser);
      setIsAssigneeInitialized(true);
    }
    if (!isDialogOpen) {
      setIsAssigneeInitialized(false);
    }
  }, [
    isDialogOpen,
    inspectionFacilityId,
    scheduledAssigneeId,
    selectableUsers,
    isAssigneeInitialized,
    setAssignee,
  ]);

  const scheduleInspection = async () => {
    if (!facility) {
      return;
    }
    setScheduling(true);
    try {
      const data = {
        baseUrl,
        currentUserId: currentUser.id,
        facilityId: facility.id,
        formId: form.id,
        priority,
        scheduledDate: date.toString(),
        token,
        userId: assignee.id !== unassignedUser.id ? assignee.id : undefined,
        zones: zonesPerInspection.map((zone) => zone.id),
      } as { currentUserId: string } & ClientData & CreateInspectionRequest;
      const result = await dispatch(createInspection(data)).then(unwrapResult);
      setScheduledInspection(result);
      // Only reset facility for global templates
      if (!form.facility) {
        setFacility(null);
      }
      setAssignee(unassignedUser);
      setPriority(Priority.Medium);
      closeDialog();
      // eslint-disable-next-line sonarjs/no-ignored-exceptions
    } catch (_) {
      setErrorMessage('Failed to schedule inspection.');
    } finally {
      setScheduling(false);
    }
  };

  const closeDialog = useCallback(() => {
    setIsDialogOpen(false);
    setDate(LocalDate.now());
  }, [setDate]);

  return {
    assignee,
    closeDialog,
    date,
    facility,
    isDialogOpen,
    priority,
    scheduledInspection,
    scheduleInspection,
    scheduling,
    selectableUsers,
    setAssignee,
    setDate,
    setFacility,
    setIsDialogOpen,
    setPriority,
    setScheduledInspection,
    setScheduling,
  };
};
