import {
  InspectionsClient,
  InspectionStatus,
  SearchStatus,
  TasksClient,
} from '@dakota/platform-client';
import { LocalDate } from '@js-joda/core';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ClientData, getClient } from 'features/clientProvider';

/**
 * Fetch the inspections assigned to the given user
 * that are in 'Scheduled' or 'Overdue' status.
 */
export const getScheduledInspections = createAsyncThunk(
  'notifications/scheduledInspections',
  async (params: { userId: string } & ClientData) => {
    const client = getClient(InspectionsClient, params);
    const response = await client.listInspections({
      scheduleDate_end: LocalDate.now().toString(),
      status: [InspectionStatus.Scheduled, InspectionStatus.Overdue],
      userId: params.userId,
    });
    return response.result;
  },
);

/**
 * Fetch the tasks assigned to the given user
 * that are in 'Scheduled' or 'Overdue' status.
 */
export const getScheduledTasks = createAsyncThunk(
  'notifications/scheduledTasks',
  async (params: { userId: string } & ClientData) => {
    const client = getClient(TasksClient, params);
    const response = await client.listTasks({
      dateRange_endDate: LocalDate.now().toString(),
      dateRange_startDate: LocalDate.now().toString(),
      status: [SearchStatus.Scheduled, SearchStatus.Overdue],
    });
    return response.result.filter((task) => task.assigneeId === params.userId);
  },
);
