import { TaskInstance, TaskInstanceDetails } from '@dakota/platform-client';
import { createSlice } from '@reduxjs/toolkit';

import {
  getTaskDetails,
  listTasks,
  updateAttachment,
  updateTaskInstance,
} from './tasksActions';

export type TasksState = {
  /**
   * True when fetching tasks from the `listTasks` API.
   */
  isLoadingTasks: boolean;
  /**
   * The list of tasks as returned by the `listTasks` API.
   */
  tasks: TaskInstance[];
  /**
   * The details of the tasks as returned by the `getTaskDetails` API.
   */
  tasksDetails: TaskInstanceDetails[];
};

const initialState: TasksState = {
  isLoadingTasks: false,
  tasks: [],
  tasksDetails: [],
};

export const tasksSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(listTasks.fulfilled, (state, action) => {
      state.isLoadingTasks = false;
      state.tasks = action.payload;
    });
    builder.addCase(listTasks.pending, (state) => {
      state.isLoadingTasks = true;
    });
    builder.addCase(listTasks.rejected, (state) => {
      state.isLoadingTasks = false;
    });
    builder.addCase(updateTaskInstance.fulfilled, (state, action) => {
      const updatedTask = action.payload;
      /**
       * We need to update the original state when the task is updated.
       */
      state.tasks = state.tasks.map((task) =>
        task.id === updatedTask.id ? updatedTask : task,
      );
      state.tasksDetails = state.tasksDetails.map((task) =>
        task.id === updatedTask.id ? updatedTask : task,
      );
    });
    builder.addCase(getTaskDetails.fulfilled, (state, action) => {
      const task = action.payload;
      if (state.tasksDetails.some((t) => t.id === task.id)) {
        state.tasksDetails = state.tasksDetails.map((t) =>
          t.id === task.id ? task : t,
        );
      } else {
        state.tasksDetails = [...state.tasksDetails, task];
      }
    });
    builder.addCase(updateAttachment.fulfilled, (state, action) => {
      const { attachmentId, taskId } = action.meta.arg;
      state.tasksDetails = state.tasksDetails.map((task) =>
        task.id === taskId
          ? {
              ...task,
              media: task.media.map((media) =>
                media.id === attachmentId ? action.payload : media,
              ),
            }
          : task,
      );
    });
  },
  initialState,
  name: 'tasks',
  reducers: {},
  selectors: {
    isLoadingTasks: (state: TasksState) => state.isLoadingTasks,
    tasks: (state: TasksState) => state.tasks,
    tasksDetails: (state) => state.tasksDetails,
  },
});
