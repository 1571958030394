import { UserSummary, Zone } from '@dakota/platform-client';
import { TrashIcon } from '@heroicons/react/24/outline';
import Autocomplete from 'components/Autocomplete';
import EditableText from 'components/EditableText';
import Input from 'components/Input';
import Toggle from 'components/Toggle';
import { configSlice } from 'features/config/configSlice';
import { tokenSlice } from 'features/token/tokenSlice';
import { deactivateZone, reactivateZone } from 'features/zones/zonesActions';
import useToast from 'hooks/useToast';
import { useUsers } from 'hooks/useUsers';
import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';

interface ZoneProps {
  facilityId: string;
  isAdding?: boolean;
  onChange: (data: { contactId?: string; name: string }) => void;
  onRemove?: () => void;
  zone: Zone;
}

const ZoneDetails: FC<ZoneProps> = ({
  facilityId,
  isAdding = false,
  onChange,
  onRemove,
  zone,
}) => {
  const dispatch = useAppDispatch();
  const { setErrorMessage, setSuccessMessage } = useToast();

  const token = useSelector(tokenSlice.selectors.token);
  const baseUrl = useSelector(configSlice.selectors.backend);

  const [name, setName] = useState(zone.name);
  const [contactId, setContactId] = useState<string | undefined>(
    zone.contact?.id,
  );

  const { activeUsers, getActiveUserSummary } = useUsers();

  const handleContactChange = (user: null | UserSummary) => {
    setContactId(user?.id);
    onChange({ contactId: user?.id, name });
  };

  const selectedUser = getActiveUserSummary(contactId) ?? null;

  const handleNameEdit = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
    onChange({ contactId, name: event.target.value });
  };

  const handleActiveToggle = (activate: boolean) => {
    const action = activate
      ? reactivateZone({ baseUrl, facilityId, id: zone.id, token })
      : deactivateZone({ baseUrl, facilityId, id: zone.id, token });

    dispatch(action)
      .unwrap()
      .then(() => {
        const successMessage = activate
          ? 'Zone activated successfully'
          : 'Zone deactivated successfully';
        setSuccessMessage(successMessage);
      })
      .catch(() => {
        const errorMessage = activate
          ? 'Failed to activate zone'
          : 'Failed to deactivate zone';
        setErrorMessage(errorMessage);
      });
  };

  return (
    <div className='flex flex-col bg-gray-100 p-2 rounded-lg shadow border gap-2'>
      <div className='flex items-center justify-between gap-2'>
        <div className='flex-1'>
          {!isAdding ? (
            <EditableText
              containerClasses='w-full'
              id='zone-name'
              onEdit={(newText) =>
                handleNameEdit({
                  target: { value: newText },
                } as React.ChangeEvent<HTMLInputElement>)
              }
              required
              saveOnEnter
              showIcon={false}
              styleAfterSave
              text={name || 'Zone Name'}
            />
          ) : (
            <Input
              className='w-full'
              id='zone-name-input'
              onChange={handleNameEdit}
              placeholder='Zone Name'
              value={name}
            />
          )}
        </div>
        <div className='flex-none'>
          {isAdding ? (
            <button
              className=' text-red-base hover:text-red-dark'
              data-testid='remove-zone-button'
              onClick={onRemove}
              title='Remove Zone'
            >
              <TrashIcon className='w-5 h-5' />
            </button>
          ) : (
            <Toggle
              id='zone-active-toggle'
              isOn={!zone.inactive}
              label='Active'
              onToggle={handleActiveToggle}
              showIcon={true}
            />
          )}
        </div>
      </div>
      <div className='bg-white rounded-md'>
        <Autocomplete
          clearable
          getOptionKey={(option) => option.id}
          getOptionLabel={(option) => option.name}
          id='zone-contact'
          onChange={handleContactChange}
          options={activeUsers}
          placeholder='Select Contact'
          value={selectedUser}
        />
      </div>
    </div>
  );
};

export default ZoneDetails;
