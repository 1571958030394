import { UserDetails } from '@dakota/platform-client';
import {
  LockClosedIcon,
  PaperAirplaneIcon,
  PencilIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import PermissionGuard from 'auth/PermissionGuard';
import { clsx } from 'clsx';
import AcknowledgmentDialog from 'components/Dialog/AcknowledgmentDialog';
import { DropdownMenu } from 'components/DropdownMenu';
import { Spinner } from 'components/Spinner';
import Toggle from 'components/Toggle';
import { configSlice } from 'features/config/configSlice';
import { tokenSlice } from 'features/token/tokenSlice';
import {
  activateUser,
  deactivateUser,
  deleteUser,
  resendInvitation,
  resetPassword,
} from 'features/user/userActions';
import { userSlice } from 'features/user/userSlice';
import useToast from 'hooks/useToast';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { Permission } from 'utils/permissions';

import AddEditUser from './addUser';

export type UserMenuProps = {
  user: UserDetails;
};

const UserMenu = ({ user }: UserMenuProps) => {
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);
  const dispatch = useAppDispatch();

  const { setErrorMessage, setSuccessMessage } = useToast();

  const isResendingInvitation = useSelector(
    userSlice.selectors.isResendingInvitation,
  );
  const isResettingPassword = useSelector(
    userSlice.selectors.isResettingPassword,
  );

  const [active, setActive] = useState(!user.inactive);
  const [processingActivation, setProcessingActivation] = useState(false);
  const [processingDeletion, setProcessingDeletion] = useState(false);
  const [confirmingDeletion, setConfirmingDeletion] = useState(false);
  const [isEditPanelOpen, setIsEditPanelOpen] = useState(false);

  const toggleActive = (event: boolean) => {
    const clientData = { baseUrl, token };
    setProcessingActivation(true);
    if (event) {
      dispatch(activateUser({ ...clientData, user }))
        .unwrap()
        .then(() => {
          setActive(true);
          setSuccessMessage('User activated');
        })
        .catch(() => {
          setErrorMessage('Failed to activate user');
        })
        .finally(() => {
          setProcessingActivation(false);
        });
    } else {
      dispatch(deactivateUser({ ...clientData, user }))
        .unwrap()
        .then(() => {
          setActive(false);
          setSuccessMessage('User deactivated');
        })
        .catch(() => {
          setErrorMessage('Failed to deactivate user');
        })
        .finally(() => {
          setProcessingActivation(false);
        });
    }
  };

  const handleDeleteUser = () => {
    setProcessingDeletion(true);
    const clientData = { baseUrl, token };
    dispatch(deleteUser({ ...clientData, user }))
      .unwrap()
      .then(() => {
        setSuccessMessage('User deleted');
      })
      .catch(() => {
        setErrorMessage('Failed to delete user');
      })
      .finally(() => {
        setProcessingDeletion(false);
        setConfirmingDeletion(false);
      });
  };

  const resendUserInvitation = () => {
    const clientData = { baseUrl, token };
    dispatch(resendInvitation({ ...clientData, id: user.id }))
      .unwrap()
      .then(() => setSuccessMessage('Invitation email resent'))
      .catch(() => setErrorMessage('Failed to resend invitation email'));
  };

  const resetUserPassword = () => {
    const clientData = { baseUrl, token };
    dispatch(resetPassword({ ...clientData, id: user.id }))
      .unwrap()
      .then(() => setSuccessMessage('Password reset'))
      .catch(() => setErrorMessage('Failed to reset password'));
  };

  const buttonClasses =
    'flex items-center gap-2 cursor-pointer disabled:text-gray-400';

  const spinner = (msg: string) => (
    <>
      <Spinner /> {msg}
    </>
  );

  return (
    <div data-testid={`user-menu-${user.id}`}>
      <DropdownMenu
        buttonAriaLabel={`Menu for ${user.displayName}`}
        buttonTestId='user-menu-button'
        contentTestId='user-contextual-menu'
      >
        <div
          className={clsx(
            'bg-white w-44 flex flex-col p-4 gap-3',
            'rounded-md shadow-md text-sm font-medium text-gray-800',
          )}
        >
          <PermissionGuard permissions={[Permission.UpdateUser]}>
            <button
              className={buttonClasses}
              disabled={isResendingInvitation}
              onClick={resendUserInvitation}
            >
              {isResendingInvitation ? (
                spinner('Sending...')
              ) : (
                <>
                  <PaperAirplaneIcon className='w-4' />
                  Resend Invite
                </>
              )}
            </button>
            <button
              className={buttonClasses}
              disabled={isResettingPassword}
              onClick={resetUserPassword}
            >
              {isResettingPassword ? (
                spinner('Resetting...')
              ) : (
                <>
                  <LockClosedIcon className='w-4' />
                  Reset Password
                </>
              )}
            </button>
          </PermissionGuard>
          <button
            className={buttonClasses}
            data-testid='edit-user-button'
            onClick={() => setIsEditPanelOpen(true)}
          >
            <PencilIcon className='w-4' />
            Edit
          </button>
          <PermissionGuard
            permissions={[Permission.DeactivateUser, Permission.Admin]}
          >
            <button
              className={buttonClasses}
              data-testid='delete-user-button'
              disabled={processingDeletion}
              onClick={() => setConfirmingDeletion(true)}
            >
              <TrashIcon className='w-4 text-red-base' />
              Delete
            </button>
          </PermissionGuard>
          <Toggle
            data-testid='toggle-user'
            disabled={processingActivation}
            id='toggle-user'
            isOn={active}
            label='Active'
            onToggle={(e) => toggleActive(e)}
            showIcon
          />
        </div>
      </DropdownMenu>
      {isEditPanelOpen && (
        <AddEditUser
          isOpen={isEditPanelOpen}
          onClose={() => setIsEditPanelOpen(false)}
          user={user}
        />
      )}
      {confirmingDeletion && (
        <AcknowledgmentDialog
          acknowledgmentDisabled={processingDeletion}
          acknowledgmentText={
            'Deleting this user will remove them from the system. ' +
            'All scheduled and in progress inspections for this user will be canceled. ' +
            'Completed inspections for this user will still be accessible. Are you sure you want to delete this user?'
          }
          buttonText='Yes'
          cancelButtonText='No'
          onAcknowledgment={handleDeleteUser}
          onCloseDialog={() => setConfirmingDeletion(false)}
          title={`Delete '${user.displayName}'?`}
        />
      )}
    </div>
  );
};

export default UserMenu;
