import { Summary } from '@dakota/platform-client';
import { Dialog, Tooltip } from '@mui/material';
import { clsx } from 'clsx';
import { FC, useState } from 'react';

type ZoneCellProps = {
  name: string;
  zones: Summary[];
};

export const ZoneCell: FC<ZoneCellProps> = ({ name, zones }) => {
  const [zonesDialogOpen, setZonesDialogOpen] = useState(false);

  const zoneText = zones.length > 1 ? `${zones.length} zones` : '1 zone';

  const openZonesDialog = () => {
    setZonesDialogOpen(true);
  };

  const closeZonesDialog = () => {
    setZonesDialogOpen(false);
  };

  return (
    <>
      {zones.length > 0 ? (
        <button
          className={clsx(
            'text-green-dark bg-green-lighter rounded-md',
            'text-xs p-2 hover:bg-green-light cursor-pointer',
          )}
          onClick={openZonesDialog}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              openZonesDialog();
            }
          }}
        >
          {zoneText}
        </button>
      ) : (
        <Tooltip arrow enterTouchDelay={0} title={`No zones on ${name}`}>
          <div
            className={clsx(
              'inline-block text-xs rounded-md p-2',
              'bg-gray-100 text-gray-500 hover:cursor-help',
            )}
          >
            General
          </div>
        </Tooltip>
      )}
      {zonesDialogOpen && (
        <Dialog fullWidth maxWidth='xs' onClose={closeZonesDialog} open>
          <div className='flex flex-col gap-3 p-6 text-gray-500'>
            <div className='text-xl font-semibold text-gray-900'>
              Zones for {name}
            </div>
            <ol className='list-decimal list-inside' data-testid='zone-list'>
              {zones.map((zone) => (
                <li key={zone.id}>{zone.name}</li>
              ))}
            </ol>
          </div>
        </Dialog>
      )}
    </>
  );
};
