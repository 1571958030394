import { TrashIcon } from '@heroicons/react/24/outline';
import { IconButton } from '@mui/material';
import EditableText from 'components/EditableText';

type deletableProps =
  /**
   * if deletable is true, removeNote is required and a trash icon will be
   * displayed
   */
  | {
      deletable: true;
      removeNote: () => void;
    }
  /**
   * if deletable is false, removeNote is not allowed and trash icon is not
   * displayed
   */
  | {
      deletable?: false;
      removeNote?: never;
    };

type NoteProps = {
  canEdit?: boolean;
  editOnMount?: boolean;
  id?: string;
  initialEditableValue?: string;
  loading?: boolean;
  maxNoteLength?: number;
  note?: string;
  onChange: (note: string) => void;
} & deletableProps;

const NewNote = ({
  canEdit = true,
  deletable,
  editOnMount,
  id,
  initialEditableValue = '',
  loading,
  maxNoteLength,
  note,
  onChange,
  removeNote,
}: NoteProps) => {
  return (
    <div className='w-full'>
      <div className='flex justify-between items-center'>
        <EditableText
          containerClasses='w-full'
          disabled={!canEdit}
          editOnMount={editOnMount}
          id={id}
          initialEditableValue={initialEditableValue}
          loading={loading}
          maxLength={maxNoteLength}
          onEdit={onChange}
          saveOnEnter
          styleAfterSave
          text={note ?? 'Add a note'}
        />
        {deletable && canEdit && (
          <IconButton
            aria-label='Delete note'
            disabled={!canEdit}
            onClick={removeNote}
          >
            <TrashIcon className='text-red-base w-5' />
          </IconButton>
        )}
      </div>
    </div>
  );
};

export default NewNote;
