import { Bars4Icon, TableCellsIcon } from '@heroicons/react/24/outline';
import { clsx } from 'clsx';
import Tooltip from 'components/Tooltip';
import React from 'react';

type ViewToggleProps = {
  listView: boolean;
  setListView: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ViewToggle: React.FC<ViewToggleProps> = ({
  listView,
  setListView,
}) => {
  const switchView = () => {
    setListView((prev) => !prev);
  };

  const buttonClasses = clsx(
    'w-5 h-5 text-gray-300',
    'hover:text-gray-700 disabled:text-gray-700',
  );

  return (
    <div className='hidden sm:inline-flex items-center gap-2'>
      <div className='flex gap-2'>
        <button
          aria-label='Switch to list view'
          className={buttonClasses}
          data-testid='list-view-button'
          disabled={listView}
          onClick={switchView}
        >
          <Tooltip arrow disabled={listView} title='Switch to list view'>
            <Bars4Icon />
          </Tooltip>
        </button>
        <button
          aria-label='Switch to card view'
          className={buttonClasses}
          disabled={!listView}
          onClick={switchView}
        >
          <Tooltip arrow disabled={!listView} title='Switch to card view'>
            <TableCellsIcon />
          </Tooltip>
        </button>
      </div>
    </div>
  );
};
