import {
  InspectionDetails,
  PromptQuestionDetails,
} from '@dakota/platform-client';
import { ChatBubbleLeftIcon } from '@heroicons/react/24/outline';
import { clsx } from 'clsx';
import Button from 'components/Button';
import SidePanel from 'components/SidePanel';
import { configSlice } from 'features/config/configSlice';
import { addNote } from 'features/inspections/inspectionActions';
import { inspectionSlice } from 'features/inspections/inspectionSlice';
import { tokenSlice } from 'features/token/tokenSlice';
import useToast from 'hooks/useToast';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import Textarea from 'react-textarea-autosize';
import { useAppDispatch } from 'store/store';

import { QuestionSummary } from './QuestionSummary';

type AddNoteProps = {
  onClose: () => void;
  promptId: string;
  promptIndex: number;
  question: PromptQuestionDetails;
  sectionIndex: number;
};

export const AddNote: FC<AddNoteProps> = ({
  onClose,
  promptId,
  promptIndex,
  question,
  sectionIndex,
}) => {
  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);

  const { setErrorMessage, setSuccessMessage } = useToast();

  const inspection = useSelector(
    inspectionSlice.selectors.inspectionDetails,
  ) as InspectionDetails;
  const inspectionId = inspection.id;

  const [text, setText] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  const charactersLeft = 1000 - text.length;

  const resetAndClose = () => {
    setText('');
    onClose();
  };

  const saveNote = () => {
    setIsSaving(true);

    dispatch(
      addNote({
        baseUrl,
        inspectionId,
        promptId,
        promptIndex,
        sectionIndex,
        text,
        token,
      }),
    )
      .unwrap()
      .then(() => {
        setSuccessMessage('Note added');
        resetAndClose();
      })
      .catch(() => setErrorMessage('Failed to add note'));
  };

  return (
    <SidePanel
      isOpen
      onClose={onClose}
      PanelTitle={
        <div className='flex items-center gap-1 text-green-base'>
          <ChatBubbleLeftIcon className='w-5' />
          Add Note
        </div>
      }
    >
      <div className='h-full flex flex-col justify-between text-gray-700'>
        <div>
          <QuestionSummary question={question} />
          <div className='p-6'>
            <Textarea
              autoFocus
              className={clsx(
                'w-full rounded-lg min-h-16 max-h-96 disabled:text-gray-400',
                'disabled:bg-gray-100 disabled:cursor-not-allowed',
                'focus:ring-blue-base focus:ring-2 focus:border-0',
              )}
              disabled={isSaving}
              maxLength={1000}
              onChange={(e) => setText(e.target.value)}
              placeholder='Enter note'
              required
              value={text}
            />
            <p
              className='text-left text-xs text-red-base'
              style={{
                opacity: charactersLeft <= 30 ? 1.3 - charactersLeft / 30 : 0,
              }}
            >
              {charactersLeft} characters remaining
            </p>
            <br />
          </div>
        </div>
        <div className='flex gap-2.5 px-6 py-4 border-t border-gray-200'>
          <Button
            aria-label='Save note'
            className='bg-green-base py-2 px-4 disabled:bg-gray-400'
            disabled={text.length === 0}
            loading={isSaving}
            onClick={saveNote}
          >
            {isSaving ? 'Saving...' : 'Save'}
          </Button>
          <Button
            aria-label='Cancel adding note'
            className='py-2 px-4'
            disabled={isSaving}
            onClick={resetAndClose}
            secondary
          >
            Cancel
          </Button>
        </div>
      </div>
    </SidePanel>
  );
};
