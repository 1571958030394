import { Form, FormSummary, Summary } from '@dakota/platform-client';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { moveSectionImpl, moveSectionQuestionImpl } from './dragAndDropImpl';

export type BuilderQuestion = {
  id: string;
  itemTypeId: string;
  itemTypeName: string;
  order: number;
  text: string;
};

export type BuilderSection = {
  /**
   * Text header for the section.
   */
  header?: string;
  /**
   * Whether the header has been modified by the user.
   */
  headerModified: boolean;
  /**
   * Whether the header is visible during the actual inspection.
   */
  headerVisible: boolean;
  /**
   * ID for the item type defining the section.
   */
  itemTypeId: string;
  /**
   * Name of the item type defining the section. The header defaults to this
   * value.
   */
  itemTypeName: string;
  /**
   * Order of the section in the form.
   */
  order: number;
  /**
   * When `true`, a question was just added to the section and we
   * scroll to it to visually show the section to the user.
   */
  questionJustAdded: boolean;
  /**
   * Questions for the section.
   */
  questions: BuilderQuestion[];
};

/**
 * Separate state just for the form builder.
 */
export type TemplatesState = {
  /**
   * Description is optional, so we cannot use an empty string for that.
   * In this case, we use `undefined` to indicate an absent value.
   * @default `undefined`
   */
  description?: string;
  /**
   * Facility for which the form is being built. If not set, the form is global.
   */
  facility?: Summary;
  sections: BuilderSection[];
  /**
   * Template (full version) that was used to start this form. Only fetched
   * and set when `templateSummary` is not `undefined`.
   */
  template?: Form;
  /**
   * Template (summary version) that was used to start this form. If not set,
   * the form is created by adding library questions from scratch.
   */
  templateSummary?: FormSummary;
  /**
   * Title for the template.
   */
  title: string;
};

const initialState: TemplatesState = {
  description: undefined,
  facility: undefined,
  sections: [],
  template: undefined,
  templateSummary: undefined,
  title: '',
};

export const templatesSlice = createSlice({
  initialState,
  name: 'templates',
  reducers: {
    addQuestionToTemplate: (
      state,
      action: PayloadAction<{
        question: BuilderQuestion;
      }>,
    ) => {
      const question = action.payload.question;
      const existingSection = state.sections.find(
        (s) => s.itemTypeId === question.itemTypeId,
      );
      if (existingSection) {
        // Add the question to an existing section, if it doesn't already exist
        if (!existingSection.questions.some((q) => q.id === question.id)) {
          existingSection.questionJustAdded = true;
          existingSection.questions.push({
            ...question,
            order: existingSection.questions.length + 1,
          });
        }
      } else {
        // New section, with one question
        state.sections.push({
          header: question.itemTypeName,
          headerModified: false,
          headerVisible: true,
          itemTypeId: question.itemTypeId,
          itemTypeName: question.itemTypeName,
          order: state.sections.length + 1,
          questionJustAdded: true,
          questions: [{ ...question, order: 1 }],
        });
      }
    },
    clearTemplate: (state) => {
      state.description = undefined;
      state.facility = undefined;
      state.title = '';
      state.sections = [];
      state.template = undefined;
      state.templateSummary = undefined;
    },
    deleteAllSections: (state) => {
      state.sections = [];
    },
    hideHeader: (state, action: PayloadAction<string>) => {
      const section = state.sections.find(
        (s) => s.itemTypeId === action.payload,
      );
      if (section) {
        section.headerVisible = false;
      }
    },
    loadTemplate: (state, action: PayloadAction<Form>) => {
      // We only copy the sections from the template, not the name or
      // description, to prompt the user to type new values.
      state.sections = action.payload.sections.map((section) => ({
        header: section.header,
        headerModified: section.header !== section.itemType.name,
        headerVisible: section.showHeader,
        itemTypeId: section.itemType.id,
        itemTypeName: section.itemType.name,
        order: section.order,
        questionJustAdded: false,
        questions: section.questions
          .toSorted((lhs, rhs) => lhs.order - rhs.order)
          .map((question) => ({
            id: question.id,
            itemTypeId: section.itemType.id,
            itemTypeName: section.itemType.name,
            order: question.order,
            text: question.text,
          })),
      }));
    },
    loadTemplateForEditing: (state, action: PayloadAction<Form>) => {
      // Load title and description here, then sections from the other reducer
      state.title = action.payload.name;
      state.description = action.payload.description;
      templatesSlice.caseReducers.loadTemplate(state, action);
    },
    moveSection: moveSectionImpl(),
    moveSectionQuestion: moveSectionQuestionImpl(),
    removeQuestionFromTemplate: (state, action: PayloadAction<string>) => {
      state.sections = state.sections
        .map((section) => ({
          ...section,
          questions: section.questions.filter(
            (question) => question.id !== action.payload,
          ),
        }))
        // Remove a section when the question removed was the only one there
        .filter((section) => section.questions.length > 0);
    },
    removeSection: (state, action: PayloadAction<string>) => {
      state.sections = state.sections.filter(
        (section) => section.itemTypeId !== action.payload,
      );
    },
    resetQuestionJustAdded: (state) => {
      state.sections = state.sections.map((section) => ({
        ...section,
        questionJustAdded: false,
      }));
    },
    setFacility: (state, action: PayloadAction<Summary | undefined>) => {
      state.facility = action.payload;
    },
    setTemplateSummary: (
      state,
      action: PayloadAction<FormSummary | undefined>,
    ) => {
      state.templateSummary = action.payload;
    },
    showHeader: (state, action: PayloadAction<string>) => {
      const section = state.sections.find(
        (s) => s.itemTypeId === action.payload,
      );
      if (section) {
        section.headerVisible = true;
      }
    },
    updateDescription: (state, action: PayloadAction<string>) => {
      state.description = action.payload.trim();
    },
    updateSectionHeader: (
      state,
      action: PayloadAction<{ header: string; itemTypeId: string }>,
    ) => {
      const sectionIdx = state.sections.findIndex(
        (s) => s.itemTypeId === action.payload.itemTypeId,
      );
      if (sectionIdx !== -1) {
        state.sections[sectionIdx].header = action.payload.header;
        state.sections[sectionIdx].headerModified =
          action.payload.header !== state.sections[sectionIdx].itemTypeName;
      }
    },
    updateTitle: (state, action: PayloadAction<string>) => {
      state.title = action.payload.trim();
    },
  },
  selectors: {
    description: (state: TemplatesState) => state.description,
    facility: (state: TemplatesState) => state.facility,
    sections: (state: TemplatesState) => state.sections,
    templateSummary: (state: TemplatesState) => state.templateSummary,
    title: (state: TemplatesState) => state.title,
  },
});
