import { useCheckPermission } from 'hooks/useCheckPermission';
import { FC, PropsWithChildren } from 'react';

interface PermissionGuardProps {
  /**
   * When passing a single permission rather than an array of permissions,
   * always opt to pass a single string rather than an array of a strings.
   */
  permissions: Array<string> | string;
}

const PermissionGuard: FC<PropsWithChildren<PermissionGuardProps>> = ({
  children,
  permissions,
  // eslint-disable-next-line sonarjs/function-return-type
}) => {
  const valid = useCheckPermission(permissions);

  return valid ? children : <></>;
};

export default PermissionGuard;
