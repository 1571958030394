import { notificationsSlice } from 'features/notifications/notificationsSlice';
import { FC } from 'react';
import { useSelector } from 'react-redux';

import { StickyMessage } from './StickyMessage';

export const StickyMessages: FC = () => {
  const messages = useSelector(notificationsSlice.selectors.stickyMessages);

  return messages.map((message) => (
    <StickyMessage key={message.id} message={message} />
  ));
};
