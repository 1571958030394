import {
  InspectionDetails,
  Prompt,
  PromptMedia,
} from '@dakota/platform-client';
import Attachment from 'components/Attachment';
import { configSlice } from 'features/config/configSlice';
import { updateAttachment } from 'features/inspections/inspectionActions';
import { inspectionSlice } from 'features/inspections/inspectionSlice';
import { tokenSlice } from 'features/token/tokenSlice';
import { userSlice } from 'features/user/userSlice';
import { useCheckPermission } from 'hooks/useCheckPermission';
import useToast from 'hooks/useToast';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { Permission } from 'utils/permissions';

import { CarouselData } from './CarouselData';

type AttachmentsProps = {
  prompt: Prompt;
  promptIndex: number;
  sectionIndex: number;
};

export const Attachments: FC<AttachmentsProps> = ({
  prompt,
  promptIndex,
  sectionIndex,
}) => {
  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);
  const currentUser = useSelector(userSlice.selectors.currentUser);

  const inspection = useSelector(
    inspectionSlice.selectors.inspectionDetails,
  ) as InspectionDetails;
  const isAdmin = useCheckPermission(Permission.Admin);
  const { setErrorMessage } = useToast();
  const [savingAttachmentId, setSavingAttachmentId] = useState('');

  const showInCarousel = (attachment: PromptMedia, attachmentIndex: number) => {
    dispatch(
      inspectionSlice.actions.openCarousel({
        attachment,
        attachmentIndex,
        promptIndex,
        sectionIndex,
      } as CarouselData),
    );
  };
  const onEditDescription = (
    description: string,
    attachment: PromptMedia,
    attachmentIndex: number,
  ) => {
    setSavingAttachmentId(attachment.id);
    dispatch(
      updateAttachment({
        attachmentId: attachment.id,
        attachmentIndex,
        baseUrl,
        description,
        inspectionId: inspection.id,
        promptId: attachment.promptId,
        promptIndex,
        sectionIndex,
        token,
      }),
    )
      .unwrap()
      .catch(() => setErrorMessage('Failed to update description'))
      .finally(() => setSavingAttachmentId(''));
  };

  return prompt.media.length > 0 ? (
    <div className='grid max-sm:grid-cols-2 sm:grid-cols-5 gap-2 pt-2'>
      {prompt.media.map((attachment, attachmentIndex) => {
        const canEdit = isAdmin || attachment.userId === currentUser.id;
        const isSaving = savingAttachmentId === attachment.id;
        return (
          <Attachment
            attachment={attachment}
            canEdit={canEdit}
            isSavingDescription={isSaving}
            key={attachment.id}
            onEdit={(description) =>
              onEditDescription(description, attachment, attachmentIndex)
            }
            showInCarousel={() => showInCarousel(attachment, attachmentIndex)}
          />
        );
      })}
    </div>
  ) : null;
};
