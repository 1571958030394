import { XMarkIcon } from '@heroicons/react/24/outline';
import { Slide } from '@mui/material';
import { clsx } from 'clsx';
import {
  notificationsSlice,
  StickyMessageType,
} from 'features/notifications/notificationsSlice';
import { FC, useState } from 'react';
import { useAppDispatch } from 'store/store';

type Props = {
  message: StickyMessageType;
};

export const StickyMessage: FC<Props> = ({ message }) => {
  const dispatch = useAppDispatch();

  const [isShown, setIsShown] = useState(true);

  const buttonClasses = clsx(
    'h-10 flex-none flex items-center gap-1 border rounded-md p-2',
    'border-gray-300 hover:border-gray-500',
    'text-gray-500 hover:text-gray-700',
  );

  return (
    <Slide
      in={isShown}
      onExited={() =>
        dispatch(notificationsSlice.actions.removeStickyMessage(message.id))
      }
    >
      <div
        aria-label='Sticky message'
        className={clsx(
          'bg-yellow-lightest px-8 py-4 text-gray-700 text-sm',
          'flex flex-col gap-4',
        )}
      >
        <div>{message.content}</div>
        <div className='flex items-center gap-4'>
          {message.actions.map((action) => (
            <button
              className={buttonClasses}
              key={action.text}
              onClick={action.onClick}
            >
              {action.text}
            </button>
          ))}
          <button className={buttonClasses} onClick={() => setIsShown(false)}>
            Dismiss
            <XMarkIcon className='w-5' />
          </button>
        </div>
      </div>
    </Slide>
  );
};
