import { TaskInstanceDetails, TaskMedia } from '@dakota/platform-client';
import Attachment from 'components/Attachment';
import { configSlice } from 'features/config/configSlice';
import { updateAttachment } from 'features/tasks/tasksActions';
import { tokenSlice } from 'features/token/tokenSlice';
import { userSlice } from 'features/user/userSlice';
import { useCheckPermission } from 'hooks/useCheckPermission';
import useToast from 'hooks/useToast';
import { AddAttachments } from 'Pages/Tasks/AddAttachments';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { Permission } from 'utils/permissions';

type Props = {
  task: TaskInstanceDetails;
};

export const Attachments: FC<Props> = ({ task }) => {
  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);

  const currentUser = useSelector(userSlice.selectors.currentUser);
  const isAdmin = useCheckPermission(Permission.Admin);
  const { setErrorMessage } = useToast();
  const [savingAttachmentId, setSavingAttachmentId] = useState('');

  const currentCount = task.media.length;

  const onEditDescription = (description: string, attachment: TaskMedia) => {
    setSavingAttachmentId(attachment.id);

    dispatch(
      updateAttachment({
        attachmentId: attachment.id,
        baseUrl,
        description,
        dueDate: task.timeline.scheduledDate,
        seriesId: task.seriesId,
        taskId: task.id,
        token,
      }),
    )
      .unwrap()
      .catch(() => setErrorMessage('Failed to update description'))
      .finally(() => setSavingAttachmentId(''));
  };

  return (
    <>
      <div className='text-base font-bold pt-2'>Attachments</div>
      <div aria-label='Task Attachments' className='grid grid-cols-2 gap-2'>
        {task.media.map((attachment) => (
          <Attachment
            attachment={attachment}
            canEdit={isAdmin || attachment.userId === currentUser.id}
            isSavingDescription={savingAttachmentId === attachment.id}
            key={attachment.id}
            onEdit={(description) => onEditDescription(description, attachment)}
          />
        ))}
      </div>
      <AddAttachments currentCount={currentCount} />
    </>
  );
};
