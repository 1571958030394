import { Identity } from './util';

export type TrackerEvents = {
  onClear?: () => void;
  onSet?: (id: Identity) => void;
};

const storageKey: string = 'identity.tracker';

class Tracker implements Partial<Identity> {
  static readonly Instance: Tracker = new Tracker();
  private static loaded: boolean;

  readonly #events: TrackerEvents[] = [];
  #identity?: Identity;
  #storage: Storage;

  private constructor() {
    if (Tracker.loaded) {
      throw new Error('Only one instance is allowed');
    }
    Tracker.loaded = true;
    this.#storage = sessionStorage;
    this.load();
  }

  private load() {
    const data = this.#storage.getItem(storageKey);
    if (!data) {
      this.clearUser();
      return;
    }

    try {
      const ident = JSON.parse(data) as Identity;
      this.setUser(ident);
    } catch {
      this.clearUser();
    }
  }

  private raiseEvents() {
    const ident = this.#identity;
    const notify = this.#events;
    if (!ident) {
      notify.forEach((i) => i.onClear?.());
    } else {
      notify.forEach((i) => i.onSet?.(ident));
    }
  }

  public clearUser() {
    if (!this.#identity) {
      return;
    }
    this.#identity = undefined;
    this.#storage.removeItem(storageKey);
    this.raiseEvents();
  }

  public registerEvents(events: TrackerEvents) {
    this.#events.push(events);
    this.raiseEvents();
  }

  public setUser(ident: Identity | null) {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!ident?.orgId || !ident?.userName) {
      this.clearUser();
      return;
    }

    try {
      const data = JSON.stringify(ident);
      this.#storage.setItem(storageKey, data);
      this.#identity = ident;
    } catch {
      this.clearUser();
    } finally {
      this.raiseEvents();
    }
  }

  get isValid() {
    return !!this.#identity;
  }
  get orgId() {
    return this.#identity?.orgId;
  }
  get orgName() {
    return this.#identity?.orgName;
  }
  get userId() {
    return this.#identity?.userId;
  }
  get userName() {
    return this.#identity?.userName;
  }
}

const UserTracker = Tracker.Instance;

export { UserTracker };
