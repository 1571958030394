import { FileType } from '@dakota/platform-client';
import {
  DocumentIcon,
  PaperClipIcon,
  PhotoIcon,
  SpeakerWaveIcon,
  TableCellsIcon,
  VideoCameraIcon,
} from '@heroicons/react/24/outline';
import Tooltip from 'components/Tooltip';
import { FC, useMemo } from 'react';

type AttachmentIconProps = {
  fileType: FileType;
};

export const AttachmentIcon: FC<AttachmentIconProps> = ({ fileType }) => {
  const { icon, tooltip } = useMemo(() => {
    switch (fileType) {
      case FileType.Audio:
        return { icon: <SpeakerWaveIcon />, tooltip: 'Audio' };
      case FileType.Document:
        return { icon: <DocumentIcon />, tooltip: 'Document' };
      case FileType.Image:
        return { icon: <PhotoIcon />, tooltip: 'Image' };
      case FileType.PDF:
        return { icon: <DocumentIcon />, tooltip: 'PDF Document' };
      case FileType.Spreadsheet:
        return { icon: <TableCellsIcon />, tooltip: 'Spreadsheet' };
      case FileType.Video:
        return { icon: <VideoCameraIcon />, tooltip: 'Video' };
      case FileType.Other:
      default:
        return { icon: <PaperClipIcon />, tooltip: 'Other documents' };
    }
  }, [fileType]);

  return (
    <div className='w-8 h-8 group-hover:w-12 group-hover:h-12'>
      <Tooltip arrow title={tooltip}>
        {icon}
      </Tooltip>
    </div>
  );
};
