import {
  Inspection,
  InspectionStatus,
  TaskInstance,
} from '@dakota/platform-client';

import { NotificationElement } from './types';

const compareInspections = (lhs: Inspection, rhs: Inspection) => {
  // Overdue first
  if (
    lhs.status === InspectionStatus.Overdue &&
    rhs.status !== InspectionStatus.Overdue
  ) {
    return -1;
  }
  if (
    lhs.status !== InspectionStatus.Overdue &&
    rhs.status === InspectionStatus.Overdue
  ) {
    return 1;
  }

  // Alphabetical order by template name
  const templateNameCompare = lhs.form.name.localeCompare(rhs.form.name);
  if (templateNameCompare !== 0) {
    return templateNameCompare;
  }

  // This is just to make the sorting stable
  return lhs.id.localeCompare(rhs.id);
};

const compareTasks = (lhs: TaskInstance, rhs: TaskInstance) => {
  // Overdue first
  if (lhs.overdue && !rhs.overdue) {
    return -1;
  }
  if (!lhs.overdue && rhs.overdue) {
    return 1;
  }

  // Alphabetical order by title
  const titleCompare = lhs.title.localeCompare(rhs.title);
  if (titleCompare !== 0) {
    return titleCompare;
  }

  // This is just to make the sorting stable
  return lhs.id.localeCompare(rhs.id);
};

const compareInspectionAndTask = (lhs: Inspection, rhs: TaskInstance) => {
  // Overdue first
  if (lhs.status === InspectionStatus.Overdue && !rhs.overdue) {
    return -1;
  }
  if (lhs.status !== InspectionStatus.Overdue && rhs.overdue) {
    return 1;
  }

  // Alphabetical order by template name/title
  const titleCompare = lhs.form.name.localeCompare(rhs.title);
  if (titleCompare !== 0) {
    return titleCompare;
  }

  // This is just to make the sorting stable
  return lhs.id.localeCompare(rhs.id);
};

export const compareNotificationElement = (
  lhs: NotificationElement,
  rhs: NotificationElement,
) => {
  // Oldest first, then overdue first
  if (lhs.timeline.scheduledDate < rhs.timeline.scheduledDate) {
    return -1;
  }
  if (lhs.timeline.scheduledDate > rhs.timeline.scheduledDate) {
    return 1;
  }

  // From this point, both elements have the same scheduled date

  if (lhs instanceof Inspection) {
    if (rhs instanceof Inspection) {
      return compareInspections(lhs, rhs);
    } else {
      return compareInspectionAndTask(lhs, rhs);
    }
  }

  if (rhs instanceof TaskInstance) {
    return compareTasks(lhs, rhs);
  } else {
    return -compareInspectionAndTask(rhs, lhs);
  }
};
