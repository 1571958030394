import { InspectionStatus } from '@dakota/platform-client';

export const getStatusLabel = (status: InspectionStatus) => {
  switch (status) {
    case InspectionStatus.Canceled:
      return 'Canceled';
    case InspectionStatus.Completed:
      return 'Completed';
    case InspectionStatus.InProgress:
      return 'In Progress';
    case InspectionStatus.Overdue:
      return 'Overdue';
    case InspectionStatus.Scheduled:
      return 'Scheduled';
  }
};
