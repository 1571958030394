import { clsx } from 'clsx';
import { taskEditSlice } from 'features/tasks/taskEditSlice';
import { FC } from 'react';
import { useSelector } from 'react-redux';

export const Description: FC = () => {
  const description = useSelector(taskEditSlice.selectors.description);

  return (
    <div
      className={clsx(
        'text-sm font-normal text-balance',
        description ? 'text-gray-700' : 'text-gray-400 italic',
      )}
    >
      {description || 'No description'}
    </div>
  );
};
