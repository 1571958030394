import { Summary } from '@dakota/platform-client';
import { UserIcon } from '@heroicons/react/24/solid';
import { Tooltip } from '@mui/material';
import { clsx } from 'clsx';
import { FC, useMemo } from 'react';

import { getColorById } from './color';
import { getInitials } from './initials';

type AssigneeAvatarProps = {
  /**
   * The user to display the avatar for. If not provided, the avatar will
   * display as unassigned.
   */
  user?: Summary;
};

export const AssigneeAvatar: FC<AssigneeAvatarProps> = ({ user }) => {
  // Determine the background color based on the user ID or default to gray
  // for unassigned
  const bgColor = useMemo(() => {
    return user?.id ? getColorById(user.id) : 'bg-gray-400';
  }, [user?.id]);

  return (
    <Tooltip arrow placement='bottom' title={user?.name ?? 'Unassigned'}>
      <span
        className={clsx(
          'inline-flex h-8 w-8 items-center justify-center',
          'rounded-full select-none',
          bgColor,
        )}
        data-testid='assignee-avatar'
      >
        {user?.id ? (
          <span className='text-sm font-medium leading-none text-white'>
            {getInitials(user.name)}
          </span>
        ) : (
          <UserIcon aria-label='Unassigned' className='h-5 w-5 text-white' />
        )}
      </span>
    </Tooltip>
  );
};
