import { Group, ItemType } from '@dakota/platform-client';
import { PlusIcon } from '@heroicons/react/20/solid';
import PermissionGuard from 'auth/PermissionGuard';
import Autocomplete from 'components/Autocomplete';
import { getRendererWithLibraryLogo } from 'components/Autocomplete/renderFunctions';
import Button from 'components/Button';
import Chip from 'components/Chip';
import { ClearAllButton } from 'components/ClearAll';
import DakotaLibraryLogo from 'components/DakotaLibraryLogo';
import { PageHeader } from 'components/PageHeader';
import SearchInput from 'components/SearchInput';
import Table from 'components/Table';
import { ColumnProps } from 'components/Table/types';
import { configSlice } from 'features/config/configSlice';
import { getAllItemGroups } from 'features/items/itemGroupsActions';
import { itemGroupsSlice } from 'features/items/itemGroupsSlice';
import { getAllItemTypes } from 'features/items/itemTypesActions';
import { itemTypesSlice } from 'features/items/itemTypesSlice';
import { getAllQuestions } from 'features/questions/questionsActions';
import { questionsSlice } from 'features/questions/questionsSlice';
import { tokenSlice } from 'features/token/tokenSlice';
import Fuse from 'fuse.js';
import { useCheckPermission } from 'hooks/useCheckPermission';
import { DataStatus, usePageLoadTracking } from 'hooks/usePageLoadTracking';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { alphabeticalCompare } from 'utils/functional';
import { getHighlighter } from 'utils/highlighter';
import { Permission } from 'utils/permissions';

import AddEditItemType from './addItemType';
import ItemTypeMenu from './itemTypeMenu';

const ItemTypes: React.FC = () => {
  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);
  const allQuestions = useSelector(questionsSlice.selectors.allQuestions);

  const { stopTracking } = usePageLoadTracking();

  const itemGroups = useSelector(itemGroupsSlice.selectors.itemGroups);
  const isLoadingItemGroups = useSelector(
    itemGroupsSlice.selectors.isLoadingItemGroups,
  );

  const itemTypes = useSelector(itemTypesSlice.selectors.itemTypes);
  const isLoadingItemTypes = useSelector(
    itemTypesSlice.selectors.isLoadingItemTypes,
  );

  useEffect(() => {
    const fetchQuestions = async () => {
      if (!allQuestions.length) {
        await dispatch(getAllQuestions({ baseUrl, token }));
      }
    };
    const fetchItemGroups = async () => {
      if (!itemGroups.length) {
        await dispatch(getAllItemGroups({ baseUrl, token }));
      }
    };
    const fetchItemTypes = async () => {
      if (!itemTypes.length) {
        await dispatch(getAllItemTypes({ baseUrl, token }));
      }
    };

    const status =
      allQuestions.length && itemGroups.length && itemTypes.length
        ? DataStatus.PreviouslyLoaded
        : DataStatus.Fetched;

    void Promise.all([
      fetchQuestions(),
      fetchItemGroups(),
      fetchItemTypes(),
    ]).then(() => stopTracking(status));
  }, [
    allQuestions.length,
    baseUrl,
    dispatch,
    itemGroups.length,
    itemTypes.length,
    stopTracking,
    token,
  ]);

  const canSeeContextualMenu = useCheckPermission([
    Permission.UpdateItemMetadata,
    Permission.DeactivateItemMetadata,
  ]);

  const [searchQuery, setSearchQuery] = useState('');
  const [selectableItemGroups, setSelectableItemGroups] = useState<Group[]>([]);
  const [selectedItemGroups, setSelectedItemGroups] = useState<Group[]>([]);
  const [isAddItemTypePanelOpen, setIsAddItemTypePanelOpen] = useState(false);

  // We *only* display the filtered item types
  const [filteredItemTypes, setFilteredItemTypes] = useState<ItemType[]>([]);

  type Status = 'Active' | 'All' | 'Inactive';
  const statuses = ['All', 'Active', 'Inactive'] as const;
  const [selectedStatus, setSelectedStatus] = useState<Status>('Active');

  useEffect(() => {
    setSelectableItemGroups(
      itemGroups
        .filter((ig) => !ig.inactive)
        .toSorted(alphabeticalCompare((e) => e.name)),
    );
  }, [itemGroups]);

  useEffect(() => {
    // Add item group name to facilitate search
    let newItemTypes = itemTypes.map((itemType) => ({
      ...itemType,
      itemGroupName: itemGroups.find((g) => g.id === itemType.groupId)?.name,
    }));

    if (selectedStatus === 'Active') {
      newItemTypes = newItemTypes.filter((q) => !q.inactive);
    } else if (selectedStatus === 'Inactive') {
      newItemTypes = newItemTypes.filter((q) => q.inactive);
    }

    if (selectedItemGroups.length > 0) {
      newItemTypes = newItemTypes.filter((itemType) =>
        selectedItemGroups.find((group) => group.id === itemType.groupId),
      );
    }

    if (searchQuery) {
      const fuse = new Fuse(newItemTypes, {
        findAllMatches: true,
        ignoreLocation: true,
        keys: ['name', 'itemGroupName'],
        shouldSort: true,
        threshold: 0.2,
        useExtendedSearch: true,
      });
      newItemTypes = fuse.search(searchQuery).map((e) => e.item);
    }

    setFilteredItemTypes(
      // convert back to ItemType without group name for display
      newItemTypes
        .toSorted(alphabeticalCompare((i) => i.name))
        .map((i) => ItemType.fromJS(i)),
    );
  }, [itemGroups, itemTypes, searchQuery, selectedItemGroups, selectedStatus]);

  const removeItemGroup = (group: Group) => {
    setSelectedItemGroups((prev) => prev.filter((g) => g.id !== group.id));
  };

  const resetFilters = () => {
    setSearchQuery('');
    setSelectedItemGroups([]);
    setSelectedStatus('Active');
  };

  const getClasses = (inactive: boolean) => (inactive ? 'text-gray-400' : '');

  const highlight = getHighlighter(searchQuery);

  const columns: ReadonlyArray<ColumnProps<ItemType>> = [
    {
      cellDataTestId: 'item-type',
      key: 'item-type',
      render: (itemType) => {
        return (
          <p className={getClasses(itemType.inactive)}>
            {highlight(itemType.name)}
            {itemType.isPlatformDefined && <DakotaLibraryLogo />}
          </p>
        );
      },
      title: 'Item Type',
    },
    {
      cellDataTestId: 'item-group',
      key: 'item-group',
      render: (itemType) => {
        const group = itemGroups.find((g) => g.id === itemType.groupId);
        return (
          <p className={getClasses(itemType.inactive)}>
            {highlight(group?.name)}
            {group?.isPlatformDefined && <DakotaLibraryLogo />}
          </p>
        );
      },
      title: 'Item Group',
    },
    {
      cellDataTestId: 'menu',
      key: 'menu',
      render: (itemType) => {
        const group = itemGroups.find(
          (g) => g.id === itemType.groupId,
        ) as Group;
        return canSeeContextualMenu &&
          !itemType.isPlatformDefined &&
          !itemType.isDefault ? (
          <ItemTypeMenu itemGroup={group} itemType={itemType} />
        ) : (
          <></>
        );
      },
      title: '',
    },
  ];

  return (
    <div className='p-4 sm:p-8'>
      <PageHeader scaffold={['Item Types', 'Manage Content']} title='Types'>
        <PermissionGuard permissions={Permission.CreateItemMetadata}>
          <Button
            className='bg-green-base px-4 py-2'
            data-testid='add-item-type-button'
            icon={<PlusIcon />}
            onClick={() => setIsAddItemTypePanelOpen(true)}
          >
            Add Item Type
          </Button>
        </PermissionGuard>
      </PageHeader>
      <div className='filters-container'>
        <SearchInput
          data-testid='search-input'
          id='item-types-search-input'
          onSearch={(e) => setSearchQuery(e)}
          value={searchQuery}
        />
        <Autocomplete
          className='max-sm:w-full min-w-36 sm:max-w-64 sm:w-48'
          getOptionKey={(option) => option.id}
          getOptionLabel={(option) => option.name}
          id='item-types-group-selector'
          label='Item Group'
          loading={isLoadingItemGroups}
          multiple
          onChange={setSelectedItemGroups}
          options={selectableItemGroups}
          renderOption={getRendererWithLibraryLogo((option) => option.name)}
          value={selectedItemGroups}
        />
        <PermissionGuard permissions={Permission.Admin}>
          <Autocomplete
            className='w-28'
            id='item-types-status-selector'
            label='Status'
            onChange={setSelectedStatus}
            options={statuses}
            value={selectedStatus}
          />
        </PermissionGuard>
        {(selectedItemGroups.length > 0 ||
          selectedStatus !== 'Active' ||
          !!searchQuery) && <ClearAllButton onClick={resetFilters} />}
      </div>
      {selectedItemGroups.length > 0 && (
        <div className='flex items-center gap-2 pb-2'>
          <span className='text-sm font-normal pr-1'>Groups:</span>
          {selectedItemGroups.map((group) => (
            <Chip
              key={group.id}
              onRemove={() => removeItemGroup(group)}
              testId={`chip-${group.id}`}
              text={group.name}
            />
          ))}
        </div>
      )}
      <Table
        columns={columns}
        data={filteredItemTypes}
        footer={`${filteredItemTypes.length} results`}
        id='item-types-table'
        loading={isLoadingItemGroups || isLoadingItemTypes}
      />
      {isAddItemTypePanelOpen && (
        <AddEditItemType onClose={() => setIsAddItemTypePanelOpen(false)} />
      )}
    </div>
  );
};

export default ItemTypes;
