import {
  FormSummary,
  Priority,
  Summary,
  UserSummary,
} from '@dakota/platform-client';
import { LocalDate } from '@js-joda/core';
import { Checkbox, Fade } from '@mui/material';
import { clsx } from 'clsx';
import Autocomplete from 'components/Autocomplete';
import Button from 'components/Button';
import { DatePicker } from 'components/DatePicker';
import { getPriorityLabel } from 'Pages/Tasks/types';
import React from 'react';
import resolveConfig from 'tailwindcss/resolveConfig';
import { unassignedUser } from 'utils/user';

import tailwindConfig from '../../../../tailwind.config';
import ReorderZones from '../ReorderZones';

type Props = {
  assignee: UserSummary;
  date: LocalDate;
  facility: null | Summary;
  form: FormSummary;
  isLoadingZones: boolean;
  orderedZones: Summary[];
  priority: Priority;
  selectableFacilities: Summary[];
  selectableUsers: UserSummary[];
  selectableZones: Summary[];
  setAssignee: React.Dispatch<React.SetStateAction<UserSummary>>;
  setDate: React.Dispatch<React.SetStateAction<LocalDate>>;
  setFacility: React.Dispatch<React.SetStateAction<null | Summary>>;
  setIsFacilityMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setPriority: React.Dispatch<React.SetStateAction<Priority>>;
  setShouldRenderZones: React.Dispatch<React.SetStateAction<boolean>>;
  setZones: (zones: Summary[]) => void;
  shouldRenderZones: boolean;
};

const { theme } = resolveConfig(tailwindConfig);

const DialogBody = ({
  assignee,
  date,
  facility,
  form,
  isLoadingZones,
  orderedZones,
  priority,
  selectableFacilities,
  selectableUsers,
  selectableZones,
  setAssignee,
  setDate,
  setFacility,
  setIsFacilityMenuOpen,
  setPriority,
  setShouldRenderZones,
  setZones,
  shouldRenderZones,
}: Props) => {
  return (
    <div className='flex-1 p-8 flex gap-4'>
      <div className='w-full pr-4 mx-auto space-y-4 items-start'>
        <Autocomplete
          disabled={!!form.facility}
          getOptionKey={(option) => option.id}
          getOptionLabel={(option) => option.name}
          id='facility-select'
          isOptionEqualToValue={(option, value) => option.id === value.id}
          // The selectableUsers array is updated when the facility changes.
          // If the new selectableUsers array does not contain the currently
          // selected assignee, MUI throws a console warning.
          // Wrapping something in a setTimeout pushes that action to the task
          // queue, preventing this update from occurring until after we've
          // updated the assignee. https://developer.mozilla.org/en-US/docs/Web/API/HTML_DOM_API/Microtask_guide
          onChange={(option) => {
            setAssignee(unassignedUser);
            setTimeout(() => {
              setFacility(option);
            }, 0);
          }}
          onClose={() => setIsFacilityMenuOpen(false)}
          onOpen={() => setIsFacilityMenuOpen(true)}
          options={selectableFacilities}
          outsideLabel='Facility'
          placeholder='Select a facility'
          required
          value={facility}
        />
        {selectableZones.length > 0 && (
          <div
            className={clsx(
              'flex items-center gap-3 text-sm',
              shouldRenderZones ? 'text-gray-700' : 'text-gray-400',
            )}
          >
            <div>
              <label className='cursor-pointer' htmlFor='enable-zones'>
                Specify Zones
              </label>
              <Checkbox
                checked={shouldRenderZones}
                data-testid='zone-checkbox'
                id='enable-zones'
                inputProps={{
                  'aria-label': 'Specify Zones',
                }}
                onChange={(e) => setShouldRenderZones(e.target.checked)}
                size='small'
                sx={{
                  '&.Mui-checked': {
                    color: theme.colors.green['base'],
                  },
                  color: theme.colors.green['base'],
                  opacity: shouldRenderZones ? 1 : 0.75,
                }}
              />
            </div>
            {shouldRenderZones && (
              <>
                <Button
                  className='text-xs'
                  disabled={orderedZones.length === selectableZones.length}
                  onClick={() => setZones(selectableZones)}
                  secondary
                >
                  Select all
                </Button>
                <Button
                  className='text-xs'
                  disabled={orderedZones.length === 0}
                  onClick={() => setZones([])}
                  secondary
                >
                  Clear selection
                </Button>
              </>
            )}
          </div>
        )}
        <Fade in={shouldRenderZones} unmountOnExit>
          <div>
            <Autocomplete
              getOptionKey={(zone) => zone.id}
              getOptionLabel={(zone) => zone.name}
              id='zone-selector'
              isOptionEqualToValue={(zone, value) => zone.id === value.id}
              label='Zone(s)'
              loading={isLoadingZones}
              multiple
              onChange={setZones}
              options={selectableZones}
              value={orderedZones}
            />
          </div>
        </Fade>
        <Autocomplete
          disabled={!facility}
          getOptionKey={(option) => option.id}
          getOptionLabel={(option) => option.name}
          id='assignee-select'
          isOptionEqualToValue={(option, value) => option.id === value.id}
          // This key ensures this component is re-rendered when the facility
          // changes.
          key={facility?.id ?? assignee.id}
          onChange={(option) => {
            setAssignee(option);
          }}
          options={selectableUsers}
          outsideLabel='Assign To'
          placeholder='Select an assignee'
          value={assignee}
        />
        <div>
          <label
            className='block mb-2 required-field text-gray-700'
            htmlFor='inspection-date'
          >
            Scheduled Date
          </label>
          <DatePicker
            asSingle
            id='inspection-date'
            onChange={setDate}
            placeholder='Select a date'
            popoverDirection='down'
            value={date}
          />
        </div>
        <div>
          <label htmlFor='inspection-priority-selector'>Priority</label>
          <Autocomplete
            fullWidth
            getOptionLabel={getPriorityLabel}
            id='inspection-priority-selector'
            onChange={setPriority}
            options={[Priority.Low, Priority.Medium, Priority.High]}
            value={priority}
          />
        </div>
      </div>
      {shouldRenderZones && <ReorderZones />}
    </div>
  );
};

export default DialogBody;
