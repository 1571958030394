import { TaskInstanceDetails } from '@dakota/platform-client';
import { Dialog } from '@mui/material';
import { clsx } from 'clsx';
import Button from 'components/Button';
import { PageHeader } from 'components/PageHeader';
import Confirmation from 'components/SimpleConfirmation';
import { taskEditSlice } from 'features/tasks/taskEditSlice';
import { useTaskEdit } from 'hooks/useTaskEdit';
import useToast from 'hooks/useToast';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';

import { Assignee } from './fields/Assignee';
import { Attachments } from './fields/Attachments';
import { Description } from './fields/Description';
import { DueDate } from './fields/DueDate';
import { Facility } from './fields/Facility';
import { Notes } from './fields/Notes';
import { Priority } from './fields/Priority';
import { Recurrence } from './fields/Recurrence';
import { ReportedBy } from './fields/ReportedBy';
import { Status } from './fields/Status';
import { Title } from './fields/Title';
import { Zone } from './fields/Zone';
import { Header } from './header';

type EditTaskProps = {
  onClose: () => void;
  task: TaskInstanceDetails;
};

export const EditTask: FC<EditTaskProps> = ({ onClose, task }) => {
  const dispatch = useAppDispatch();
  const { setErrorMessage, setSuccessMessage } = useToast();

  const hasChanges = useSelector(taskEditSlice.selectors.hasUnsavedChanges);
  const { editTaskInstance } = useTaskEdit();

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const close = () => {
    if (hasChanges) {
      setShowConfirmation(true);
    } else {
      onClose();
    }
  };

  const confirmClose = () => {
    setShowConfirmation(false);
    onClose();
  };

  useEffect(() => {
    dispatch(taskEditSlice.actions.load(task));
  }, [dispatch, task]);

  const saveTask = () => {
    setIsSaving(true);
    editTaskInstance()
      .then(() => setSuccessMessage('Task updated successfully'))
      .catch(() => setErrorMessage('Failed to update task'))
      .finally(() => setIsSaving(false));
  };

  return (
    <Dialog disableEscapeKeyDown fullScreen onClose={close} open>
      <PageHeader scaffold={['Edit Task', 'Tasks']} />
      <Header onClose={close} seriesId={task.seriesId} title={task.title} />
      <div className='flex flex-1 justify-center overflow-y-hidden'>
        <div className='w-full max-w-6xl flex flex-col'>
          <div className='p-4 border-x border-t-0 border border-gray-100'>
            <Title />
          </div>
          <div className='p-4 border-t-0 border border-gray-100 space-y-6'>
            <Recurrence />
            <Description />
          </div>
          <div className='flex flex-row border-x border-gray-100 overflow-y-auto'>
            <div
              className={clsx(
                'flex flex-col w-1/2 gap-4 p-6',
                'border-r border-gray-100 text-sm text-gray-700',
                '*:flex *:justify-stretch *:items-center *:h-9',
              )}
            >
              <div className='text-base font-bold col-span-2'>Task Details</div>
              <Facility />
              <Zone />
              <Assignee />
              <Priority />
              <DueDate />
              <Status />
              <div className='text-base font-bold col-span-2'>Source</div>
              <ReportedBy />
            </div>
            <div className='flex flex-col w-1/2 gap-4 p-6 text-sm overflow-y-auto'>
              <Notes />
              <Attachments task={task} />
            </div>
          </div>
        </div>
      </div>
      <div className='w-full border-t p-4 text-sm bg-gray-100'>
        <div className='flex justify-center'>
          <div className='w-full max-w-6xl flex gap-2'>
            <Button
              disabled={!hasChanges}
              id='save-edit-task'
              loading={isSaving}
              onClick={saveTask}
            >
              {isSaving ? 'Saving...' : 'Save'}
            </Button>
            <Button id='cancel-edit-task' onClick={close} secondary>
              Cancel
            </Button>
          </div>
        </div>
      </div>
      {showConfirmation && (
        <Confirmation
          cancelText='Return to form'
          confirmText='Discard'
          onCancel={() => setShowConfirmation(false)}
          onConfirm={confirmClose}
          title='Discard Changes?'
        >
          You have unsaved changes. Are you sure you want to discard them?
        </Confirmation>
      )}
    </Dialog>
  );
};
