import {
  AnswerType,
  Group,
  ItemType,
  QuestionBatchResult,
} from '@dakota/platform-client';
import { answersSlice } from 'features/answers/answersSlice';
import { itemGroupsSlice } from 'features/items/itemGroupsSlice';
import { itemTypesSlice } from 'features/items/itemTypesSlice';
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';

export type QuestionToAdd = {
  answerType: AnswerType;
  citation: string;
  itemType: ItemType;
  text: string;
};

export type AddMultipleContextState = {
  defaultAnswerType: AnswerType;
  defaultItemType: ItemType;
  getGroupName: (itemType: ItemType) => string;
  lines: string[];
  /**
   * For each new question, it indicates whether
   * the question already exists for the exact same Item Type.
   */
  questionExists: boolean[];
  /**
   * For each new question, it indicates if there's another new question
   * with the same text and for the same Item Type.
   */
  questionIsDuplicate: boolean[];
  questions: QuestionToAdd[];
  questionSelected: boolean[];
  results: QuestionBatchResult[];
  selectableAnswerTypes: AnswerType[];
  selectableItemTypes: ItemType[];
  setLines: Dispatch<SetStateAction<string[]>>;
  setQuestionExists: Dispatch<SetStateAction<boolean[]>>;
  setQuestionIsDuplicate: Dispatch<SetStateAction<boolean[]>>;
  setQuestions: Dispatch<SetStateAction<QuestionToAdd[]>>;
  setQuestionSelected: Dispatch<SetStateAction<boolean[]>>;
  setResults: Dispatch<SetStateAction<QuestionBatchResult[]>>;
  setStep: Dispatch<SetStateAction<number>>;
  setText: Dispatch<SetStateAction<string>>;
  step: number;
  text: string;
};

export const AddMultipleContext = createContext<
  AddMultipleContextState | undefined
>(undefined);

export const AddMultipleContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [questions, setQuestions] = useState<QuestionToAdd[]>([]);
  /**
   * For each new question, it indicates whether the question
   * already exists for the exact same Item Type.
   */
  const [questionExists, setQuestionExists] = useState<boolean[]>([]);
  const [questionIsDuplicate, setQuestionIsDuplicate] = useState<boolean[]>([]);
  const [questionSelected, setQuestionSelected] = useState<boolean[]>([]);
  const [step, setStep] = useState(1);
  const [text, setText] = useState('');
  const [lines, setLines] = useState<string[]>([]);
  const [results, setResults] = useState<QuestionBatchResult[]>([]);

  const itemGroups = useSelector(itemGroupsSlice.selectors.itemGroups);
  const itemTypes = useSelector(itemTypesSlice.selectors.itemTypes);
  const answerTypes = useSelector(answersSlice.selectors.answerTypes);

  const defaultAnswerType = answerTypes.find(
    (a) => a.name === 'Pass/Fail',
  ) as AnswerType;

  const defaultItemType = itemTypes.find((i) => i.isDefault) as ItemType;

  const getGroupName = useCallback(
    (itemType: ItemType) =>
      (itemGroups.find((g) => g.id === itemType.groupId) as Group).name,
    [itemGroups],
  );

  const selectableAnswerTypes = answerTypes.toSorted((a, b) =>
    a.name.localeCompare(b.name),
  );

  const selectableItemTypes = itemTypes
    .filter((i) => !i.inactive)
    .toSorted(
      (a, b) =>
        getGroupName(a).localeCompare(getGroupName(b)) ||
        a.name.localeCompare(b.name),
    );

  const value = useMemo(
    () => ({
      defaultAnswerType,
      defaultItemType,
      getGroupName,
      lines,
      questionExists,
      questionIsDuplicate,
      questions,
      questionSelected,
      results,
      selectableAnswerTypes,
      selectableItemTypes,
      setLines,
      setQuestionExists,
      setQuestionIsDuplicate,
      setQuestions,
      setQuestionSelected,
      setResults,
      setStep,
      setText,
      step,
      text,
    }),
    [
      defaultAnswerType,
      defaultItemType,
      getGroupName,
      lines,
      questionExists,
      questionIsDuplicate,
      questionSelected,
      questions,
      results,
      selectableAnswerTypes,
      selectableItemTypes,
      step,
      text,
    ],
  );

  return (
    <AddMultipleContext.Provider value={value}>
      {children}
    </AddMultipleContext.Provider>
  );
};
