import { configSlice } from 'features/config/configSlice';
import { FC, PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';

type PageHeaderProps = {
  /**
   * The scaffold to use to build the page title.
   *
   * For example, if the scaffold is `['Facilities', 'My Organization']`,
   * then the title will be `Facilities | My Organization | {AppName}`,
   * where `{AppName}` is the name of the application (Dakota Software).
   *
   * Defaults to the title of the page if not provided.
   *
   * @default [title]
   */
  scaffold?: string[];
  /**
   * The title to display on the page, below the tab menu.
   * Only rendered if provided.
   */
  title?: string;
};

/**
 * Render the header of our standard pages, with a title on the left
 * and one or more buttons on the right, which are passed as children.
 */
export const PageHeader: FC<PropsWithChildren<PageHeaderProps>> = ({
  children,
  title,
  // eslint-disable-next-line perfectionist/sort-objects
  scaffold = [title],
}) => {
  const appName = useSelector(configSlice.selectors.appName);

  return (
    <>
      <Helmet>
        <title>
          {scaffold.join(' | ')} | {appName}
        </title>
      </Helmet>
      {title && (
        <div className='flex justify-between w-full pb-8 max-sm:hidden'>
          <h2 aria-label='Page title' className='page-title' role='heading'>
            {title}
          </h2>
          <div className='flex gap-2.5 items-center'>{children}</div>
        </div>
      )}
    </>
  );
};
