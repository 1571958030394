import { InspectionDetails, PromptComment } from '@dakota/platform-client';
import { TrashIcon } from '@heroicons/react/24/outline';
import { IconButton } from '@mui/material';
import EditableText from 'components/EditableText';
import { configSlice } from 'features/config/configSlice';
import { deleteNote, updateNote } from 'features/inspections/inspectionActions';
import { inspectionSlice } from 'features/inspections/inspectionSlice';
import { tokenSlice } from 'features/token/tokenSlice';
import { userSlice } from 'features/user/userSlice';
import { useCheckPermission } from 'hooks/useCheckPermission';
import useToast from 'hooks/useToast';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { formatBackendDateTime } from 'utils/date';
import { Permission } from 'utils/permissions';

type NoteProps = {
  note: PromptComment;
  noteIndex: number;
  promptIndex: number;
  sectionIndex: number;
};

export const Note: FC<NoteProps> = ({
  note,
  noteIndex,
  promptIndex,
  sectionIndex,
}) => {
  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);
  const currentUser = useSelector(userSlice.selectors.currentUser);

  const isAdmin = useCheckPermission(Permission.Admin);
  const canEdit = isAdmin || note.userId === currentUser.id;

  const inspection = useSelector(
    inspectionSlice.selectors.inspectionDetails,
  ) as InspectionDetails;
  const [updating, setUpdating] = useState(false);

  const { setErrorMessage, setSuccessMessage } = useToast();

  const updateText = (text: string) => {
    setUpdating(true);

    dispatch(
      updateNote({
        baseUrl,
        commentId: note.id,
        inspectionId: inspection.id,
        noteIndex,
        promptId: note.promptId,
        promptIndex,
        sectionIndex,
        text,
        token,
      }),
    )
      .unwrap()
      .catch(() => setErrorMessage('Failed to update note'))
      .finally(() => setUpdating(false));
  };

  const removeNote = () => {
    dispatch(
      deleteNote({
        baseUrl,
        commentId: note.id,
        inspectionId: inspection.id,
        noteIndex,
        promptId: note.promptId,
        promptIndex,
        sectionIndex,
        token,
      }),
    )
      .unwrap()
      .then(() => setSuccessMessage('Note removed'))
      .catch(() => setErrorMessage('Failed to remove note'));
  };

  return (
    <div className='border border-gray-300 rounded-md bg-gray-50 text-gray-700 p-2'>
      <div className='flex justify-between items-center'>
        <EditableText
          containerClasses='flex-1'
          disabled={!canEdit}
          growVertically
          id={`edit-note-textarea-${note.id}`}
          initialEditableValue={note.text}
          loading={updating}
          maxLength={1000}
          onEdit={updateText}
          saveOnEnter
          text={note.text}
          textClasses='text-gray-700 py-0'
        />
        <IconButton
          aria-label='Delete note'
          disabled={!canEdit}
          onClick={removeNote}
        >
          <TrashIcon className='text-red-base w-5' />
        </IconButton>
      </div>
      <div className='text-xs pl-2 text-gray-500'>
        {note.userName} &mdash; {formatBackendDateTime(note.commented)}
      </div>
    </div>
  );
};
