import { InspectionStatus } from '@dakota/platform-client';

export function getStatusText(status: InspectionStatus) {
  switch (status) {
    case InspectionStatus.Completed:
      return 'Completed';
    case InspectionStatus.InProgress:
      return 'In Progress';
    case InspectionStatus.Overdue:
      return 'Overdue';
    case InspectionStatus.Scheduled:
      return 'Scheduled';
  }
}
