import { Feature } from '@dakota/platform-client';
import { configSlice } from 'features/config/configSlice';
import { getFeature } from 'features/flags/flagsActions';
import { tokenSlice } from 'features/token/tokenSlice';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';

/**
 * Hook that returns true if the given feature flag is enabled.
 */
export const useFeatureFlag = (name: string) => {
  const dispatch = useAppDispatch();
  const token = useSelector(tokenSlice.selectors.token);
  const baseUrl = useSelector(configSlice.selectors.backend);

  const [feature, setFeature] = useState<Feature | undefined>();

  useEffect(() => {
    void dispatch(getFeature({ baseUrl, name, token }))
      .unwrap()
      .then(setFeature);
  }, [name, token, baseUrl, dispatch]);

  return feature?.enabled ?? false;
};
