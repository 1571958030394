import { useAuth0 } from '@auth0/auth0-react';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { DakotaUser } from 'auth/DakotaUser';
import { ProtectedRoute } from 'auth/ProtectedRoute';
import PrintInspection from 'components/Inspections/PrintInspection';
import Layout from 'components/Layout';
import Loading from 'components/Loading';
import Login from 'components/Login';
import Logout from 'components/Logout';
import { configSlice } from 'features/config/configSlice';
import { getTimeZones } from 'features/timeZones/timeZoneActions';
import { tokenSlice } from 'features/token/tokenSlice';
import {
  getAllUsers,
  getAllUserSummaries,
  getCurrentUser,
  getMyAccessibleFacilities,
  listActiveUsersForActiveFacilities,
  testUserAccess,
} from 'features/user/userActions';
import { logoutUser, setUser, userSlice } from 'features/user/userSlice';
import { Forbidden } from 'Forbidden';
import { useCheckPermission } from 'hooks/useCheckPermission';
import { useUsers } from 'hooks/useUsers';
import { OnError } from 'OnError';
import Assignments from 'Pages/Assignments';
import Charts from 'Pages/Charts';
import { InspectionLoader } from 'Pages/ConductInspection/InspectionLoader';
import Dashboard from 'Pages/Dashboard';
import Home from 'Pages/Home';
import Inspections from 'Pages/Inspections';
import CompletedInspections from 'Pages/Inspections/CompletedInspections';
import ScheduledInspections from 'Pages/Inspections/ScheduledInspections';
import Manage from 'Pages/Manage';
import Organization from 'Pages/Organization';
import Facilities from 'Pages/Organization/Facilities';
import ItemGroups from 'Pages/Organization/Inventory/itemGroups';
import ItemTypes from 'Pages/Organization/Inventory/ItemTypes';
import Users from 'Pages/Organization/Users';
import Tasks from 'Pages/Tasks';
import { CompletedTasks } from 'Pages/Tasks/CompletedTasks';
import { ScheduledTasks } from 'Pages/Tasks/ScheduledTasks';
import { TaskLoader } from 'Pages/Tasks/TaskLoader';
import InspectionsQuestions from 'Pages/Templates/InspectionsQuestions';
import InspectionsTemplates from 'Pages/Templates/InspectionsTemplates';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { useAppDispatch } from 'store/store';
import { loadPendo } from 'telemetry/pendoLoader';
import { isTelemetryEnabled, loadAppInsights } from 'telemetry/service';
import { useInterval, useIsMounted, useReadLocalStorage } from 'usehooks-ts';
import { Permission } from 'utils/permissions';

import tailwindConfig from '../tailwind.config';

export default function App() {
  const { getAccessTokenSilently, isAuthenticated, isLoading, user } =
    useAuth0<DakotaUser>();
  const location = useLocation();
  const pendoLoadedRef = useRef(false);
  const dispatch = useAppDispatch();
  const config = useSelector(configSlice.selectors.config);
  const token = useSelector(tokenSlice.selectors.token);
  const currentUser = useSelector(userSlice.selectors.currentUser);
  const areAccessibleFacilitiesLoaded = useSelector(
    userSlice.selectors.areAccessibleFacilitiesLoaded,
  );

  const canReadItemMetadata = useCheckPermission(Permission.ReadItemMetadata);
  const canReadQuestion = useCheckPermission(Permission.ReadQuestion);
  const canReadForm = useCheckPermission(Permission.ReadForm);

  // Determine which page from /manage should be displayed by default
  const defaultManagePage = useMemo(() => {
    if (canReadItemMetadata) {
      return 'item-groups';
    }
    if (canReadQuestion) {
      return 'inspection-questions';
    }
    if (canReadForm) {
      return 'inspection-templates';
    }
    return '';
  }, [canReadForm, canReadItemMetadata, canReadQuestion]);

  const canCreateFacility = useCheckPermission(Permission.CreateFacility);
  const canManageUsers = useCheckPermission(Permission.ManageUsers);

  // Determine which page from /organization should be displayed by default
  const defaultOrganizationPage = useMemo(() => {
    if (canCreateFacility) {
      return 'facilities';
    }
    if (canManageUsers) {
      return 'users';
    }
    return '';
  }, [canCreateFacility, canManageUsers]);

  const isMounted = useIsMounted();

  const tokenRefreshInterval = 5 * 60 * 1000;

  const hasForbiddenResponse = useSelector(
    userSlice.selectors.hasForbiddenResponse,
  );
  // Use a read-only version of the forbidden state from local storage
  // in case the user tried to refresh the page after being forbidden.
  const isForbiddenLocally = useReadLocalStorage<boolean>('isForbidden');

  const fetchToken = useCallback(async () => {
    const newToken = await getAccessTokenSilently();
    dispatch(tokenSlice.actions.setToken(newToken));
  }, [dispatch, getAccessTokenSilently]);

  const appInsightsCtx = useAppInsightsContext();

  const [isFirstTimeLoad, setIsFirstTimeLoad] = useState(true);

  const { allUserSummaries } = useUsers();

  useEffect(() => {
    const handleResize = () => {
      const isMobile =
        window.innerWidth < parseInt(tailwindConfig.theme.screens.sm, 10);
      dispatch(userSlice.actions.setIsMobile(isMobile));
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [dispatch]);

  useEffect(() => {
    if (!isTelemetryEnabled() && config.appInsights) {
      loadAppInsights(config.appInsights, config.appInfo);
    }
    if (!pendoLoadedRef.current && config.pendo) {
      loadPendo(config.pendo);
      pendoLoadedRef.current = true;
    }
  }, [config.appInfo, config.appInsights, config.pendo]);

  useEffect(() => {
    if (isTelemetryEnabled()) {
      if (isFirstTimeLoad) {
        setIsFirstTimeLoad(false);
        appInsightsCtx
          .getAppInsights()
          .trackPageView({ properties: { IsFirstTimeLoad: true } });
      } else {
        appInsightsCtx.getAppInsights().startTrackPage();
      }
    }
  }, [location, appInsightsCtx, isFirstTimeLoad]);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    const getToken = async () => {
      dispatch(
        tokenSlice.actions.setToken(
          await getAccessTokenSilently({ cacheMode: 'off' }),
        ),
      );
    };

    if (isAuthenticated && !token) {
      getToken().catch((e: unknown) => {
        console.error(e);
      });
      dispatch(setUser({ ...(user as DakotaUser) }));
    } else if (!isAuthenticated) {
      dispatch(logoutUser());
    }
  }, [
    getAccessTokenSilently,
    isAuthenticated,
    isLoading,
    dispatch,
    user,
    token,
  ]);

  useEffect(() => {
    if (token) {
      // Fetch the logged-in user, which is required to show any page
      void dispatch(getCurrentUser({ baseUrl: config.backend, token }));
    }
  }, [config.backend, dispatch, token]);

  useEffect(() => {
    if (token) {
      // By adding this call here, we ensure that the user is always
      // authorized in the backend, meaning that if at any point the user
      // is deactivated and should have no access, we will get a
      // 403/Forbidden response and will be showing the Forbidden page
      // where the user gets logged out.
      void dispatch(testUserAccess({ baseUrl: config.backend, token }));
    }
    // We add `location.pathname` to the dependencies to force a call
    // to `testMyAccess` in the backend every time the user visits a new page.
  }, [config.backend, dispatch, token, location.pathname]);

  useEffect(() => {
    if (isMounted() && token) {
      // Dispatch here any calls that we want to make as soon as the
      // token is available, pre-fetching data to optimize performance.
      void Promise.all([
        dispatch(
          listActiveUsersForActiveFacilities({
            baseUrl: config.backend,
            token,
          }),
        ),
        dispatch(getMyAccessibleFacilities({ baseUrl: config.backend, token })),
        dispatch(getTimeZones({ baseUrl: config.backend, token })),
        dispatch(getAllUsers({ baseUrl: config.backend, token })),
        dispatch(getAllUserSummaries({ baseUrl: config.backend, token })),
      ]);
    }
  }, [config.backend, dispatch, isMounted, token]);

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      void fetchToken();
    }
  }, [location.pathname, fetchToken, isLoading, isAuthenticated]);

  useInterval(() => {
    if (!isLoading && isAuthenticated) {
      void fetchToken();
    }
  }, tokenRefreshInterval);

  // Handle login and logout cases first, as they work regardless of auth0 state
  if (location.pathname === '/login') {
    return <Login />;
  }

  if (location.pathname === '/logout') {
    return <Logout />;
  }

  // If auth0 is loading or the user is authenticated but we have no token yet,
  // show a loading screen. This prevents from rendering components that will
  // attempt to call the backend with an empty token.
  if (isLoading || (isAuthenticated && !token)) {
    return <Loading />;
  }

  // If we know the user is not authenticated, show the landing page with the
  // link to the login page from auth0.
  if (!isAuthenticated) {
    return <Home />;
  }

  if (hasForbiddenResponse || isForbiddenLocally) {
    return <Forbidden />;
  }

  // Don't render anything that requires a user until we have a user
  if (!currentUser.id) {
    return <Loading />;
  }

  // There should always be at least one user in the system,
  // so once these arrays have at least one element, we can render the app
  // and the users will always be available in dropdowns
  if (allUserSummaries.length === 0 || !areAccessibleFacilitiesLoaded) {
    return <Loading />;
  }

  // From this point the user is authenticated and we have a token, so we can
  // render our components and call the backend to fetch data without needing
  // to check for authentication again.
  return (
    <Routes>
      <Route element={<Layout />} path='/'>
        <Route element={<Dashboard />} path='/'>
          <Route element={<Navigate replace to='charts' />} index />
          <Route element={<Charts />} path='charts' />
          <Route element={<Assignments />} path='assignments' />
        </Route>
        <Route element={<Inspections />} path='inspections'>
          <Route element={<Navigate replace to='scheduled' />} index />
          <Route element={<ScheduledInspections />} path='scheduled' />
          <Route element={<CompletedInspections />} path='completed' />
        </Route>
        <Route element={<Tasks />} path='tasks'>
          <Route element={<Navigate replace to='scheduled' />} index />
          <Route
            element={
              <ProtectedRoute permissions={Permission.ReadTasks}>
                <ScheduledTasks />
              </ProtectedRoute>
            }
            path='scheduled'
          />
          <Route
            element={
              <ProtectedRoute permissions={Permission.ReadTasks}>
                <CompletedTasks />
              </ProtectedRoute>
            }
            path='completed'
          />
        </Route>
        <Route
          element={
            <TaskLoader
              source={new URLSearchParams(location.search).get('source')}
            />
          }
          path='tasks/:seriesId/:date'
        />
        <Route
          element={
            <InspectionLoader
              source={new URLSearchParams(location.search).get('source')}
            />
          }
          path='inspections/:id'
        />
        <Route element={<Manage />} path='manage'>
          <Route element={<Navigate replace to={defaultManagePage} />} index />
          <Route
            element={
              <ProtectedRoute permissions={Permission.ReadItemMetadata}>
                <ItemGroups />
              </ProtectedRoute>
            }
            path='item-groups'
          />
          <Route
            element={
              <ProtectedRoute permissions={Permission.ReadItemMetadata}>
                <ItemTypes />
              </ProtectedRoute>
            }
            path='item-types'
          />
          <Route
            element={
              <ProtectedRoute permissions={Permission.ReadQuestion}>
                <InspectionsQuestions />
              </ProtectedRoute>
            }
            path='inspection-questions'
          />
          <Route
            element={
              <ProtectedRoute permissions={Permission.ReadForm}>
                <InspectionsTemplates />
              </ProtectedRoute>
            }
            path='inspection-templates'
          />
        </Route>
        <Route element={<Organization />} path='organization'>
          <Route
            element={<Navigate replace to={defaultOrganizationPage} />}
            index
          />
          <Route
            element={
              <ProtectedRoute permissions={Permission.CreateFacility}>
                <Facilities />
              </ProtectedRoute>
            }
            path='facilities'
          />
          <Route
            element={
              <ProtectedRoute permissions={Permission.ManageUsers}>
                <Users />
              </ProtectedRoute>
            }
            path='users'
          />
        </Route>
      </Route>
      <Route element={<PrintInspection />} path='print-inspection' />
      <Route element={<OnError />} path='*' />
    </Routes>
  );
}
