import {
  Inspection,
  InspectionStatus,
  Summary,
  UserSummary,
} from '@dakota/platform-client';
import { LocalDate } from '@js-joda/core';
import Autocomplete from 'components/Autocomplete';
import Chips from 'components/Chip/Chips';
import { ClearAllButton } from 'components/ClearAll';
import { DatePicker } from 'components/DatePicker';
import InspectionsTable from 'components/Inspections/InspectionsTable';
import { PageHeader } from 'components/PageHeader';
import SearchInput from 'components/SearchInput';
import { inspectionSlice } from 'features/inspections/inspectionSlice';
import { userSlice } from 'features/user/userSlice';
import { useFeatureFlag } from 'hooks/useFeatureFlag';
import { useFilteredInspections } from 'hooks/useFilteredInspections';
import { DataStatus, usePageLoadTracking } from 'hooks/usePageLoadTracking';
import { useUsers } from 'hooks/useUsers';
import { useViewToggle } from 'hooks/useViewToggle';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { FeatureFlag } from 'utils/featureFlags';
import { alphabeticalCompare } from 'utils/functional';
import { getStatusLabel } from 'utils/inspectionStatusLabel';

import { InspectionCard } from '../Card';

const CompletedInspections: React.FC = () => {
  const isMobile = useSelector(userSlice.selectors.isMobile);

  const isTasksEnabled = useFeatureFlag(FeatureFlag.Tasks);

  const navigate = useNavigate();
  const location = useLocation();

  const accessibleFacilities = useSelector(
    userSlice.selectors.accessibleFacilities,
  );
  const selectableFacilities = accessibleFacilities.toSorted(
    alphabeticalCompare((e) => e.name),
  );

  const loadingInspections = useSelector(
    inspectionSlice.selectors.isLoadingInspections,
  );
  const { activeUsersAndUnassigned } = useUsers();

  const { stopTracking } = usePageLoadTracking();

  const { isListView, viewToggleButtons } = useViewToggle(
    'completedInspectionsView',
  );

  const [searchQuery, setSearchQuery] = useState('');
  const [selectedAssignees, setSelectedAssignees] = useState<UserSummary[]>([]);
  const [selectedFacilities, setSelectedFacilities] = useState<Summary[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<InspectionStatus>(
    InspectionStatus.Completed,
  );

  const statuses = useMemo(
    () => [InspectionStatus.Completed, InspectionStatus.Canceled],
    [],
  );

  const {
    dateRange,
    filteredInspections,
    hasFilters,
    hookDataLoaded,
    resetDateRange,
    setDateRange,
  } = useFilteredInspections({
    initialDateRange: {
      begin: LocalDate.now().minusDays(30),
      end: LocalDate.now(),
    },
    initialSelectedStatus: InspectionStatus.Completed,
    searchQuery,
    selectedAssignees,
    selectedFacilities,
    selectedStatus,
    statuses,
  });

  useEffect(() => {
    if (hookDataLoaded) {
      stopTracking(DataStatus.Fetched);
    }
  }, [hookDataLoaded, stopTracking]);

  const removeFacility = (facility: Summary) => {
    setSelectedFacilities((prev) => prev.filter((f) => f.id !== facility.id));
  };

  const removeAssignee = (assignee: UserSummary) => {
    setSelectedAssignees((prev) => prev.filter((f) => f.id !== assignee.id));
  };

  const resetFilters = () => {
    setSearchQuery('');
    setSelectedAssignees([]);
    setSelectedFacilities([]);
    setSelectedStatus(InspectionStatus.Completed);
    resetDateRange();
  };

  const openInspection = (inspection: Inspection) => {
    navigate(`/inspections/${inspection.id}?source=${location.pathname}`);
  };

  return (
    <div className='p-4 sm:p-8'>
      <PageHeader scaffold={['Completed', 'Inspections']} title='Completed' />
      <div className='flex justify-between items-center gap-2'>
        <div className='filters-container'>
          <SearchInput
            data-testid='search-input'
            onSearch={setSearchQuery}
            value={searchQuery}
          />
          <DatePicker
            asSingle={false}
            id='completed-inspections-date'
            onChange={setDateRange}
            placeholder='date range'
            popoverDirection='down'
            showShortcuts
            value={dateRange}
          />
          <Autocomplete
            className='max-sm:w-60 w-40'
            getOptionKey={(option) => option.id}
            getOptionLabel={(option) => option.name}
            id='completed-inspections-facilities-selector'
            label='Facilities'
            multiple
            onChange={setSelectedFacilities}
            options={selectableFacilities}
            value={selectedFacilities}
          />
          <Autocomplete
            className='max-sm:w-60 w-40'
            getOptionKey={(option) => option.id}
            getOptionLabel={(option) => option.name}
            id='completed-inspections-assignee-selector'
            label='Assignee'
            multiple
            onChange={setSelectedAssignees}
            options={activeUsersAndUnassigned}
            value={selectedAssignees}
          />
          <Autocomplete
            className='w-36'
            getOptionLabel={getStatusLabel}
            id='completed-inspections-status-selector'
            label='Status'
            onChange={setSelectedStatus}
            options={statuses}
            value={selectedStatus}
          />
          {hasFilters && <ClearAllButton onClick={resetFilters} />}
        </div>
        {isTasksEnabled && viewToggleButtons}
      </div>
      <Chips
        elements={selectedFacilities}
        getKey={(f) => f.id}
        getLabel={(f) => f.name}
        onRemove={removeFacility}
      />
      <Chips
        elements={selectedAssignees}
        getKey={(f) => f.id}
        getLabel={(f) => f.name}
        onRemove={removeAssignee}
      />
      {isMobile || isListView || !isTasksEnabled ? (
        <InspectionsTable
          data={filteredInspections}
          loading={loadingInspections}
          searchQuery={searchQuery}
          showCompletedDate
        />
      ) : (
        <div className='space-y-4'>
          {filteredInspections.map((inspection) => (
            <InspectionCard
              inspection={inspection}
              key={inspection.id}
              openInspection={() => openInspection(inspection)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default CompletedInspections;
