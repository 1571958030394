import { PlusIcon } from '@heroicons/react/24/outline';
import NewNote from 'components/Note/NewNote';
import { configSlice } from 'features/config/configSlice';
import { taskEditSlice } from 'features/tasks/taskEditSlice';
import { addTaskNote } from 'features/tasks/tasksActions';
import { tokenSlice } from 'features/token/tokenSlice';
import useToast from 'hooks/useToast';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';

import { TaskNote } from './TaskNote';

export const Notes: FC = () => {
  const dispatch = useAppDispatch();
  const dueDate = useSelector(taskEditSlice.selectors.date);
  const seriesId = useSelector(taskEditSlice.selectors.seriesId);
  const note = useSelector(taskEditSlice.selectors.note);
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);
  const notes = useSelector(taskEditSlice.selectors.notes);

  const [addingNote, setAddingNote] = useState(false);
  const [isCreatingNote, setIsCreatingNote] = useState(false);
  const { setErrorMessage, setSuccessMessage } = useToast();

  const createNewNote = (value: string) => {
    setIsCreatingNote(true);
    dispatch(
      addTaskNote({
        baseUrl,
        dueDate,
        note: value,
        seriesId,
        token,
      }),
    )
      .unwrap()
      .then((response) => dispatch(taskEditSlice.actions.addNote(response)))
      .then(() => setSuccessMessage('Note added'))
      .catch(() => setErrorMessage('Failed to create note'))
      .finally(() => {
        setAddingNote(false);
        setIsCreatingNote(false);
      });
  };
  return (
    <div aria-label='Notes Container' className='flex flex-col gap-4'>
      <div className='text-base font-bold'>Notes</div>
      {notes.length > 0 && (
        <div className='flex flex-col gap-2'>
          {notes.map((n, noteIndex) => (
            <TaskNote
              dueDate={dueDate}
              key={n.id}
              note={n}
              noteIndex={noteIndex}
              seriesId={seriesId}
            />
          ))}
        </div>
      )}
      {addingNote && (
        <NewNote
          deletable={true}
          editOnMount
          id='task-new-note'
          loading={isCreatingNote}
          maxNoteLength={1000}
          note={note || 'Enter Note'}
          onChange={createNewNote}
          removeNote={() => setAddingNote(false)}
        />
      )}
      <button
        className='flex gap-2 items-center text-green-base text-sm'
        onClick={() => setAddingNote(true)}
      >
        <PlusIcon className='w-5' /> New Entry
      </button>
    </div>
  );
};
