import { TaskSeries } from '@dakota/platform-client';
import { Dialog } from '@mui/material';
import Button from 'components/Button';
import { PageHeader } from 'components/PageHeader';
import Confirmation from 'components/SimpleConfirmation';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { EditSeriesChoices } from '../types';
import { Header } from './layout/header';
import { SeriesDetails } from './layout/seriesDetails';

type EditSeriesProps = {
  /**
   * The option to edit all events or this event and following events.
   */
  editOption: EditSeriesChoices;
  /**
   * Callback to close the dialog.
   */
  onClose: () => void;
  /**
   * The task series to edit.
   */
  task: TaskSeries;
};

export const EditSeries: FC<EditSeriesProps> = ({
  editOption,
  onClose,
  task,
}) => {
  const navigate = useNavigate();
  const [hasChanges, setHasChanges] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const attemptClose = () => {
    if (hasChanges) {
      setShowConfirmation(true);
    } else {
      onClose();
    }
  };

  const confirmClose = () => {
    setShowConfirmation(false);
    onClose();
  };

  const onSave = () => {
    // TODO: Add save logic
    navigate('/tasks');
  };

  return (
    <Dialog
      data-testid='edit-series'
      disableEscapeKeyDown
      fullWidth
      onClose={attemptClose}
      open
    >
      <PageHeader scaffold={['Edit Series', 'Tasks']} />
      <Header
        onClose={attemptClose}
        task={task}
        title={`Edit ${task.title} Series Details`}
      />
      <div className='flex-1 overflow-y-auto'>
        <SeriesDetails
          editOption={editOption}
          onFieldChange={setHasChanges}
          task={task}
        />
      </div>
      <div className='flex items-center h-14 border-t p-4 gap-2 text-sm bg-gray-100'>
        <Button
          disabled={!hasChanges}
          id='save-edit-series'
          loading={false}
          onClick={onSave}
        >
          Save
        </Button>
        <Button id='cancel-edit-series' onClick={attemptClose} secondary>
          Cancel
        </Button>
      </div>
      {showConfirmation && (
        <Confirmation
          cancelText='Return to form'
          confirmText='Discard'
          onCancel={() => setShowConfirmation(false)}
          onConfirm={confirmClose}
          title='Discard Changes?'
        >
          You have unsaved changes. Are you sure you want to discard them?
        </Confirmation>
      )}
    </Dialog>
  );
};
