import { UserProfile } from '@dakota/platform-client';
import { Avatar } from '@mui/material';
import { getRgbColorById } from 'components/Avatar/color';
import { FC } from 'react';

type Props = {
  user: UserProfile;
};

export const UserAvatar: FC<Props> = ({ user }: Props) => {
  const first = user.firstName;
  const last = user.lastName;
  const avatarInitials =
    first && last ? `${first.charAt(0)}${last.charAt(0)}` : '';

  return (
    <Avatar aria-label='User avatar' sx={{ bgcolor: getRgbColorById(user.id) }}>
      {avatarInitials}
    </Avatar>
  );
};
