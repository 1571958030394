import {
  Inspection,
  InspectionStatus,
  TaskInstance,
} from '@dakota/platform-client';
import { formatBackendDate } from 'utils/date';

import { Notification, NotificationElement } from './types';

export const overdueInspectionToNotification = (
  inspection: Inspection,
): Notification => ({
  cta: { link: `/inspections/${inspection.id}`, text: 'Go to Inspection' },
  key: inspection.id,
  message: (
    <>
      Your inspection <strong>{inspection.form.name}</strong> is overdue. It was
      scheduled for {formatBackendDate(inspection.timeline.scheduledDate)}.
    </>
  ),
  severity: 'warning',
});

export const scheduledInspectionToNotification = (
  inspection: Inspection,
): Notification => ({
  cta: { link: `/inspections/${inspection.id}`, text: 'Go to Inspection' },
  key: inspection.id,
  message: (
    <>
      Your inspection <strong>{inspection.form.name}</strong> is due today.
    </>
  ),
});

export const overdueTaskToNotification = (
  task: TaskInstance,
): Notification => ({
  cta: {
    link: `/tasks/${task.seriesId}/${task.timeline.scheduledDate}`,
    text: 'Go to Task',
  },
  key: task.id,
  message: (
    <>
      Your task <strong>{task.title}</strong> is overdue. It was scheduled for{' '}
      {formatBackendDate(task.timeline.scheduledDate)}.
    </>
  ),
  severity: 'warning',
});

export const scheduledTaskToNotification = (
  task: TaskInstance,
): Notification => ({
  cta: {
    link: `/tasks/${task.seriesId}/${task.timeline.scheduledDate}`,
    text: 'Go to Task',
  },
  key: task.id,
  message: (
    <>
      Your task <strong>{task.title}</strong> is due today.
    </>
  ),
});

export const elementToNotification = (element: NotificationElement) => {
  if (element instanceof Inspection) {
    if (element.status === InspectionStatus.Overdue) {
      return overdueInspectionToNotification(element);
    } else {
      return scheduledInspectionToNotification(element);
    }
  }
  if (element.overdue) {
    return overdueTaskToNotification(element);
  } else {
    return scheduledTaskToNotification(element);
  }
};
