import { Inspection, Summary } from '@dakota/platform-client';
import { TrashIcon } from '@heroicons/react/24/outline';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Slide } from '@mui/material';
import { clsx } from 'clsx';
import { zonesSlice } from 'features/zones/zonesSlice';
import { FC, useEffect } from 'react';
import { DragDropContext, Draggable, DropResult } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import resolveConfig from 'tailwindcss/resolveConfig';
import { StrictModeDroppable } from 'utils/StrictModeDroppable';

import tailwindConfig from '../../../tailwind.config';

const { theme } = resolveConfig(tailwindConfig);

type ReorderZonesProps = {
  inspection?: Inspection;
};

const ReorderZones: FC<ReorderZonesProps> = ({ inspection }) => {
  const dispatch = useAppDispatch();
  const orderedZones = useSelector(zonesSlice.selectors.orderedZones);

  useEffect(() => {
    // set the ordered zones state to the zones from the inspection
    if (inspection) {
      dispatch(zonesSlice.actions.setZones(inspection.zones));
    }
  }, [inspection, dispatch]);

  const moveZone = (onEnd: DropResult) => {
    const fromIndex = onEnd.source.index;
    const toIndex = onEnd.destination?.index;
    if (toIndex === undefined) {
      return;
    }

    dispatch(zonesSlice.actions.moveZone({ fromIndex, toIndex }));
  };

  const handleDeleteZone = (zone: Summary) =>
    dispatch(zonesSlice.actions.deleteZone(zone));

  const renderDraggable = (zone: Summary, index: number) => {
    return (
      <Draggable draggableId={zone.id} index={index} key={zone.id}>
        {(provided, snapshot) => (
          <div
            data-testid='draggable'
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={clsx(
              'p-2 border rounded flex items-center justify-between',
              snapshot.isDragging ? 'bg-gray-100' : '',
              snapshot.dropAnimation ? 'bg-green-lightest' : '',
            )}
            ref={provided.innerRef}
          >
            <div className={clsx('flex items-center gap-2')} key={zone.id}>
              <DragIndicatorIcon sx={{ color: theme.colors.gray[400] }} />
              {zone.name}
            </div>
            <button
              aria-label='Delete zone'
              className='self-center'
              data-testid='delete-icon'
              onClick={() => handleDeleteZone(zone)}
            >
              <TrashIcon className='w-5 h-5' />
            </button>
          </div>
        )}
      </Draggable>
    );
  };

  return (
    <Slide direction='right' in={orderedZones.length >= 2} unmountOnExit>
      <div
        className='min-w-96 text-gray-700 text-lg pl-4'
        data-testid='reorder-zones-container'
        id='reorder-zones-container'
      >
        <div className={clsx('mb-2 font-sans text-base text-gray-700')}>
          Reorder Zones
          <div className='text-sm text-gray-400'>
            Drag and drop to change the order of zones in your template.
          </div>
        </div>
        <div>
          <DragDropContext onDragEnd={moveZone}>
            <StrictModeDroppable droppableId='zones'>
              {(droppableProvided) => (
                <div
                  {...droppableProvided.droppableProps}
                  className='flex flex-col gap-1 select-none'
                  ref={droppableProvided.innerRef}
                >
                  {orderedZones.map(renderDraggable)}
                  {droppableProvided.placeholder}
                </div>
              )}
            </StrictModeDroppable>
          </DragDropContext>
        </div>
      </div>
    </Slide>
  );
};

export default ReorderZones;
