import {
  Inspection,
  InspectionStatus,
  TaskInstance,
} from '@dakota/platform-client';
import { LocalDate } from '@js-joda/core';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  cancelInspection,
  createInspection,
  startInspection,
  updateInspection,
} from 'features/inspections/inspectionActions';
import { ReactNode } from 'react';

import {
  getScheduledInspections,
  getScheduledTasks,
} from './notificationsActions';

export type StickyMessageAction = {
  onClick: () => void;
  text: string;
};

export type StickyMessageType = {
  /**
   * One or more actions that the user can take in response to the message.
   * These actions are displayed as buttons with the provided text. The last
   * action is always a 'Dismiss' button with an 'X' icon that simply makes the
   * message disappear, and such action must not be passed or considered here.
   */
  actions: StickyMessageAction[];
  /**
   * The message to display.
   */
  content: ReactNode;
  /**
   * A unique identifier for the message. This is used to remove the message
   * without affecting other sticky messages that might be present at the same
   * time.
   */
  id: string;
};

/**
 * A request to add a sticky message to the UI. It includes the message and
 * actions, given that the ID is generated automatically.
 */
export type StickyMessageRequest = Pick<
  StickyMessageType,
  'actions' | 'content'
>;

export type NotificationsState = {
  /**
   * The inspections that are in 'Scheduled' or 'Overdue' status
   * and are assigned to the current user.
   */
  inspections: Inspection[];
  /**
   * A list of sticky messages that are displayed at the top of the screen
   * and only disappear once the user dismisses them.
   */
  stickyMessages: StickyMessageType[];
  /**
   * The tasks that are in 'Scheduled' or 'Overdue' status
   * and are assigned to the current user.
   */
  tasks: TaskInstance[];
  /**
   * The total number of sticky messages that have been displayed so far.
   */
  totalStickyMessages: number;
};

const initialState: NotificationsState = {
  inspections: [],
  stickyMessages: [],
  tasks: [],
  totalStickyMessages: 0,
};

export const notificationsSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(getScheduledTasks.fulfilled, (state, action) => {
      state.tasks = action.payload;
    });
    builder.addCase(getScheduledInspections.fulfilled, (state, action) => {
      state.inspections = action.payload;
    });
    builder.addCase(createInspection.fulfilled, (state, action) => {
      const inspection = action.payload;
      if (
        !LocalDate.parse(inspection.timeline.scheduledDate).isAfter(
          LocalDate.now(),
        ) &&
        action.meta.arg.currentUserId === inspection.userId
      ) {
        state.inspections.push(inspection);
      }
    });
    builder.addCase(updateInspection.fulfilled, (state, action) => {
      const inspection = action.payload;
      if (
        !LocalDate.parse(inspection.timeline.scheduledDate).isAfter(
          LocalDate.now(),
        ) &&
        (inspection.status === InspectionStatus.Scheduled ||
          inspection.status === InspectionStatus.Overdue) &&
        action.meta.arg.currentUserId === inspection.userId
      ) {
        state.inspections.push(inspection);
      } else {
        // Remove the inspection from the list if it's not scheduled or overdue
        state.inspections = state.inspections.filter(
          (i) => i.id !== inspection.id,
        );
      }
    });
    builder.addCase(startInspection.fulfilled, (state, action) => {
      // Unconditionally remove an inspection that gets started
      const { id } = action.meta.arg;
      state.inspections = state.inspections.filter((i) => i.id !== id);
    });
    builder.addCase(cancelInspection.fulfilled, (state, action) => {
      // Unconditionally remove an inspection that gets canceled
      const { id } = action.meta.arg;
      state.inspections = state.inspections.filter((i) => i.id !== id);
    });
  },
  initialState,
  name: 'notifications',
  reducers: {
    addStickyMessage: (state, action: PayloadAction<StickyMessageRequest>) => {
      state.totalStickyMessages += 1;
      state.stickyMessages.push({
        ...action.payload,
        id: `sticky-message-${state.totalStickyMessages.toString()}`,
      });
    },
    removeStickyMessage: (state, action) => {
      state.stickyMessages = state.stickyMessages.filter(
        (m) => m.id !== action.payload,
      );
    },
  },
  selectors: {
    pendingInspections: (state) => state.inspections,
    pendingTasks: (state) => state.tasks,
    stickyMessages: (state) => state.stickyMessages,
  },
});
