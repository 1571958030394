import { FormSummary } from '@dakota/platform-client';
import { ClipboardDocumentCheckIcon } from '@heroicons/react/24/outline';
import Autocomplete from 'components/Autocomplete';
import { configSlice } from 'features/config/configSlice';
import { getAllFormSummaries } from 'features/forms/formsActions';
import { formsSlice } from 'features/forms/formsSlice';
import { tokenSlice } from 'features/token/tokenSlice';
import { userSlice } from 'features/user/userSlice';
import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { alphabeticalCompare } from 'utils/functional';

const TemplateChoice: FC<{
  onChange: (form: FormSummary) => void;
  template: FormSummary | null;
}> = ({ onChange, template }) => {
  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);

  const forms = useSelector(formsSlice.selectors.forms);
  const isLoadingForms = useSelector(formsSlice.selectors.isLoadingForms);

  const userFacilities = useSelector(
    userSlice.selectors.accessibleFacilities,
  ).map((f) => f.id);

  // We only offer the user the templates that are global or that are
  // made for facilities the user has access to. The template has to be
  // active, and we sort them alphabetically by name for the dropdown.
  const selectableForms = forms
    .filter((f) => !f.inactive)
    .filter((f) => !f.facility || userFacilities.includes(f.facility.id))
    .toSorted(alphabeticalCompare((e) => e.name));

  useEffect(() => {
    void dispatch(getAllFormSummaries({ baseUrl, token }));
  }, [dispatch, token, baseUrl]);

  return (
    <div className='flex flex-col h-72 items-center justify-center gap-0.5'>
      <ClipboardDocumentCheckIcon className='w-12 h-12 text-green-base mb-4' />
      <div className='text-sm text-black'>Select a Template</div>
      <div className='text-xs leading-5 text-gray-400'>
        Select the template to use as a starting point for this new one.
      </div>
      <Autocomplete
        className='mt-4 w-96'
        getOptionKey={(option) => option.id}
        getOptionLabel={(option) => option.name}
        id='template-selector'
        loading={isLoadingForms}
        maxListHeight={250}
        onChange={onChange}
        options={selectableForms}
        value={template}
      />
    </div>
  );
};

export default TemplateChoice;
