import { CtxTagKeys } from '@microsoft/applicationinsights-common';
import { IDependencyInitializerDetails } from '@microsoft/applicationinsights-dependencies-js';
import {
  ICustomProperties,
  ITelemetryItem,
  Tags,
} from '@microsoft/applicationinsights-web';
import { AppInfo } from 'features/config/types';

import { CaseInsensitiveSet } from './Sets';
import { IdentityData, TelemetryData } from './telemetryKeys';
import { UserTracker } from './userTracker';

/* This telemetry initializer adds application/environment specific properties
 * to all of the telemetry messages
 */
export function createAddAppTelemetry(appInfo: AppInfo) {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const { environment, name, version } = appInfo || {};
  const azEnvironment = environmentToAzureEnvironment(environment);

  return (item: ITelemetryItem) => {
    item.data ??= {} as ICustomProperties;

    if (name) {
      item.data[TelemetryData.Application] = name;
    }
    if (environment) {
      item.data[TelemetryData.Environment] = environment;

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      item.data[TelemetryData.AzureEnvironment] = azEnvironment!;
    }
    if (version) {
      item.data[TelemetryData.Version] = version;
    }
  };
}

export function addUserTelemetry(item: ITelemetryItem) {
  if (!UserTracker.isValid) {
    return;
  }

  item.data ??= {} as ICustomProperties;
  item.tags ??= {} as Tags & Tags[];

  const { orgId, orgName, userId, userName } = UserTracker;

  // if OrgId is available, store in custom property
  // as well as in the "AccountId" (Tenant) tag
  if (orgId) {
    item.data[IdentityData.OrgId] = orgId;
    item.tags[CtxTagKeys.userAccountId] = orgId;
  }

  // OrgName is only interesting as a custom property
  if (orgName) {
    item.data[IdentityData.OrgName] = orgName;
  }

  // for User@Name
  // store as a custom property as well as
  // the userAuthUserId (login) tag and extra user data if available
  if (userName) {
    item.data[IdentityData.UserName] = userName;
    item.tags[CtxTagKeys.userAuthUserId] = userName;
    if (item.ext?.user) {
      (item.ext.user as { authId: string }).authId = userName;
    }
  }

  // set UserId (Our identifier) in custom property
  // and use as a fallback for Login Id if it's not there somehow
  if (userId) {
    item.data[IdentityData.UserId] = userId;
    if (!item.tags[CtxTagKeys.userAuthUserId]) {
      item.tags[CtxTagKeys.userAuthUserId] = userId;
    }
  }
}

// returns a function that drops all telemetry if it's a successful call to any
// of the urls in the provided list. we do this mainly for our config.json file
// since we don't want this flooding AI as it's going to be requested on a short
// interval
export function createdropRequestTelemetry(...urls: readonly string[]) {
  const values = urls
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    ?.map((url) => url?.replace(/^[\\/]+/, ''))
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    ?.filter((url) => !!url);

  const localUrls = new CaseInsensitiveSet(values);

  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  return function (dets: IDependencyInitializerDetails): boolean | void {
    // ajax request was aborted or we don't have item/file info
    // return void to continue processing

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (dets.aborted || !dets.item || !localUrls.size) {
      return;
    }

    // if unsuccessful, return void to continue processing
    // if we don't have URL or type then do the same
    // always process unsuccessful requests even if in our list
    const { success, target, type } = dets.item;
    if (!success || !target || (type !== 'Ajax' && type !== 'AJAX')) {
      return;
    }

    // not in the set, return void to continue
    if (!localUrls.has(target)) {
      return;
    }

    // in our set, return false to abort processing
    return false;
  };
}

function environmentToAzureEnvironment(envName?: null | string): null | string {
  if (!envName) {
    return null;
  }
  switch (envName.toLowerCase()) {
    case 'development':
      return 'dev';
    case 'qa':
      return 'qa';
    case 'staging':
      return 'uat';
    case 'production':
      return 'prod';
    case 'localdevelopment':
      return 'localdev';
    default:
      return envName.toLowerCase();
  }
}
