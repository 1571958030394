import { LocalDate } from '@js-joda/core';
import { DatePicker } from 'components/DatePicker';
import { taskEditSlice } from 'features/tasks/taskEditSlice';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';

export const DueDate: FC = () => {
  const dispatch = useAppDispatch();

  const date = useSelector(taskEditSlice.selectors.date);

  return (
    <div className='flex'>
      <label className='flex-1' htmlFor='task-date'>
        Due Date
      </label>
      <DatePicker
        asSingle
        id='task-date'
        onChange={(newDate) =>
          dispatch(
            taskEditSlice.actions.setTaskField({
              field: 'date',
              value: newDate.toString(),
            }),
          )
        }
        value={LocalDate.parse(date)}
      />
    </div>
  );
};
