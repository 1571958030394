import { DatePickerRange } from 'components/DatePicker';
import { useCallback, useMemo, useState } from 'react';

export const useDateRange = (initialDateRange: DatePickerRange) => {
  const initialBeginDate = useMemo(
    () => initialDateRange.begin,
    [initialDateRange.begin],
  );
  const initialEndDate = useMemo(
    () => initialDateRange.end,
    [initialDateRange.end],
  );

  const [dateRange, setDateRange] = useState<DatePickerRange>({
    begin: initialBeginDate,
    end: initialEndDate,
  });

  const resetDateRange = useCallback(() => {
    setDateRange({ begin: initialBeginDate, end: initialEndDate });
  }, [initialBeginDate, initialEndDate]);

  return {
    dateRange,
    initialBeginDate,
    initialEndDate,
    resetDateRange,
    setDateRange,
  };
};
