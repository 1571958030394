import { ViewToggle } from 'components/ViewToggle';
import { useMemo } from 'react';
import { useSessionStorage } from 'usehooks-ts';

export const useViewToggle = (key: string) => {
  const [isListView, setIsListView] = useSessionStorage(key, false);

  const viewToggleButtons = useMemo(() => {
    return <ViewToggle listView={isListView} setListView={setIsListView} />;
  }, [isListView, setIsListView]);

  return { isListView, viewToggleButtons };
};
