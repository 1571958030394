import { Facility, Summary, Zone } from '@dakota/platform-client';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import dotProp from 'dot-prop-immutable';
import {
  createZone,
  deactivateZone,
  reactivateZone,
  updateZone,
} from 'features/zones/zonesActions';
import { alphabeticalCompare } from 'utils/functional';

import {
  activateFacility,
  addFacility,
  deactivateFacility,
  deleteFacility,
  getActiveOrgFacilities,
  getAllFacilities,
  updateFacility,
} from './facilitiesActions';

export type FacilityState = {
  /**
   * Returns an organization-wide listing of summary data for active facilities
   * only.
   */
  activeOrgFacilities: Summary[];
  /**
   * Returns a list of all accessible facilities for the user.
   */
  allFacilities: Facility[];
  /**
   * True when fetching active organization facilities.
   */
  isLoadingActiveOrgFacilities: boolean;
  /**
   * True when fetching all facilities (client.listFacilities()).
   */
  isLoadingAllFacilities: boolean;
};

const initialState: FacilityState = {
  activeOrgFacilities: [],
  allFacilities: [],
  isLoadingActiveOrgFacilities: false,
  isLoadingAllFacilities: false,
};

export const facilitiesSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(getAllFacilities.pending, (state) => {
      state.isLoadingAllFacilities = true;
    });
    builder.addCase(getAllFacilities.fulfilled, (state, action) => {
      state.allFacilities = action.payload;
      state.isLoadingAllFacilities = false;
    });
    builder.addCase(addFacility.fulfilled, (state, action) => {
      state.allFacilities.push(action.payload);
    });
    builder.addCase(updateFacility.fulfilled, (state, action) => {
      state.allFacilities = state.allFacilities.map((f) =>
        f.id === action.payload.id ? action.payload : f,
      );
      state.activeOrgFacilities = state.activeOrgFacilities.map((f) =>
        f.id === action.payload.id ? action.payload : f,
      );
    });
    builder.addCase(deleteFacility.fulfilled, (state, action) => {
      state.allFacilities = state.allFacilities.filter(
        (f) => f.id !== action.payload,
      );
      state.activeOrgFacilities = state.activeOrgFacilities.filter(
        (f) => f.id !== action.payload,
      );
    });
    builder.addCase(activateFacility.fulfilled, (state, action) => {
      const facilityId = action.meta.arg.id;
      state.allFacilities = state.allFacilities.map((f) =>
        f.id === facilityId ? { ...f, inactive: false } : f,
      );
      state.activeOrgFacilities = state.activeOrgFacilities.map((f) =>
        f.id === facilityId ? { ...f, inactive: false } : f,
      );
    });
    builder.addCase(deactivateFacility.fulfilled, (state, action) => {
      state.allFacilities = state.allFacilities.map((f) =>
        f.id === action.payload ? { ...f, inactive: true } : f,
      );
      state.activeOrgFacilities = state.activeOrgFacilities.map((f) =>
        f.id === action.payload ? { ...f, inactive: true } : f,
      );
    });
    builder.addCase(getActiveOrgFacilities.fulfilled, (state, action) => {
      state.isLoadingActiveOrgFacilities = false;
      state.activeOrgFacilities = action.payload;
    });
    builder.addCase(getActiveOrgFacilities.pending, (state) => {
      state.isLoadingActiveOrgFacilities = true;
    });
    builder.addCase(getActiveOrgFacilities.rejected, (state) => {
      state.isLoadingActiveOrgFacilities = false;
    });
    builder.addCase(createZone.fulfilled, (state, action) => {
      const { facilityId, zone } = action.payload;
      const facilityIndex = state.allFacilities.findIndex(
        (f) => f.id === facilityId,
      );
      if (facilityIndex !== -1) {
        state.allFacilities = dotProp.set(
          state.allFacilities,
          `${facilityIndex}.zones`,
          (zones: Zone[]) => [...zones, zone],
        );
      }
    });
    builder.addCase(updateZone.fulfilled, (state, action) => {
      const { facilityId, zone } = action.payload;
      const facilityIndex = state.allFacilities.findIndex(
        (f) => f.id === facilityId,
      );
      if (facilityIndex !== -1) {
        const zoneIndex = state.allFacilities[facilityIndex].zones.findIndex(
          (z) => z.id === zone.id,
        );
        if (zoneIndex !== -1) {
          state.allFacilities = dotProp.set(
            state.allFacilities,
            `${facilityIndex}.zones.${zoneIndex}`,
            zone,
          );
        }
      }
    });
    builder.addCase(deactivateZone.fulfilled, (state, action) => {
      const { facilityId, id } = action.meta.arg;
      const facilityIndex = state.allFacilities.findIndex(
        (f) => f.id === facilityId,
      );
      if (facilityIndex !== -1) {
        const zoneIndex = state.allFacilities[facilityIndex].zones.findIndex(
          (z) => z.id === id,
        );
        if (zoneIndex !== -1) {
          state.allFacilities = dotProp.set(
            state.allFacilities,
            `${facilityIndex}.zones.${zoneIndex}.inactive`,
            true,
          );
        }
      }
    });
    builder.addCase(reactivateZone.fulfilled, (state, action) => {
      const { facilityId, id } = action.meta.arg;
      const facilityIndex = state.allFacilities.findIndex(
        (f) => f.id === facilityId,
      );
      if (facilityIndex !== -1) {
        const zoneIndex = state.allFacilities[facilityIndex].zones.findIndex(
          (z) => z.id === id,
        );
        if (zoneIndex !== -1) {
          state.allFacilities = dotProp.set(
            state.allFacilities,
            `${facilityIndex}.zones.${zoneIndex}.inactive`,
            false,
          );
        }
      }
    });
  },
  initialState,
  name: 'facilities',
  reducers: {},
  selectors: {
    activeFacilities: createSelector(
      (state: FacilityState) => state.allFacilities,
      (facilities) =>
        facilities
          .filter((f) => !f.inactive)
          .toSorted(alphabeticalCompare((e) => e.name)),
    ),
    allFacilities: (state: FacilityState) => state.allFacilities,
    getActiveOrgFacilities: (state: FacilityState) => state.activeOrgFacilities,
    isLoadingActiveOrgFacilities: (state: FacilityState) =>
      state.isLoadingActiveOrgFacilities,
    isLoadingAllFacilities: (state: FacilityState) =>
      state.isLoadingAllFacilities,
  },
});
