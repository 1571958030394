import { Zone } from '@dakota/platform-client';
import { configSlice } from 'features/config/configSlice';
import { tokenSlice } from 'features/token/tokenSlice';
import { createZone, updateZone } from 'features/zones/zonesActions';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { useImmer } from 'use-immer';

import { useUsers } from './useUsers';
import { useZones } from './useZones';

export const useZone = (facilityId: string) => {
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);
  const dispatch = useAppDispatch();

  const { getActiveUserSummary } = useUsers();

  const { getFacilityZones } = useZones();

  const [addedZones, updateAddedZones] = useImmer<Zone[]>([]);
  const [editedZones, updateEditedZones] = useImmer<Zone[]>([]);
  const [nextZoneId, setNextZoneId] = useState(1);

  const facilityZones = getFacilityZones(facilityId);

  const existingZoneNames = facilityZones.map((zone) => zone.name);

  const canCreateZones =
    addedZones.length > 0 &&
    addedZones.every((zone, index, self) => {
      const name = zone.name.trim();
      return (
        name !== '' &&
        !existingZoneNames.includes(name) &&
        self.findIndex((z) => z.name.trim() === name) === index
      );
    });

  const canEditZones =
    editedZones.length > 0 &&
    editedZones.every((zone) => {
      const name = zone.name.trim();
      const originalZone = facilityZones.find((z) => z.id === zone.id);

      if (!originalZone) {
        return false;
      }

      const isNameChanged =
        name !== '' &&
        name !== originalZone.name &&
        !existingZoneNames.includes(name);
      const isContactChanged = zone.contact?.id !== originalZone.contact?.id;

      return isNameChanged || isContactChanged;
    });

  const addZone = () => {
    const newZone = {
      contact: undefined,
      id: `zone-${nextZoneId}`,
      inactive: false,
      name: '',
    } as Zone;
    updateAddedZones((prev) => [...prev, newZone]);
    setNextZoneId((prev) => prev + 1);
  };

  const removeZone = (index: number) => {
    updateAddedZones((draft) => {
      draft.splice(index, 1);
    });
  };

  /**
   * This function updates the details of a newly added zone in the addedZones
   * state. It directly modifies the name and contact of the zone at the
   * specified index. - The function locates the zone by its index in the
   * addedZones array.
   * - It updates the zone's name and contact information based on the provided
   * data.
   */
  const updateNewZone = (
    index: number,
    data: { contactId?: string; name: string },
  ) => {
    updateAddedZones((draft) => {
      draft[index].name = data.name;
      draft[index].contact = getActiveUserSummary(data.contactId);
    });
  };

  /**
   * This function updates the details of a zone in the editedZones state.
   * It first checks if the zone already exists in the editedZones array.
   * - If the zone exists, it updates the zone's name and contact.
   * - If the zone does not exist in editedZones, it finds the original zone
   *   from facilityZones, creates a new updated zone object with the new data,
   *   and adds it to editedZones.
   */
  const updateExistingZone = (
    zoneId: string,
    data: { contactId?: string; name: string },
  ) => {
    updateEditedZones((draft) => {
      const zoneToUpdate = draft.find((zone) => zone.id === zoneId);

      if (zoneToUpdate) {
        zoneToUpdate.name = data.name;
        zoneToUpdate.contact = getActiveUserSummary(data.contactId);
      } else {
        const originalZone = facilityZones.find((zone) => zone.id === zoneId);
        if (originalZone) {
          draft.push(
            Zone.fromJS({
              ...originalZone,
              contact: getActiveUserSummary(data.contactId),
              name: data.name,
            }),
          );
        }
      }
    });
  };

  const createZones = () => {
    if (facilityId) {
      const createPromises = addedZones.map((zone) => {
        const { contact, inactive, name } = zone;
        return dispatch(
          createZone({
            baseUrl,
            body: { contact, inactive, name } as Zone,
            facilityId,
            token,
          }),
        ).unwrap();
      });
      updateAddedZones([]);
      return Promise.all(createPromises);
    }
  };

  const updateExistingZones = () => {
    if (facilityId) {
      const updatePromises = editedZones.map((zone) => {
        return dispatch(
          updateZone({
            baseUrl,
            body: zone,
            facilityId,
            id: zone.id,
            token,
          }),
        ).unwrap();
      });
      return Promise.all(updatePromises);
    }
  };

  return {
    addedZones,
    addZone,
    canCreateZones,
    canEditZones,
    createZones,
    facilityZones,
    removeZone,
    updateExistingZone,
    updateExistingZones,
    updateNewZone,
  };
};
