import { Prompt } from '@dakota/platform-client';
import { FC } from 'react';

import { Note } from './Note';

type NotesProps = {
  prompt: Prompt;
  promptIndex: number;
  sectionIndex: number;
};

export const Notes: FC<NotesProps> = ({
  prompt,
  promptIndex,
  sectionIndex,
}) => {
  return prompt.comments.length > 0 ? (
    <div className='flex flex-col gap-2 pt-2'>
      {prompt.comments.map((note, noteIndex) => (
        <Note
          {...{ noteIndex, promptIndex, sectionIndex }}
          key={note.id}
          note={note}
        />
      ))}
    </div>
  ) : null;
};
