import { useCallback, useEffect, useState } from 'react';

/**
 * Hook that triggers a callback function when a long press is detected.
 *
 * @param callback The function to be called when the long press is triggered.
 * @param delay The time in milliseconds before the long press is triggered.
 * @param interval The time in milliseconds between each call of the callback function.
 * @param runOnClick Whether the callback function should be called on the first click.
 * @returns The event handlers to be used in the component.
 */
export default function useLongPress(
  callback: () => void,
  delay = 500,
  interval = 100,
  runOnClick = true,
) {
  const [isClickActive, setIsClickActive] = useState(false);
  const [isInInterval, setIsInInterval] = useState(false);
  const [isInitialRunDone, setIsInitialRunDone] = useState(false);

  const runOnce = useCallback(() => {
    if (runOnClick && !isInitialRunDone) {
      callback();
      setIsInitialRunDone(true);
    }
  }, [callback, isInitialRunDone, runOnClick]);

  useEffect(() => {
    let timerId: NodeJS.Timeout | undefined;

    if (isClickActive) {
      runOnce();
      timerId = setTimeout(() => {
        // Call the first time after the delay,
        // then subsequently with the interval
        callback();
        setIsInInterval(true);
      }, delay);
    } else {
      clearTimeout(timerId);
      setIsInInterval(false);
      setIsInitialRunDone(false);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [callback, delay, isClickActive, runOnce]);

  useEffect(() => {
    if (isInInterval) {
      const intervalId = setInterval(callback, interval);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [callback, isInInterval, interval]);

  return {
    onClick: () => runOnce(),
    onMouseDown: () => setIsClickActive(true),
    onMouseLeave: () => setIsClickActive(false),
    onMouseUp: () => setIsClickActive(false),
    onTouchEnd: () => setIsClickActive(false),
    onTouchStart: () => setIsClickActive(true),
  };
}
