import {
  SearchStatus,
  Summary,
  TaskInstance,
  UserSummary,
} from '@dakota/platform-client';
import { LocalDate } from '@js-joda/core';
import Skeleton from '@mui/material/Skeleton';
import Autocomplete from 'components/Autocomplete';
import { ClearAllButton } from 'components/ClearAll';
import { DatePicker } from 'components/DatePicker';
import { PageHeader } from 'components/PageHeader';
import SearchInput from 'components/SearchInput';
import { tasksSlice } from 'features/tasks/tasksSlice';
import { userSlice } from 'features/user/userSlice';
import { useFilteredTasks } from 'hooks/useFilteredTasks';
import { DataStatus, usePageLoadTracking } from 'hooks/usePageLoadTracking';
import { useUsers } from 'hooks/useUsers';
import { useViewToggle } from 'hooks/useViewToggle';
import { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { TaskCard } from '../Card';
import TasksTable from '../TasksTable';
import { getSearchStatusLabel } from '../types';

export const CompletedTasks: FC = () => {
  const navigate = useNavigate();

  const accessibleFacilities = useSelector(
    userSlice.selectors.accessibleFacilities,
  );

  const loadingTasks = useSelector(tasksSlice.selectors.isLoadingTasks);
  const { activeUsersAndUnassigned } = useUsers();
  const { stopTracking } = usePageLoadTracking();

  const { isListView, viewToggleButtons } = useViewToggle('completedTasksView');

  const [searchQuery, setSearchQuery] = useState('');
  const [selectedAssignees, setSelectedAssignees] = useState<UserSummary[]>([]);
  const [selectedFacilities, setSelectedFacilities] = useState<Summary[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<SearchStatus>(
    SearchStatus.Completed,
  );

  const statuses = useMemo(
    () => [SearchStatus.Completed, SearchStatus.Canceled],
    [],
  );

  const {
    dateRange,
    filteredTasks,
    hasFilters,
    hookDataLoaded,
    resetDateRange,
    setDateRange,
  } = useFilteredTasks({
    initialDateRange: {
      begin: LocalDate.now().minusDays(30),
      end: LocalDate.now(),
    },
    initialSelectedStatus: SearchStatus.Completed,
    searchQuery,
    selectedAssignees,
    selectedFacilities,
    selectedStatus,
    statuses,
  });

  useEffect(() => {
    if (hookDataLoaded) {
      stopTracking(DataStatus.Fetched);
    }
  }, [hookDataLoaded, stopTracking]);

  const resetFilters = () => {
    setSearchQuery('');
    setSelectedAssignees([]);
    setSelectedFacilities([]);
    setSelectedStatus(SearchStatus.Completed);
    resetDateRange();
  };

  const openTaskInstance = (task: TaskInstance) => {
    navigate(
      `/tasks/${task.seriesId}/${task.timeline.scheduledDate}?source=/tasks/completed`,
    );
  };

  return (
    <div className='p-4 sm:p-8'>
      <PageHeader title='Completed Tasks' />
      <div className='flex justify-between items-center gap-2'>
        <div className='filters-container'>
          <SearchInput
            aria-label='Search tasks'
            id='completed-tasks-search-input'
            onSearch={setSearchQuery}
            value={searchQuery}
          />
          <DatePicker
            asSingle={false}
            id='completed-tasks-date-picker'
            onChange={setDateRange}
            scrollOnFocus={false}
            shortcutsType='last'
            showShortcuts
            value={dateRange}
          />
          <Autocomplete
            className='max-sm:w-full w-48'
            getOptionKey={(facility) => facility.id}
            getOptionLabel={(facility) => facility.name}
            id='completed-tasks-facility-selector'
            label='Facilities'
            multiple
            onChange={setSelectedFacilities}
            options={accessibleFacilities}
            value={selectedFacilities}
          />
          <Autocomplete
            className='max-sm:w-full w-44'
            getOptionKey={(user) => user.id}
            getOptionLabel={(user) => user.name}
            id='completed-tasks-assignee-selector'
            label='Assignee'
            multiple
            onChange={setSelectedAssignees}
            options={activeUsersAndUnassigned}
            value={selectedAssignees}
          />
          <Autocomplete
            className='max-sm:w-full w-40'
            getOptionLabel={getSearchStatusLabel}
            id='completed-tasks-status-selector'
            label='Status'
            onChange={setSelectedStatus}
            options={statuses}
            value={selectedStatus}
          />
          {hasFilters && <ClearAllButton onClick={resetFilters} />}
        </div>
        {viewToggleButtons}
      </div>
      {isListView && (
        <TasksTable
          data={filteredTasks}
          loading={loadingTasks}
          openTaskInstance={openTaskInstance}
          setTaskInstanceStatus={console.log}
          showCompletedDate
        />
      )}
      {!isListView && loadingTasks && (
        <div aria-label='Loading task cards' className='space-y-4'>
          <Skeleton animation='pulse' height={200} variant='rounded' />
          <Skeleton animation='pulse' height={200} variant='rounded' />
        </div>
      )}
      {!isListView && !loadingTasks && (
        <div className='space-y-4'>
          {filteredTasks.map((task) => (
            <TaskCard
              key={task.id}
              openTaskInstance={openTaskInstance}
              setTaskInstanceStatus={console.log}
              task={task}
            />
          ))}
        </div>
      )}
    </div>
  );
};
