import {
  AddMediaParams,
  InspectionDetails,
  PromptQuestionDetails,
} from '@dakota/platform-client';
import { PaperClipIcon } from '@heroicons/react/24/outline';
import { DocumentArrowUpIcon } from '@heroicons/react/24/solid';
import { FilePreview, UploadState } from 'components/Attachment/FilePreview';
import { FileError } from 'components/Attachment/types';
import Button from 'components/Button';
import SidePanel from 'components/SidePanel';
import { WarningMessage } from 'components/WarningMessage';
import dotProp, { set } from 'dot-prop-immutable';
import { configSlice } from 'features/config/configSlice';
import { addAttachment } from 'features/inspections/inspectionActions';
import { inspectionSlice } from 'features/inspections/inspectionSlice';
import { tokenSlice } from 'features/token/tokenSlice';
import useToast from 'hooks/useToast';
import { FC, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';

import { QuestionSummary } from './QuestionSummary';

type AddAttachmentsProps = {
  /**
   * The number of attachments already in the question, before adding more
   */
  currentCount: number;
  onClose: () => void;
  promptId: string;
  promptIndex: number;
  question: PromptQuestionDetails;
  sectionIndex: number;
};

export const AddAttachments: FC<AddAttachmentsProps> = ({
  currentCount,
  onClose,
  promptId,
  promptIndex,
  question,
  sectionIndex,
}) => {
  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);

  const { setSuccessMessage } = useToast();

  const inspection = useSelector(
    inspectionSlice.selectors.inspectionDetails,
  ) as InspectionDetails;
  const inspectionId = inspection.id;

  const fileInputRef = useRef<HTMLInputElement>(null);

  const [isSaving, setIsSaving] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [descriptions, setDescriptions] = useState<string[]>([]);
  const [states, setStates] = useState<UploadState[]>([]);
  const [errorState, setErrorState] = useState<FileError[]>([]);

  const limit = 10;
  const notUploadedCount = states.filter(
    (s) => s === 'pending' || s === 'uploading',
  ).length;

  const resetAndClose = () => {
    onClose();
  };

  const saveAttachments = async () => {
    setIsSaving(true);

    // We keep a local copy of the upload state for the final success message,
    // and we also update the state in the component to keep track of the
    // count of files that have been uploaded successfully.
    const uploadState = [] as UploadState[];
    setStates([]);
    for (let i = 0; i < files.length; i++) {
      setStates((prev) => set(prev, i, 'uploading'));
      const file = files[i];
      const options = {
        description: descriptions[i],
        mediaFile: {
          data: file,
          fileName: file.name,
        },
      } as AddMediaParams;
      await dispatch(
        addAttachment({
          baseUrl,
          inspectionId,
          options,
          promptId,
          promptIndex,
          sectionIndex,
          token,
        }),
      )
        .unwrap()
        .then(() => {
          uploadState[i] = 'uploaded';
          setStates((prev) => set(prev, i, 'uploaded'));
        })
        .catch((e: unknown) => {
          uploadState[i] = 'error';
          setStates((prev) => set(prev, i, 'error'));
          if (typeof e === 'string') {
            setErrorState((prev) => [
              ...prev,
              { errorMessage: e, filename: file.name },
            ]);
          }

          setIsSaving(false);
        });
    }

    if (uploadState.every((s) => s === 'uploaded')) {
      setSuccessMessage('Attachments saved successfully');
      resetAndClose();
    }
  };

  const renderErrorMessages = () => {
    return errorState.map(({ errorMessage, filename }, index) => (
      <WarningMessage key={`${filename} + ${index}`}>
        Unable to upload <strong>{filename}</strong>: {errorMessage}.
      </WarningMessage>
    ));
  };

  const handleFileEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFiles = e.target.files;
    const filesToAdd = [] as File[];

    if (newFiles) {
      for (const file of Array.from(newFiles)) {
        if (!files.some((f) => f.name === file.name)) {
          filesToAdd.push(file);
        }
      }
    }

    setFiles((prev) => [...prev, ...filesToAdd]);
    setDescriptions((prev) => [...prev, ...filesToAdd.map(() => '')]);
    setStates((prev) => [
      ...prev,
      ...filesToAdd.map(() => 'pending' as UploadState),
    ]);
  };

  const removeFile = (name: string) => {
    const index = files.findIndex((file) => file.name === name);
    if (index !== -1) {
      setFiles((prev) => prev.toSpliced(index, 1));
      setDescriptions((prev) => prev.toSpliced(index, 1));
      setStates((prev) => prev.toSpliced(index, 1));
    }
  };

  return (
    <SidePanel
      isOpen
      onClose={onClose}
      PanelTitle={
        <div className='flex items-center gap-1 text-green-base'>
          <PaperClipIcon className='w-5' />
          Add Attachments
        </div>
      }
    >
      <div className='h-full flex flex-col justify-between text-gray-700'>
        <div className='flex-1 overflow-y-auto'>
          <QuestionSummary question={question} />
          <div aria-label='Attachments' className='p-6 flex flex-col gap-3'>
            {currentCount + notUploadedCount > limit && (
              <WarningMessage>
                You can upload up to {limit} attachments to a question. Please
                remove some files before saving.
              </WarningMessage>
            )}
            {currentCount == limit && (
              <WarningMessage variant='light'>
                This question has the maximum number of attachments allowed (
                {limit}).
              </WarningMessage>
            )}
            {currentCount < limit &&
              currentCount + notUploadedCount == limit && (
                <WarningMessage variant='light'>
                  You can upload up to {limit} attachments to a question.
                </WarningMessage>
              )}
            {renderErrorMessages()}
            <div>
              <input
                className='hidden'
                data-testid='file-upload-input'
                multiple
                onChange={handleFileEvent}
                ref={fileInputRef}
                type='file'
              />
              <Button
                className='flex items-center gap-1'
                disabled={isSaving || currentCount + files.length >= limit}
                onClick={() => fileInputRef.current?.click()}
                secondary
              >
                <DocumentArrowUpIcon className='h-5' />
                Select File(s)
              </Button>
            </div>
            <div className='flex flex-col gap-3'>
              {files.map((file, index) => (
                <FilePreview
                  description={descriptions[index]}
                  file={file}
                  index={currentCount + index}
                  key={file.name}
                  onRemove={() => removeFile(file.name)}
                  setDescription={(desc) =>
                    setDescriptions(dotProp.set(descriptions, index, desc))
                  }
                  state={states[index]}
                />
              ))}
            </div>
          </div>
        </div>
        <div className='flex gap-2.5 px-6 py-4 border-t border-gray-200'>
          <Button
            className='bg-green-base py-2 px-4 disabled:bg-gray-400'
            disabled={files.length === 0 || currentCount + files.length > limit}
            loading={isSaving}
            onClick={() => void saveAttachments()}
          >
            {isSaving ? 'Saving...' : 'Save'}
          </Button>
          <Button
            className='py-2 px-4'
            disabled={isSaving}
            onClick={resetAndClose}
            secondary
          >
            Cancel
          </Button>
        </div>
      </div>
    </SidePanel>
  );
};
