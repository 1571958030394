import { taskEditSlice } from 'features/tasks/taskEditSlice';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';

export const Description: FC = () => {
  const dispatch = useAppDispatch();

  const description = useSelector(taskEditSlice.selectors.description);

  const hasErrorFiles = useSelector(taskEditSlice.selectors.hasErrorFiles);

  return (
    <>
      <label htmlFor='task-details-input'>Description</label>
      <textarea
        className='w-full border rounded-lg border-gray-300 h-20 text-gray-700'
        data-testid='task-details-input'
        disabled={hasErrorFiles}
        id='task-details-input'
        onChange={(e) =>
          dispatch(
            taskEditSlice.actions.setTaskField({
              field: 'description',
              value: e.target.value,
            }),
          )
        }
        placeholder='Enter Description...'
        value={description}
      />
    </>
  );
};
