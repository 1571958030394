import { Inspection, Summary, Zone } from '@dakota/platform-client';
import { LocalDate } from '@js-joda/core';
import { Dialog } from '@mui/material';
import { clsx } from 'clsx';
import Button from 'components/Button';
import AcknowledgmentDialog from 'components/Dialog/AcknowledgmentDialog';
import { userSlice } from 'features/user/userSlice';
import { zonesSlice } from 'features/zones/zonesSlice';
import {
  TemplateWorkflowProps,
  useTemplateWorkflow,
} from 'hooks/useTemplateWorkflow';
import { useZones } from 'hooks/useZones';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { formatBackendDate } from 'utils/date';

import DialogBody from './DialogBody';
import DialogFooter from './DialogFooter';
import DialogHeader from './DialogHeader';

const Schedule = ({
  form,
  scheduledAssigneeId,
  scheduledInspectionDate,
}: TemplateWorkflowProps) => {
  const {
    assignee,
    closeDialog,
    date,
    facility,
    isDialogOpen,
    priority,
    scheduledInspection,
    scheduleInspection,
    scheduling,
    selectableUsers,
    setAssignee,
    setDate,
    setFacility,
    setIsDialogOpen,
    setPriority,
    setScheduledInspection,
  } = useTemplateWorkflow({
    form,
    scheduledAssigneeId,
    scheduledInspectionDate,
  });

  const selectableFacilities = useSelector(
    userSlice.selectors.accessibleFacilities,
  );

  const dispatch = useAppDispatch();
  const orderedZones = useSelector(zonesSlice.selectors.orderedZones);
  const [isOpenOverdueDialog, setIsOpenOverdueDialog] = useState(false);
  const [shouldRenderZones, setShouldRenderZones] = useState(false);
  const [selectableZones, setSelectableZones] = useState<Zone[]>([]);

  const [isFacilityMenuOpen, setIsFacilityMenuOpen] = useState(false);

  const { facilitiesLoadingZones, getFacilityZones } = useZones();

  useEffect(() => {
    if (facility) {
      setSelectableZones(
        getFacilityZones(facility.id).filter((zone) => !zone.inactive),
      );
    }
  }, [facility, getFacilityZones]);

  const setZones = (zones: Summary[]) => {
    dispatch(zonesSlice.actions.setZones(zones));
  };

  const getAcknowledgmentMessage = useCallback(
    (inspection: Inspection) => (
      <>
        <strong>{inspection.form.name}</strong> has been successfully scheduled{' '}
        for <strong>{inspection.userName}</strong> on{' '}
        <strong>{formatBackendDate(inspection.timeline.scheduledDate)}.</strong>
      </>
    ),
    [],
  );

  const handleScheduleInspection = () => {
    if (date.isBefore(LocalDate.now())) {
      setIsOpenOverdueDialog(true);
      return;
    }

    void scheduleInspection();

    setZones([]);
    setShouldRenderZones(false);
  };

  const confirmOverdueSchedule = () => {
    setIsOpenOverdueDialog(false);
    void scheduleInspection();
  };

  const onClose = () => {
    setZones([]);
    setShouldRenderZones(false);
    closeDialog();
  };

  return (
    <>
      {scheduledInspection && (
        <AcknowledgmentDialog
          acknowledgmentText={getAcknowledgmentMessage(scheduledInspection)}
          onAcknowledgment={() => setScheduledInspection(undefined)}
          onCloseDialog={() => setScheduledInspection(undefined)}
          title='Inspection Scheduled'
        />
      )}
      {isOpenOverdueDialog && (
        <AcknowledgmentDialog
          acknowledgmentText='This inspection is being scheduled for a date in the past, and will immediately be overdue. Do you wish to proceed?'
          buttonText={scheduling ? 'Scheduling...' : 'Schedule'}
          cancelButtonText='Go back'
          onAcknowledgment={confirmOverdueSchedule}
          onCloseDialog={() => setIsOpenOverdueDialog(false)}
          title='Schedule Inspection'
        />
      )}
      <Button
        data-testid='scheduler-button'
        disabled={form.inactive}
        onClick={() => setIsDialogOpen(true)}
        secondary
      >
        Schedule
      </Button>
      <Dialog
        data-testid='scheduler-dialog'
        fullWidth
        maxWidth={orderedZones.length > 1 ? 'md' : 'sm'}
        onClose={(_, reason) => reason !== 'backdropClick' && onClose()}
        open={isDialogOpen}
      >
        <div
          className={clsx('flex flex-col justify-between', {
            'min-h-[700px]': isFacilityMenuOpen,
          })}
        >
          <DialogHeader onClose={onClose} title={form.name} />
          <DialogBody
            assignee={assignee}
            date={date}
            facility={facility}
            form={form}
            isLoadingZones={
              !!facility && facilitiesLoadingZones.includes(facility.id)
            }
            orderedZones={orderedZones}
            priority={priority}
            selectableFacilities={selectableFacilities}
            selectableUsers={selectableUsers}
            selectableZones={selectableZones}
            setAssignee={setAssignee}
            setDate={setDate}
            setFacility={setFacility}
            setIsFacilityMenuOpen={setIsFacilityMenuOpen}
            setPriority={setPriority}
            setShouldRenderZones={setShouldRenderZones}
            setZones={setZones}
            shouldRenderZones={shouldRenderZones}
          />
          <DialogFooter
            canSchedule={!!facility}
            handleScheduleInspection={handleScheduleInspection}
            onClose={onClose}
            scheduling={scheduling}
          />
        </div>
      </Dialog>
    </>
  );
};

export default Schedule;
